import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

  AlertError,
} from '../../components/Utility/MyString';

const Personal_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/sales/acard_save";



export default class Personal_Provider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        item_no: '',
        acard_no: '',
        sourceofcus: '',
        media: '',
        cusgroup: '',
        custype: '',
        colour: '',
        salejobtype: '',
        acard_contace: '',
        customername: '',
        mobilephone: '',
        userid: '',
        companycode: ''
      },
      userAuthorize: [
        {
          userid: '',
          menucode: '',
          menutext: '',
          useuse: 0,
          isinsert: 0,
          isupdate: 0,
          isdelete: 0
        }
      ],
      itempage_list: [
        {
          person_id: '',
          acard_no: '', 
          totalrecord: 1
        }],
        
       
        
              

      pageindex: 1,
      pagesize: 20,
      strsearch: '',
      strfromdate: '',
      strenddate: '',
      companycode: '',
      totalrecord: 0,
      pagecount: 0,
      isLogin: false
    }
  }


  setData = async (values) => {
    this.setState({
      ...this.state,
      data: values
    });

    console.log("state->" + JSON.stringify(this.state))

  };


  firstPage = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  previousPage = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  nextPage = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  lastPage = async (callBack) => {
    this.setState({
      pageindex: this.state.pagecount
    });

    let obj = this

    setTimeout(function () {
      //alert("Last Page ...." + obj.state.pageindex)
      obj.getPage(callBack);
    }, 200)

  }

  
  

  getPage = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize, 
        filter_type: sessionStorage.getItem("filter_type"), 
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"), 
        userid: sessionStorage.getItem("userid")
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/personal/getpage";

      await axios
        .post(url, { data: page })

        .then((response) => {

            //  alert("context  getPage_matching    ->" + JSON.stringify(response.data[1].totalrecord));
         
            let xpagecount = parseInt(response.data[0].totalrecord / 20);
             let ypage = response.data[0].totalrecord % 20;
             if (ypage > 0) { xpagecount = xpagecount + 1 }
         
            let obj = this;
  
            setTimeout(function () {
              obj.setState({
                ...obj.state,
  
  
                itempage :  response.data ,
                totalrecord: response.data[0].totalrecord,
                pagecount: xpagecount
  
  
              });
            }, 100);
  
            setTimeout(function () {
              callBack(response.data);
            }, 100);
  
  
          });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };

    
    


  UpdatePersonInfo = async (callBack) => {

    try {


      let xdata = {



        person_id: sessionStorage.getItem("person_id")
        , person_group1: sessionStorage.getItem("person_group1")
        , person_group2: sessionStorage.getItem("person_group2")
        , person_group3: sessionStorage.getItem("person_group3")
        , title: sessionStorage.getItem("title")
        , firstname: sessionStorage.getItem("firstname")
        , lastname: sessionStorage.getItem("lastname")
        , gender: sessionStorage.getItem("gender")
        , mobilephone: sessionStorage.getItem("mobilephone")
        , citizenid: sessionStorage.getItem("citizenid")
        , address_no: sessionStorage.getItem("address_no")
        , address_road: sessionStorage.getItem("address_road")
        , address_postcode: sessionStorage.getItem("address_postcode")
        , address_province: sessionStorage.getItem("address_province")
        , address_amphur: sessionStorage.getItem("address_amphur")
        , address_tumbon: sessionStorage.getItem("address_tumbon")
        , details1: sessionStorage.getItem("details1")
        , details2: sessionStorage.getItem("details2")
        , details3: sessionStorage.getItem("details3")
        , details4: sessionStorage.getItem("details4")
        , details5: sessionStorage.getItem("details5")
        , person_status: sessionStorage.getItem("person_status") 
        , userid: sessionStorage.getItem("userid")


      }



      let url = BaseApi + "/api/personal/update_personal_info";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }



  GetPersonalInfo = async (callBack) => {
    try {

      let url = BaseApi + "/api/personal/get_personal_info";


      let page = { 
      
        person_id: sessionStorage.getItem("person_id"),
        userid: sessionStorage.getItem("userid"),

      };


      await axios
        .post(url, { data: page })
        .then((response) => {

            let obj = this;

            setTimeout(function () {
                obj.setState({
                    ...obj.state,
                    itempage_list: response.data.details,
                   

                });
            }, 100);

            setTimeout(function () {
                callBack(response.data);
            }, 100);


       
        })


    } catch (error) {
      AlertError(error);
    }
  };




   
  render() {
    return (
      <Personal_Context.Provider
        value={{
          ...this.state,
          handleChange: this.handleChange,
          setData: this.setData,
          saveData: this.saveData,
          getData: this.getData,
          getRunning: this.getRunning,
          deleteData: this.deleteData,
          getPage: this.getPage,
          firstPage: this.firstPage,
          previousPage: this.previousPage,
          nextPage: this.nextPage,
          lastPage: this.lastPage,
          UpdatePersonInfo: this.UpdatePersonInfo,
          GetPersonalInfo: this.GetPersonalInfo,
          
        

         

        }}
      >
        {this.props.children}
      </Personal_Context.Provider>

    );
  }
}
const Personal_Consumer = Personal_Context.Consumer;

export { Personal_Provider, Personal_Consumer, Personal_Context };

export function withPersonal_Consumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <Personal_Consumer>
        {value => <Component {...props} context={value} />}
      </Personal_Consumer>
    );
  };
}

