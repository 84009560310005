import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

  AlertError,
} from '../../components/Utility/MyString';

const SaleInfo_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/sales/acard_save";



export default class SaleInfo_Provider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        item_no: '',
        acard_no: '',
        sourceofcus: '',
        media: '',
        cusgroup: '',
        custype: '',
        colour: '',
        salejobtype: '',
        acard_contace: '',
        customername: '',
        mobilephone: '',
        userid: '',
        companycode: ''
      },
      userAuthorize: [
        {
          userid: '',
          menucode: '',
          menutext: '',
          useuse: 0,
          isinsert: 0,
          isupdate: 0,
          isdelete: 0
        }
      ],
      itempage: [
        {
          item_no: '',
          acard_no: '',
          companycode: '',
          totalrecord: 1
        }],

      itempage_prefinancelist: [
        {

          manager: '',
          sale: '',
          productid: '',
          itemname: '', buydate2: '',
          buydate_tbr: '', sale_code: '', reservation_no: '', ref_no2: '', ref_no3: '',
          customername: '', dn_no: '', cassienumber: '', cartype: '', model: '',
          color: '', buyfrom: '', saletype: '', financename: '', saleprice: '',
          subsidy_note: ''


        }],

      itempage_bookinglist: [
        {

          manager: '',
          sale: '',
          productid: '',
          itemname: '', buydate2: '',
          buydate_tbr: '', sale_code: '', reservation_no: '', ref_no2: '', ref_no3: '',
          customername: '', dn_no: '', cassienumber: '', cartype: '', model: '',
          color: '', buyfrom: '', saletype: '', financename: '', saleprice: '',
          subsidy_note: ''


        }],

      itempage_model: [
        {

          model: '',
          count_model: ''

        }],

      itempage_paymentcheck: [
        {

          item_no: '',
          approve_item_no: '', 
          status1: '',
          confirm1: '',
          confirm2: '',
          remark1: '',
          remark2: '' 

        }],

        
        itempage_listpaymentcheck: [
        {

          item_no: '',
          acard_no: '',
          customername: '',
          status1: '',
          confirm1: '',
          confirm2: '',

        }],

        
        itempage_approve_process: [
          {
  
            item_no: '',
            approve_status: '', 
            approve_status2: '',
            ref_date1: '',
            ref_date2: '',
  
          }], 
 

          itempage_receiptcar_no: [
            {
    
              receipt_no: '',
              receiptdate: '', 
              customerid: '',
              transactionId: '',
              recivetype: '',
    
            }],

            itempage_reservate: [
              {
      
                reservation_no: '',
                createdate: '', 
                customerid: '',
                model: '',
                color: '',
                cassienumber: '',
                advancepay: '',
                status: '',
      
              }],

              itempage_acardlist: [
                {
        
                  acard_no: '',
                  customename: '', 
                  jobstatus: '',
                  mobilephone: '',
                   
        
                }],

                itempage_bookinglist_b002: [
                  { 
                    customerid: '', 
                    customername: '', 
                    curentaddress: '' , 
                    mobilephone: '', 
                    citizenid: '',
						      sale_code : '', 
                  model: '', 
                  color: '', 
                  saleperson: '', 
                  saleteam: '', 
                  reservatedate: '', 
                  buydate: '', 
                  salestatus : ''
          
                  }],

                  itempage_bookinglist_b003: [
                    {
            
                      manager: '',
                      sale: '',
                      productid: '',
                      itemname: '', buydate2: '',
                      buydate_tbr: '', sale_code: '', reservation_no: '', ref_no2: '', ref_no3: '',
                      customername: '', dn_no: '', cassienumber: '', cartype: '', model: '',
                      color: '', buyfrom: '', saletype: '', financename: '', saleprice: '',
                      subsidy_note: ''
            
            
                    }],
                    itempage_approvelist_b004: [
                    {
            
                      manager: '',
                      sale: '',
                      productid: '',
                      itemname: '', buydate2: '',
                      buydate_tbr: '', sale_code: '', reservation_no: '', ref_no2: '', ref_no3: '',
                      customername: '', dn_no: '', cassienumber: '', cartype: '', model: '',
                      color: '', buyfrom: '', saletype: '', financename: '', saleprice: '',
                    
            
            
                    }],
            
            
          
                    

      pageindex: 1,
      pagesize: 20,
      strsearch: '',
      strfromdate: '',
      strenddate: '',
      companycode: '',
      totalrecord: 0,
      pagecount: 0,
      isLogin: false
    }
  }


  setData = async (values) => {
    this.setState({
      ...this.state,
      data: values
    });

    console.log("state->" + JSON.stringify(this.state))

  };


  firstPage = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  previousPage = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  nextPage = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  lastPage = async (callBack) => {
    this.setState({
      pageindex: this.state.pagecount
    });

    let obj = this

    setTimeout(function () {
      //alert("Last Page ...." + obj.state.pageindex)
      obj.getPage(callBack);
    }, 200)

  }




  getPage = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,
        // filter_month: sessionStorage.getItem("filter_month"),
        // filter_userid: sessionStorage.getItem("filter_userid"),
        // filter_team: sessionStorage.getItem("filter_team"),
        // filter_jobstatus: sessionStorage.getItem("filter_jobstatus"),
        // filter_sortby: sessionStorage.getItem("filter_sortby"),
        // filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid")
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/saleinfo/saleinfo_page";

      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          let xpagecount = parseInt(response.data[0].totalrecord / 20);
          let ypage = response.data[0].totalrecord % 20;
          if (ypage > 0) {
            xpagecount = xpagecount + 1;
          }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              itempage: response.data,
              totalrecord: response.data[0].totalrecord,

              pagecount: xpagecount,
            });
          }, 100);

          //   setTimeout(function () {
          //     callBack(response.data);
          //   }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };




  getSaleInfobyID = async (callBack) => {
    try {

      let url = BaseApi + "/api/saleinfo/saleinfo_getdata";

      let sale_code = sessionStorage.getItem("sale_code");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        sale_code: sale_code,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          // setTimeout(function () {
          //   obj.setState({
          //     ...obj.state,

          //     // data: response.data.data,



          //   });
          // }, 100);

          setTimeout(function () {
            callBack(response.data.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };




  previousPageTBR = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptPrefinanceConfirm(callBack)
    }, 200)

  }

  nextPageTBR = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptPrefinanceConfirm(callBack)
    }, 200)

  }

  getRptPrefinanceConfirm = async (callBack) => {
    try {

      let url = BaseApi + "/api/tbr/getrptprefinanceconfirm";

      let page = {

        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,

        filter_status: sessionStorage.getItem("filter_status"),
        filter_date_from: sessionStorage.getItem("filter_date_from"),
        filter_date_to: sessionStorage.getItem("filter_date_to"),

        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        filter_branch: sessionStorage.getItem("filter_branch"),
        strsearch: sessionStorage.getItem("strsearch"),

        userid: sessionStorage.getItem("userid"),
        companycode: sessionStorage.getItem("companycode"),
      };





      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // alert("context getrptinvite from server->" + JSON.stringify(response.data.fk_list));

          let xpagecount = parseInt(response.data[0].totalrecord / 20);
          let ypage = response.data[0].totalrecord % 20;
          if (ypage > 0) {
            xpagecount = xpagecount + 1;
          }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data,
              itempage_prefinancelist: response.data,

              // itempage_fklist: response.data.fk_list,
              totalrecord: response.data[0].totalrecord,
              pagecount: xpagecount,
            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error }]);
    }
  };




  getRptBooking = async (callBack) => {
    try {

      let url = BaseApi + "/api/saleinfo/getrptbooking";

      let page = {

        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,

        filter_status: sessionStorage.getItem("filter_status"),
        filter_date_from: sessionStorage.getItem("filter_date_from"),
        filter_date_to: sessionStorage.getItem("filter_date_to"),

        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        filter_branch: sessionStorage.getItem("filter_branch"),
        strsearch: sessionStorage.getItem("strsearch"),

        userid: sessionStorage.getItem("userid"),
        companycode: sessionStorage.getItem("companycode"),
      };





      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // alert("context getrptinvite from server->" + JSON.stringify(response.data.fk_list));

          let xpagecount = parseInt(response.data[0].totalrecord / 20);
          let ypage = response.data[0].totalrecord % 20;
          if (ypage > 0) {
            xpagecount = xpagecount + 1;
          }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data,
              itempage_bookinglist: response.data,

              // itempage_fklist: response.data.fk_list,
              totalrecord: response.data[0].totalrecord,
              pagecount: xpagecount,
            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error }]);
    }
  };



  genExportbookingtoExcel = async (callBack) => {
    try {


      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: 1000,

        filter_status: sessionStorage.getItem("filter_status"),
        filter_date_from: sessionStorage.getItem("filter_date_from"),
        filter_date_to: sessionStorage.getItem("filter_date_to"),

        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        filter_branch: sessionStorage.getItem("filter_branch"),
        strsearch: sessionStorage.getItem("strsearch"),

        userid: sessionStorage.getItem("userid"),
        companycode: sessionStorage.getItem("companycode"),

      }


      await axios.post(BaseApi + "/api/saleinfo/genexportbookingtoexcel", { data: page })
        .then((response) => {

          let obj = this

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              itempage: response.data
            });
          }, 100)


          setTimeout(function () {
            callBack(response.data);
          }, 100);



        })




    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };


  firstPageBooking = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking(callBack)
    }, 200)

  }


  previousPageBooking = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking(callBack)
    }, 200)

  }

  nextPageBooking = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking(callBack)
    }, 200)

  }




  firstPageBooking_all = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_all(callBack)
    }, 200)

  }



  firstPageBooking_sup = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup(callBack)
    }, 200)

  }


  getRptBooking_sup = async (callBack) => {
    try {

      let url = BaseApi + "/api/saleinfo/getrptbooking_sup";

      let page = {

        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,

        filter_date_from: sessionStorage.getItem("filter_date_from"),
        filter_date_to: sessionStorage.getItem("filter_date_to"),
        filter_userid: sessionStorage.getItem("filter_userid"),
        filter_team: sessionStorage.getItem("filter_team"),
        filter_branch: sessionStorage.getItem("filter_branch"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),

        userid: sessionStorage.getItem("userid"),
        companycode: sessionStorage.getItem("companycode"),
      };





      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //  alert("context getRptBooking_sup from server->" + JSON.stringify(response.data.status));

          let xpagecount = parseInt(response.data.status[0].totalrecord / 20);

          let ypage = response.data.status[0].totalrecord % 20;
          if (ypage > 0) {
            xpagecount = xpagecount + 1;
          }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data,
              itempage_bookinglist: response.data.data,
              itempage_model: response.data.model,
              // itempage_bookinglist: response.data.data[0],

              // // itempage_fklist: response.data.fk_list,
              totalrecord: response.data.status[0].totalrecord,
              pagecount: xpagecount,
            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error }]);
    }
  };


  nextPageBooking_sup = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup(callBack)
    }, 200)

  }


  previousPageBooking_sup = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup(callBack)
    }, 200)

  }

  
  
  

  getPage_PaymentCheck = async (callBack) => {
    try {

      let url = BaseApi + "/api/saleinfo/getrptpaymentcheck";

      let page = {

        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,
        filter_status: sessionStorage.getItem("filter_status"),
        filter_date_from: sessionStorage.getItem("filter_date_from"),
        filter_date_to: sessionStorage.getItem("filter_date_to"),
        filter_branch: sessionStorage.getItem("filter_branch"),
        strsearch: sessionStorage.getItem("strsearch"),
        userid: sessionStorage.getItem("userid"),

      };

 
      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //  alert("context getPage_PaymentCheck from server->" + JSON.stringify(response.data.status));

          let xpagecount = parseInt(response.data.status[0].totalrecord / 20);


          let ypage = response.data.status[0].totalrecord % 20;
          if (ypage > 0) {
            xpagecount = xpagecount + 1;
          }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data,
              itempage_listpaymentcheck: response.data.data,
              itempage_acardlist: response.data.acardlist,
              itempage_paymentcheck: response.data.payment_check,
              itempage_approve_process: response.data.approve_process, 
              itempage_reservate: response.data.reservate,
              itempage_receiptcar_no: response.data.receiptcar_no,

              // itempage_model : response.data.model,

              totalrecord: response.data.status[0].totalrecord,
              pagecount: xpagecount,
            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error }]);
    }
  };



  firstPageBooking_sup_B002 = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup_B002(callBack)
    }, 200)

  }


  getRptBooking_sup_B002 = async (callBack) => {
    try {

      let url = BaseApi + "/api/saleinfo/getrptbooking_sup_b002";

      let page = {

        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,

        filter_date_from: sessionStorage.getItem("filter_date_from"),
        filter_date_to: sessionStorage.getItem("filter_date_to"),
        filter_userid: sessionStorage.getItem("filter_userid"),
        filter_team: sessionStorage.getItem("filter_team"),
        filter_branch: sessionStorage.getItem("filter_branch"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),

        userid: sessionStorage.getItem("userid"),
        companycode: sessionStorage.getItem("companycode"),
      };





      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //  alert("context getRptBooking_sup from server->" + JSON.stringify(response.data.status));

          let xpagecount = parseInt(response.data.status[0].totalrecord / 20);

          let ypage = response.data.status[0].totalrecord % 20;
          if (ypage > 0) {
            xpagecount = xpagecount + 1;
          }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data,
              itempage_bookinglist_b002: response.data.data,
              itempage_model: response.data.model,
              // itempage_bookinglist: response.data.data[0],

              // // itempage_fklist: response.data.fk_list,
              totalrecord: response.data.status[0].totalrecord,
              pagecount: xpagecount,
            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error }]);
    }
  };

  
  nextPageBooking_sup_b002 = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup_B002(callBack)
    }, 200)

  }


  previousPageBooking_sup_b002 = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup_B002(callBack)
    }, 200)

  }

  
  firstPageBooking_sup_B003 = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup_B003(callBack)
    }, 200)

  }


  getRptBooking_sup_B003 = async (callBack) => {
    try {

      let url = BaseApi + "/api/saleinfo/getrptbooking_sup_b003";

      let page = {

        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,

        filter_date_from: sessionStorage.getItem("filter_date_from"),
        filter_date_to: sessionStorage.getItem("filter_date_to"),
        filter_userid: sessionStorage.getItem("filter_userid"),
        filter_team: sessionStorage.getItem("filter_team"),
        filter_branch: sessionStorage.getItem("filter_branch"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),

        userid: sessionStorage.getItem("userid"),
        companycode: sessionStorage.getItem("companycode"),
      };





      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //  alert("context getRptBooking_sup from server->" + JSON.stringify(response.data.status));

          let xpagecount = parseInt(response.data.status[0].totalrecord / 20);

          let ypage = response.data.status[0].totalrecord % 20;
          if (ypage > 0) {
            xpagecount = xpagecount + 1;
          }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data,
              itempage_bookinglist_b003: response.data.data,
              itempage_model: response.data.model, 
              totalrecord: response.data.status[0].totalrecord,
              pagecount: xpagecount,
            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error }]);
    }
  };

  
  nextPageBooking_sup_b003 = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup_B003(callBack)
    }, 200)

  }


  previousPageBooking_sup_b003 = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup_B003(callBack)
    }, 200)

  }
 

  genExportB002toExcel = async (callBack) => {
    try {


      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: 1000,

        filter_date_from: sessionStorage.getItem("filter_date_from"),
        filter_date_to: sessionStorage.getItem("filter_date_to"),
        filter_userid: sessionStorage.getItem("filter_userid"),
        filter_team: sessionStorage.getItem("filter_team"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"), 

        userid: sessionStorage.getItem("userid"),
        companycode: sessionStorage.getItem("companycode"),

      }


      await axios.post(BaseApi + "/api/saleinfo/genexportb002toexcel", { data: page })
        .then((response) => {

          let obj = this

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              itempage: response.data
            });
          }, 100)


          setTimeout(function () {
            callBack(response.data);
          }, 100); 

        }) 


    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };

  
  
  firstPageBooking_sup_B004 = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup_B004(callBack)
    }, 200)

  }


  getRptBooking_sup_B004 = async (callBack) => {
    try {

      let url = BaseApi + "/api/saleinfo/getrptbooking_sup_b004";

      let page = {

        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,

        filter_date_from: sessionStorage.getItem("filter_date_from"),
        filter_date_to: sessionStorage.getItem("filter_date_to"),
        filter_userid: sessionStorage.getItem("filter_userid"),
        filter_team: sessionStorage.getItem("filter_team"),
        filter_branch: sessionStorage.getItem("filter_branch"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),

        userid: sessionStorage.getItem("userid"),
        companycode: sessionStorage.getItem("companycode"),
      };





      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //  alert("context getRptBooking_sup from server->" + JSON.stringify(response.data.status));

          let xpagecount = parseInt(response.data.status[0].totalrecord / 20);

          let ypage = response.data.status[0].totalrecord % 20;
          if (ypage > 0) {
            xpagecount = xpagecount + 1;
          }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data,
              itempage_approvelist_b004: response.data.data, 
              totalrecord: response.data.status[0].totalrecord,
              pagecount: xpagecount,
            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error }]);
    }
  };

  
  nextPageBooking_sup_b004 = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup_B004(callBack)
    }, 200)

  }


  previousPageBooking_sup_b004 = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getRptBooking_sup_B004(callBack)
    }, 200)

  }
 


  render() {
    return (
      <SaleInfo_Context.Provider
        value={{
          ...this.state,
          handleChange: this.handleChange,
          setData: this.setData,
          saveData: this.saveData,
          getData: this.getData,
          getRunning: this.getRunning,
          deleteData: this.deleteData,
          getPage: this.getPage,
          firstPage: this.firstPage,
          previousPage: this.previousPage,
          nextPage: this.nextPage,
          lastPage: this.lastPage,
          getSaleInfobyID: this.getSaleInfobyID,
          getRptPrefinanceConfirm: this.getRptPrefinanceConfirm,
          getRptBooking: this.getRptBooking,
          genExportbookingtoExcel: this.genExportbookingtoExcel,
          firstPageBooking: this.firstPageBooking,
          previousPageBooking: this.previousPageBooking,
          nextPageBooking: this.nextPageBooking,
          previousPageTBR: this.previousPageTBR,
          nextPageTBR: this.nextPageTBR,
          // firstPageBooking_all: this.firstPageBooking_all,
          getRptBooking_all: this.getRptBooking_all,
          firstPageBooking_sup: this.firstPageBooking_sup,
          nextPageBooking_sup: this.nextPageBooking_sup,
          previousPageBooking_sup: this.previousPageBooking_sup,
          getPage_PaymentCheck: this.getPage_PaymentCheck,
          firstPageBooking_sup_B002: this.firstPageBooking_sup_B002,
          nextPageBooking_sup_b002: this.nextPageBooking_sup_b002,
          previousPageBooking_sup_b002: this.previousPageBooking_sup_b002,
          firstPageBooking_sup_B003: this.firstPageBooking_sup_B003,
          nextPageBooking_sup_b003: this.nextPageBooking_sup_b003,
          previousPageBooking_sup_b003: this.previousPageBooking_sup_b003,
          genExportB002toExcel: this.genExportB002toExcel,
          firstPageBooking_sup_B004: this.firstPageBooking_sup_B004,
          nextPageBooking_sup_b004: this.nextPageBooking_sup_b004,
          previousPageBooking_sup_b004: this.previousPageBooking_sup_b004,


        }}
      >
        {this.props.children}
      </SaleInfo_Context.Provider>

    );
  }
}
const SaleInfo_Consumer = SaleInfo_Context.Consumer;

export { SaleInfo_Provider, SaleInfo_Consumer, SaleInfo_Context };

export function withSaleInfo_Consumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <SaleInfo_Consumer>
        {value => <Component {...props} context={value} />}
      </SaleInfo_Consumer>
    );
  };
}

