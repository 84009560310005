import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

    AlertError,
} from '../../components/Utility/MyString';

const Account_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/sales/acard_save";



export default class Account_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                item_no: '',
                acard_no: '',
                jobdate: '',
                jobtime: '',
                saleperson: '',
                saleteam: '',
                sourceofcus: '',
                customername: '',
                address_no: '',
                address_road: '',
                address_tumbon: '',
                address_amphur: '',
                address_province: '',
                address_postcode: '',
                telephone: '',
                mobilephone: '',
                ext_lineid: '',
                ext_fbid: '',
                ext_email: '',
                ext_car_brand: '',
                ext_car_model: '',
                ext_car_year: '',
                note: '',
                jobstatus: '',
                inputtype: '',
                persontype: '',
                personname_prx: '',
                personname_main: '',
                personname_sfx: '',
                details1: '',
                details2: '',
                details3: '',
                details4: '',
                details5: '',
                companycode: ''
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],

            itempage_receiptcar: [
                {
                    item_no: '',
                    itemname: '',
                    unitprice: '',
                    companycode: '',
                    totalrecord: 1
                }],
            itempage_receiptcarlist: [
                {
                    receipt_no: '',
                    recdiptdate: '',
                    grandtotal: '',
                    companycode: '',
                    totalrecord: 1
                }],

            itempage_paymentlist: [
                {
                    item_no: '',
                    acard_no: '',
                    companycode: '',
                    totalrecord: 1
                }],


            itempage_Invitelist: [
                {

                    manager: '',
                    sale: '',
                    productid: '',
                    itemname: '', buydate2: '',
                    buydate_tbr: '', sale_code: '', reservation_no: '', ref_no2: '', ref_no3: '',
                    customername: '', dn_no: '', cassienumber: '', cartype: '', model: '',
                    color: '', buyfrom: '', saletype: '', financename: '', saleprice: '',
                    subsidy_note: ''


                }], 
                
                itempage_Invitelist2: [
                    {
    
                        manager: '',
                        sale: '',
                        productid: '',
                        itemname: '', buydate2: '',
                        buydate_tbr: '', sale_code: '', reservation_no: '', ref_no2: '', ref_no3: '',
                        customername: '', dn_no: '', cassienumber: '', cartype: '', model: '',
                        color: '', buyfrom: '', saletype: '', financename: '', saleprice: '',
                        subsidy_note: ''
    
    
                    }],

            itempage_fklist: [
                {
                    imagename: ''

                }],

            itempage_CancelBK: [
                {
                    reservatedate: '', reservation_no: '', statusrefno: '', salecar_no: ''
                    , ref_no: '', promotion: '', customername: '', contactphone: '', sale: ''
                    , manager: ''
                    , reservateprice: '', downpayment: '', principle: '', advancepay: ''
                    , extrapay: '', moreaccessorie: ''
                    , dn_no: '', model: '', cartype: '', color: '', enginenumber: ''
                    , cassienumber: '', duedate: '', status: ''

                }],

                itempage_approve_list: [
                    {
                      process_id: '',
                      createdate_th: '',
                      request_details1: '',
                      ref_no: '',
                      qtyapprove: '',
                      qty: '' 
                      
            
                    }],
          

            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strfromdate: '',
            strenddate: '',
            address_postcode: '',
            ext_car_brand: '',
            total: '0',

            companycode: '',
            totalrecord: 0,
            pagecount: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getRptPayment(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getRptPayment(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getRptPayment(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getRptPayment(callBack);
        }, 200)

    }

    getPage = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/sales/acard_page";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                    //  alert("context acard  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,
                        totalrecord: response.data[0].totalrecord,
                        pagecount: xpagecount
                    });
                    // }, 100)


                    // setTimeout(function () {
                    callBack(response.data)
                    // }, 100)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };




    getData = async (code, callBack) => {
        try {

            //  alert(code);
            //alert("service api ->" + ServiceApi)




            await axios.get(BaseApi + "/api/sales/acard_getbyid", { params: { code: code } })
                .then((response) => {

                    // AlertError("context getData  from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            callBack()
        }
    };




    //select changed
    handleChange = (str1, str2, callBack) => {
        this.setState({
            //...this.state,
            strfromdate: str1,
            strenddate: str2

        });
        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getSummary(callBack)
        }, 200)

    }





    SaveReceiptcar = async (xdata, callBack) => {

        try {

            let xdata = {

                receiptcarno: sessionStorage.getItem("receiptcarno")
                , customerid: sessionStorage.getItem("customerid")
                , ref_no: sessionStorage.getItem("ref_no")
                , receiptdate: sessionStorage.getItem("receiptdate")
                , acard_no: sessionStorage.getItem("acard_no")
                , fee: sessionStorage.getItem("fee")
                , slipno: sessionStorage.getItem("slipno")
                , userid: sessionStorage.getItem("userid")
                , companycode: sessionStorage.getItem("companycode")


            }

            let url = BaseApi + "/api/acard/acard_savereceiptcar";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    this.setState({
                        ...this.state,
                        data: res.data
                    });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }




    getDataReceiptcar = async (code, callBack) => {
        try {

            let url = BaseApi + "/api/acard/acard_getdatareceiptcar";

            let receipt_no = sessionStorage.getItem("receipt_no");
            let acard_no = sessionStorage.getItem("acard_no");

            let userid = sessionStorage.getItem("userid");
            let companycode = sessionStorage.getItem("companycode");


            let page = {

                receipt_no: receipt_no,
                acard_no: acard_no

            };

            //   alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    //    AlertError("context getDataReceiptcar from server->" + response.data.details) ;


                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            data: response.data.data,
                            itempage_receiptcar: response.data.details,
                            customer: response.data.customer,

                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error: error }]);
        }
    };



    getPageReceiptcar = async (code, callBack) => {
        try {

            let url = BaseApi + "/api/acard/acard_getpagereceiptcar";

            let acard_no = sessionStorage.getItem("acard_no");
            let userid = sessionStorage.getItem("userid");
            let companycode = sessionStorage.getItem("companycode");


            let page = {

                acard_no: acard_no

            };

            //   alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    //    AlertError("context getDataReceiptcar from server->" + response.data.details) ;


                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_receiptcarlist: response.data.data,
                            //   itempage_receiptcar:  response.data.details ,


                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error: error }]);
        }
    };






    NewReceiptcarByEpayment = async (xdata, callBack) => {

        try {

            let xdata = {


                transactionid: sessionStorage.getItem("transactionid")
                , userid: sessionStorage.getItem("userid")
                , companycode: sessionStorage.getItem("companycode")


            }

            let url = BaseApi + "/api/account/account_newreceiptcarbyepayment";

            //   alert("context NewReceiptcarByEpayment->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    this.setState({
                        ...this.state,
                        data: res.data
                    });

                    //   AlertError("context NewReceiptcarByEpayment from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }





    Getlistreceiptcarbypayment = async (callBack) => {
        try {

            let url = BaseApi + "/api/account/getlistreceiptcarbypayment";

            let page = {


                acard_no: sessionStorage.getItem("acard_no"),
                userid: sessionStorage.getItem("userid"),
                companycode: sessionStorage.getItem("companycode"),
            };

            // alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    // alert("context getRptPayment from server->" + JSON.stringify(response.data));


                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            // data: response.data,
                            itempage_paymentlist: response.data,
                            total: response.data[0].total,

                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error }]);
        }
    };



    getRptPayment = async (callBack) => {
        try {

            let url = BaseApi + "/api/acard/getrptpayment";

            let page = {

                pageindex: this.state.pageindex || 1,
                pagesize: this.state.pagesize,

                filter_status: sessionStorage.getItem("filter_status"),
                filter_date_from: sessionStorage.getItem("filter_date_from"),
                filter_date_to: sessionStorage.getItem("filter_date_to"),

                filter_sortby: sessionStorage.getItem("filter_sortby"),
                filter_sorttype: sessionStorage.getItem("filter_sorttype"),
                filter_branch: sessionStorage.getItem("filter_branch"),
                strsearch: sessionStorage.getItem("strsearch"),

                userid: sessionStorage.getItem("userid"),
                companycode: sessionStorage.getItem("companycode"),
            };

            // alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    // alert("context getRptPayment from server->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) {
                        xpagecount = xpagecount + 1;
                    }

                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            // data: response.data,
                            itempage_paymentlist: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount,
                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error }]);
        }
    };



    getRptInvite = async (callBack) => {
        try {

            let url = BaseApi + "/api/account/getrptinvite";

            let page = {

                pageindex: this.state.pageindex || 1,
                pagesize: this.state.pagesize,

                filter_status: sessionStorage.getItem("filter_status"),
                filter_date_from: sessionStorage.getItem("filter_date_from"),
                filter_date_to: sessionStorage.getItem("filter_date_to"),

                filter_sortby: sessionStorage.getItem("filter_sortby"),
                filter_sorttype: sessionStorage.getItem("filter_sorttype"),
                filter_branch: sessionStorage.getItem("filter_branch"),
                strsearch: sessionStorage.getItem("strsearch"),

                userid: sessionStorage.getItem("userid"),
                companycode: sessionStorage.getItem("companycode"),
            };

            // alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    // alert("context getrptinvite from server->" + JSON.stringify(response.data.fk_list));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) {
                        xpagecount = xpagecount + 1;
                    }

                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            // data: response.data,
                            itempage_Invitelist: response.data,

                            // itempage_fklist: response.data.fk_list,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount,
                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error }]);
        }
    };






    APApprovePaycheck = async (callBack) => {

        try {

            let xdata = {


                sale_code: sessionStorage.getItem("sale_code")
                , reservation_no: sessionStorage.getItem("reservation_no")
                , dn_no: sessionStorage.getItem("dn_no")
                , acard_no: sessionStorage.getItem("acard_no")
                , ref_no2: sessionStorage.getItem("invite_ref_no2")
                , confirm2: sessionStorage.getItem("confirm2")
                , ref_date2: sessionStorage.getItem("ref_date2")
                , remark2: sessionStorage.getItem("remark2")
                , paycheckid: sessionStorage.getItem("paycheckid") 
                , edittype : sessionStorage.getItem("edittype") 
                , itemname: sessionStorage.getItem("itemname")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/account/apapprovepaycheck";

            //   alert("context NewReceiptcarByEpayment->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {

                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //   AlertError("context NewReceiptcarByEpayment from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    CashierApprovePaycheck = async (callBack) => {

        try {

            let xdata = {


                sale_code: sessionStorage.getItem("sale_code")
                , reservation_no: sessionStorage.getItem("reservation_no")
                , dn_no: sessionStorage.getItem("dn_no")
                , acard_no: sessionStorage.getItem("acard_no")
                , ref_no1: sessionStorage.getItem("ref_no1")
                , confirm1: sessionStorage.getItem("confirm1")
                , ref_date1: sessionStorage.getItem("ref_date1")
                , check_type: sessionStorage.getItem("check_type")
                , paycheckid: sessionStorage.getItem("paycheckid") 
                , itemname: sessionStorage.getItem("itemname")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/account/cashierapprovepaycheck";

            //   alert("context NewReceiptcarByEpayment->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {

                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //   AlertError("context NewReceiptcarByEpayment from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    getDataInvite = async (callBack) => {

        try {

            let xdata = {


                sale_code: sessionStorage.getItem("sale_code")
                , reservation_no: sessionStorage.getItem("reservation_no")
                , dn_no: sessionStorage.getItem("dn_no")
                , acard_no: sessionStorage.getItem("acard_no")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/account/getinvitedetail";

            //    alert("context getDataInvite->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {

                    this.setState({
                        ...this.state,
                         itempage_approve_list: res.data.approve_list,

                    });

                    // AlertError("context getDataInvite from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }



    getRptCancelBooking = async (callBack) => {
        try {

            let url = BaseApi + "/api/account/getrptcancelbooking";

            let page = {

                pageindex: this.state.pageindex || 1,
                pagesize: this.state.pagesize,

                filter_status: sessionStorage.getItem("filter_status"),
                filter_date_from: sessionStorage.getItem("filter_date_from"),
                filter_date_to: sessionStorage.getItem("filter_date_to"),

                filter_sortby: sessionStorage.getItem("filter_sortby"),
                filter_sorttype: sessionStorage.getItem("filter_sorttype"),
                filter_branch: sessionStorage.getItem("filter_branch"),
                strsearch: sessionStorage.getItem("strsearch"),

                userid: sessionStorage.getItem("userid"),
                companycode: sessionStorage.getItem("companycode"),
            };

            // alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    // alert("context getrptinvite from server->" + JSON.stringify(response.data.fk_list));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) {
                        xpagecount = xpagecount + 1;
                    }

                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            // data: response.data,
                            itempage_CancelBK: response.data,

                            // itempage_fklist: response.data.fk_list,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount,
                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error }]);
        }
    };


    CashierApproveBKCancel = async (callBack) => {

        try {

            let xdata = {



                reservation_no: sessionStorage.getItem("reservation_no")
                , acard_no: sessionStorage.getItem("acard_no")
                , ref_no1: sessionStorage.getItem("ref_no1")
                , customername: sessionStorage.getItem("customername")
                , confirm1: sessionStorage.getItem("confirm1")
                , ref_date1: sessionStorage.getItem("ref_date1")
                , check_type: sessionStorage.getItem("check_type")
                , paycheckid: sessionStorage.getItem("paycheckid") 
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/account/cashierapprovebkcancel";

            //   alert("context NewReceiptcarByEpayment->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {

                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //   AlertError("context NewReceiptcarByEpayment from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    APApproveBKCancel = async (callBack) => {

        try {

            let xdata = {

                reservation_no: sessionStorage.getItem("reservation_no") 
                , acard_no: sessionStorage.getItem("acard_no")
                , ref_no2: sessionStorage.getItem("invite_ref_no2")
                , confirm2: sessionStorage.getItem("confirm2")
                , ref_date2: sessionStorage.getItem("ref_date2")
                , remark2: sessionStorage.getItem("remark2")
                , paycheckid: sessionStorage.getItem("paycheckid") 
                , edittype : sessionStorage.getItem("edittype") 
                , customername: sessionStorage.getItem("customername")
                , userid: sessionStorage.getItem("userid")

            }

            let url = BaseApi + "/api/account/apapprovebkcancel";

           

            await axios.post(url, { data: xdata })

                .then((res) => {

                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //   AlertError("context NewReceiptcarByEpayment from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    } 


    CashierRejectBKCancel = async (callBack) => {

        try {

            let xdata = {



                 reservation_no: sessionStorage.getItem("reservation_no")
                , acard_no: sessionStorage.getItem("acard_no") 
                , customername: sessionStorage.getItem("customername")
                , confirm1: sessionStorage.getItem("confirm1")
                , ref_date1: sessionStorage.getItem("ref_date1")
                , check_type: sessionStorage.getItem("check_type")
                , paycheckid: sessionStorage.getItem("paycheckid") 
                , remark1 : sessionStorage.getItem("remark1")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/account/cashierrejectbkcancel";

            //   alert("context NewReceiptcarByEpayment->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {

                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //   AlertError("context NewReceiptcarByEpayment from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
 
    APRejectBKCancel = async (callBack) => {

        try {

            let xdata = {



                 reservation_no: sessionStorage.getItem("reservation_no")
                , acard_no: sessionStorage.getItem("acard_no") 
                , customername: sessionStorage.getItem("customername")
                , confirm2: sessionStorage.getItem("confirm2")
                , ref_date2: sessionStorage.getItem("ref_date2")
                , check_type: sessionStorage.getItem("check_type")
                , paycheckid: sessionStorage.getItem("paycheckid") 
                , remark2 : sessionStorage.getItem("remark2")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/account/aprejectbkcancel";

            //   alert("context NewReceiptcarByEpayment->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {

                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //   AlertError("context NewReceiptcarByEpayment from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    

    CashierRejectInvite = async (callBack) => {

        try {

            let xdata = {


                sale_code: sessionStorage.getItem("sale_code")
                , reservation_no: sessionStorage.getItem("reservation_no")
                , dn_no: sessionStorage.getItem("dn_no")
                , acard_no: sessionStorage.getItem("acard_no")
                , ref_no1: sessionStorage.getItem("ref_no1")
                , confirm1: sessionStorage.getItem("confirm1")
                , ref_date1: sessionStorage.getItem("ref_date1")
                , check_type: sessionStorage.getItem("check_type")
                , paycheckid: sessionStorage.getItem("paycheckid") 
                , remark1: sessionStorage.getItem("remark1")
                , itemname: sessionStorage.getItem("customername")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/account/cashierrejectinvite";

            //   alert("context NewReceiptcarByEpayment->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {

                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //   AlertError("context NewReceiptcarByEpayment from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    

    APRejectInvite = async (callBack) => {

        try {

            let xdata = {


                sale_code: sessionStorage.getItem("sale_code")
                , reservation_no: sessionStorage.getItem("reservation_no")
                , dn_no: sessionStorage.getItem("dn_no")
                , acard_no: sessionStorage.getItem("acard_no") 
                , confirm2: sessionStorage.getItem("confirm2")
                , ref_date2: sessionStorage.getItem("ref_date2")
                , remark2: sessionStorage.getItem("remark2")
                , check_type: sessionStorage.getItem("check_type") 
                , paycheckid: sessionStorage.getItem("paycheckid") 
                , itemname: sessionStorage.getItem("customername")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/account/aprejectinvite";

            //   alert("context NewReceiptcarByEpayment->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {

                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //   AlertError("context NewReceiptcarByEpayment from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    

    
    previousPageInvite = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getRptInvite(callBack)
        }, 200)

    }

    nextPageInvite = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getRptInvite(callBack)
        }, 200)

    }


    firstPageInvite = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getRptInvite(callBack)
        }, 200)

    }

    
    getRptInvite_2 = async (callBack) => {
        try {

            let url = BaseApi + "/api/account/getrptinvite_2";

            let page = {

                pageindex: this.state.pageindex || 1,
                pagesize: this.state.pagesize,

                filter_status: sessionStorage.getItem("filter_status"),
                filter_date_from: sessionStorage.getItem("filter_date_from"),
                filter_date_to: sessionStorage.getItem("filter_date_to"),

                filter_sortby: sessionStorage.getItem("filter_sortby"),
                filter_sorttype: sessionStorage.getItem("filter_sorttype"),
                strsearch: sessionStorage.getItem("strsearch"),

                userid: sessionStorage.getItem("userid"),
                companycode: sessionStorage.getItem("companycode"),
            };

            // alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    // alert("context getrptinvite from server->" + JSON.stringify(response.data.fk_list));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) {
                        xpagecount = xpagecount + 1;
                    }

                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            // data: response.data,
                            itempage_Invitelist2: response.data,

                            // itempage_fklist: response.data.fk_list,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount,
                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error }]);
        }
    };

    
    genExcelRptInvite_2 = async (callBack) => {
        try {

            let url = BaseApi + "/api/account/genexcelrptinvite_2";

            let page = {

                pageindex: this.state.pageindex || 1,
                pagesize: this.state.pagesize,

                filter_status: sessionStorage.getItem("filter_status"),
                filter_date_from: sessionStorage.getItem("filter_date_from"),
                filter_date_to: sessionStorage.getItem("filter_date_to"),

                filter_sortby: sessionStorage.getItem("filter_sortby"),
                filter_sorttype: sessionStorage.getItem("filter_sorttype"),
                strsearch: sessionStorage.getItem("strsearch"),

                userid: sessionStorage.getItem("userid"),
                companycode: sessionStorage.getItem("companycode"),
            };

            // alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    // alert("context getrptinvite from server->" + JSON.stringify(response.data.fk_list));

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_Invitelist: response.data
                        });
                    }, 100)
      
                    
                    setTimeout(function () {
                      callBack(response.data);
                  }, 100);
      


                });
        } catch (error) {

            callBack([{ error }]);
        }
    };


    

AccountApproveCancelBooking = async (callBack) => {

    try {


      let xdata = {


         acard_no: sessionStorage.getItem("acard_no")
        , item_no: sessionStorage.getItem("item_no") 
        , approve: sessionStorage.getItem("approve")
        , approve_note: sessionStorage.getItem("approve_note")
        , substatus: sessionStorage.getItem("substatus") 
        , request_details2: sessionStorage.getItem("request_details2")
        , userid: sessionStorage.getItem("userid")

        


      }



      let url = BaseApi + "/api/account/account_approve_cancel_booking";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  
AccountApproveInvite = async (callBack) => {

    try {


      let xdata = {


         acard_no: sessionStorage.getItem("acard_no")
        , item_no: sessionStorage.getItem("item_no") 
        , approve: sessionStorage.getItem("approve")
        , approve_note: sessionStorage.getItem("approve_note")
        , substatus: sessionStorage.getItem("substatus") 
        , request_details2: sessionStorage.getItem("request_details2")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/account/account_approve_invite";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

   
  genExcelRptCancelBK = async (callBack) => {
    try {

        let url = BaseApi + "/api/account/genexcelrptcancelbk";
   

        let page = {

            pageindex: this.state.pageindex || 1,
            pagesize: this.state.pagesize,

            filter_status: sessionStorage.getItem("filter_status"),
            filter_date_from: sessionStorage.getItem("filter_date_from"),
            filter_date_to: sessionStorage.getItem("filter_date_to"),

            filter_sortby: sessionStorage.getItem("filter_sortby"),
            filter_sorttype: sessionStorage.getItem("filter_sorttype"),
            filter_branch: sessionStorage.getItem("filter_branch"),
            strsearch: sessionStorage.getItem("strsearch"),

            userid: sessionStorage.getItem("userid"),
            companycode: sessionStorage.getItem("companycode"),
        };

        // alert("context   ->" + JSON.stringify(page));



        await axios
            .post(url, { data: page })

            .then((response) => {
                // console.log(response)

                // alert("context getrptinvite from server->" + JSON.stringify(response.data.fk_list));

                // let xpagecount = parseInt(response.data[0].totalrecord / 20);
                // let ypage = response.data[0].totalrecord % 20;
                // if (ypage > 0) {
                //     xpagecount = xpagecount + 1;
                // }

                let obj = this;

                // setTimeout(function () {
                //     obj.setState({
                //         ...obj.state,
                //         // data: response.data,
                //         itempage_CancelBK: response.data,

                //         // itempage_fklist: response.data.fk_list,
                //         // totalrecord: response.data[0].totalrecord,
                //         // pagecount: xpagecount,
                //     });
                // }, 100);

                setTimeout(function () {
                    callBack(response.data);
                }, 100);


            });
    } catch (error) {

        AlertError(error.message);
    }
};

 
firstPageInvite_A77 = async (callBack) => {
    this.setState({
        ...this.state,
        pageindex: 1
    })

    let obj = this
    setTimeout(function () {
        //alert("from context->" + obj.state.strsearch);
        obj.getRptInvite_A77(callBack)
    }, 200)

}
 

getRptInvite_A77 = async (callBack) => {
    try {

        let url = BaseApi + "/api/account/getrptinvite_a77";

        let page = {

            pageindex: this.state.pageindex || 1,
            pagesize: this.state.pagesize,

            filter_status: sessionStorage.getItem("filter_status"),
            filter_date_from: sessionStorage.getItem("filter_date_from"),
            filter_date_to: sessionStorage.getItem("filter_date_to"),

            filter_sortby: sessionStorage.getItem("filter_sortby"),
            filter_sorttype: sessionStorage.getItem("filter_sorttype"),
            filter_branch: sessionStorage.getItem("filter_branch"),
            strsearch: sessionStorage.getItem("strsearch"),

            userid: sessionStorage.getItem("userid"),
            companycode: sessionStorage.getItem("companycode"),
        };

        // alert("context   ->" + JSON.stringify(page));



        await axios
            .post(url, { data: page })

            .then((response) => {
                // console.log(response)

                // alert("context getrptinvite from server->" + JSON.stringify(response.data.fk_list));

                let xpagecount = parseInt(response.data[0].totalrecord / 20);
                let ypage = response.data[0].totalrecord % 20;
                if (ypage > 0) {
                    xpagecount = xpagecount + 1;
                }

                let obj = this;

                setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        // data: response.data,
                        itempage_Invitelist: response.data,

                        // itempage_fklist: response.data.fk_list,
                        totalrecord: response.data[0].totalrecord,
                        pagecount: xpagecount,
                    });
                }, 100);

                setTimeout(function () {
                    callBack(response.data);
                }, 100);


            });
    } catch (error) {

        callBack([{ error }]);
    }
};






    render() {
        return (
            <Account_Context.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData,
                    getData: this.getData,

                    getPage: this.getPage,

                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,

                    SaveReceiptcar: this.SaveReceiptcar,
                    getDataReceiptcar: this.getDataReceiptcar,
                    getPageReceiptcar: this.getPageReceiptcar,
                    NewReceiptcarByEpayment: this.NewReceiptcarByEpayment,
                    Getlistreceiptcarbypayment: this.Getlistreceiptcarbypayment,
                    getRptPayment: this.getRptPayment,
                    getRptInvite: this.getRptInvite,
                    APApprovePaycheck: this.APApprovePaycheck,
                    CashierApprovePaycheck: this.CashierApprovePaycheck,
                    getDataInvite: this.getDataInvite,
                    getRptCancelBooking: this.getRptCancelBooking,
                    CashierApproveBKCancel: this.CashierApproveBKCancel,
                    APApproveBKCancel: this.APApproveBKCancel,
                    CashierRejectBKCancel: this.CashierRejectBKCancel,
                    APRejectBKCancel: this.APRejectBKCancel,
                    APRejectInvite : this.APRejectInvite,
                    CashierRejectInvite : this.CashierRejectInvite,
                    previousPageInvite: this.previousPageInvite,
                    nextPageInvite: this.nextPageInvite,
                    firstPageInvite: this.firstPageInvite,
                    getRptInvite_2: this.getRptInvite_2, 
                    genExcelRptInvite_2: this.genExcelRptInvite_2,
                    AccountApproveCancelBooking: this.AccountApproveCancelBooking,
                    AccountApproveInvite: this.AccountApproveInvite,
                    genExcelRptCancelBK: this.genExcelRptCancelBK,
                    firstPageInvite_A77 : this.firstPageInvite_A77,
                   

                }}
            >
                {this.props.children}
            </Account_Context.Provider>

        );
    }
}
const Account_Consumer = Account_Context.Consumer;

export { Account_Provider, Account_Consumer, Account_Context };

export function withAccount_Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <Account_Consumer>
                {value => <Component {...props} context={value} />}
            </Account_Consumer>
        );
    };
}

