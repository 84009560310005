import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

  AlertError,
} from '../../components/Utility/MyString';

const Score_Card_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/sales/acard_save";



export default class Score_Card_Provider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        item_no: '',
        acard_no: '',
        sourceofcus: '',
        media: '',
        cusgroup: '',
        custype: '',
        colour: '',
        salejobtype: '',
        acard_contace: '',
        customername: '',
        mobilephone: '',
        userid: '',
        companycode: ''
      },
      userAuthorize: [
        {
          userid: '',
          menucode: '',
          menutext: '',
          useuse: 0,
          isinsert: 0,
          isupdate: 0,
          isdelete: 0
        }
      ],
      itempage: [
        {
          item_no: '',
          acard_no: '',
          companycode: '',
          totalrecord: 1
        }],
      itemscore: [
        {
          item_id: '',
          group2_id: '',
          item_name1: '',
          item_detail: '',
          itm_item_id: '',
          score: '',
          totalrecord: 1
        }],

      itempage_contactlist: [
        {
          menucode: '',
          menutext: ''

        }],
      itempage_detaillist: [
        {
          item_no: '',
          contactdate: '',
          contacttime: '',
          channel: '',
          details: '',
          actions: '',
          status: '',
          followup: '',
          followupdate: '',
          acard_no: '',
          customername: '',

        }],
      itempage_partsetitems: [
        {
          productid: '',
          partsetname: '',
          category: '',
          partsetno: '',
          item_name: '',
          quantity: '',
          unitprice: '',
          amount: '',
          vat: '',
          totalamount: '',

        }],


      itempage_partsetitems_select: [
        {
          productid: '',
          partsetname: '',
          category: '',
          partsetno: '',
          item_name: '',
          quantity: '',
          unitprice: '',
          amount: '',
          vat: '',
          totalamount: '',

        }],

      detail_fee: [
        {

          item_name: '',
          amount: '',

        }],
      detail_fee2: [
        {

          item_name: '',
          amount: '',

        }],
      // detail_h3: [
      //   {

      //     item_name: '',
      //     amount: '',

      //   }],

      detail_h2: [
        {

          item_name: '',
          amount: '',

        }],


      detail_discount: [
        {

          itemname: '',
          amount: '',

        }],
      itempage_qrlist: [
        {
          quatationdate: '',
          item_no: '',
          itemname: '',
          amount: '',

        }],
      itempage_qulist: [
        {
          quatationdate: '',
          item_no: '',
          itemname: '',
          amount: '',

        }],



      itempage_category: [
        {

          itemcategory: '',


        }],

      item_db: [
        {
          itemname: '',
          customername: '',
          job_name1: '',


        }],
        
      item_acard: [
        {
          itemname: '',
          customername: '',
          job_name1: '',


        }],


      item_Ahot: [
        {
          itemname: '',
          customername: '',
          job_name1: '',
        }],

      item_Awarm: [
        {
          itemname: '',
          customername: '',
          job_name1: '',
        }],

      item_Acold: [
        {
          itemname: '',
          customername: '',
          job_name1: '',


        }],
      item_prebooking: [
        {
          itemname: '',
          customername: '',
          job_name1: '',


        }],

      item_booking: [
        {
          itemname: '',
          customername: '',
          job_name1: '',
        }],

      item_fc: [
        {
          itemname: '',
          customername: '',
          job_name1: '',
        }],

      item_matching: [
        {
          itemname: '',
          customername: '',
          job_name1: '',
        }],

      item_sale_report: [
        {
          itemname: '',
          customername: '',
          job_name1: '',
        }],

      item_vdqi: [
        {
          itemname: '',
          customername: '',
          job_name1: '',
        }],

      item_sold: [
        {
          itemname: '',
          customername: '',
          job_name1: '',
        }],
      item_hot_wait: [
        {
          itemname: '',
          customername: '',
          job_name1: '',
        }],
      item_warm_wait: [
        {
          itemname: '',
          customername: '',
          job_name1: '',
        }],

      list_licensetype: [
        {
          item_name: '',

        }],
      list_launchyear: [
        {
          launchyear: '',

        }],
      list_province: [
        {
          province_th: '',

        }],

      itempage_processid: [
        {
          process_id: '',
          process_name: '',

        }],

      itempage_monitor2: [
        {
          item_no: '',
          transtype: '',
          ref_no: '',
          ref_no2: '',
          actdate: '',
          details: '',
          operatorname: '',
          authorize: ''

        }],

      itempage_approve_list: [
        {
          process_id: '',
          createdate_th: '',
          request_details1: '',
          ref_no: '',
          qtyapprove: '',
          qty: ''


        }],



      pageindex: 1,
      pagesize: 10,
      strsearch: '',
      strfromdate: '',
      strenddate: '',
      companycode: '',
      totalrecord: 0,
      pagecount: 0,
      isLogin: false
    }
  }


  setData = async (values) => {
    this.setState({
      ...this.state,
      data: values
    });

    console.log("state->" + JSON.stringify(this.state))

  };


  firstPage = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  previousPage = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  nextPage = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  lastPage = async (callBack) => {
    this.setState({
      pageindex: this.state.pagecount
    });

    let obj = this

    setTimeout(function () {
      //alert("Last Page ...." + obj.state.pageindex)
      obj.getPage(callBack);
    }, 200)

  }


  nextPageApprove = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPageApprove(callBack)
    }, 200)

  }


  previousPageApprove = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPageApprove(callBack)
    }, 200)

  }


  firstPageApprove = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPageApprove(callBack)
    }, 200)

  }

  getData = async (code, callBack) => {
    try {

      //  alert(code);
      //alert("service api ->" + ServiceApi)




      await axios.get(BaseApi + "/api/sales/acard_getbyid", { params: { code: code } })
        .then((response) => {

          // AlertError("context getData  from server->" + JSON.stringify(response.data));

          this.setState({
            ...this.state,
            data: response.data,
          });
          setTimeout(function () {
            callBack(response.data)
          }, 500)

        })


    } catch (error) {
      AlertError(error.message);
      callBack()
    }
  };



  saveData = async (xdata, callBack) => {

    try {

      //callBack()
      //xdata.password = this.state.data.password
      //alert("password->" + this.state.data.password)

      //    alert("acard context save -> " + xdata)

      await axios.post(ServiceApi, {
        data: xdata
      })
        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  updateData = async (xdata, callBack) => {

    try {

      //callBack()
      //xdata.password = this.state.data.password
      //alert("password->" + this.state.data.password)

      await axios.put(ServiceApi, {
        data: xdata
      })
        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  deleteData = async (xdata, callBack) => {

    try {

      //alert("delete from context -> " + ServiceApi )

      await axios.delete(ServiceApi, {
        data: {
          code: xdata.code
        }
      })
        .then((res) => {
          if (res.data === "true") {
            setTimeout(function () {
              //message.success('Delete Data Complete..', 5);
              callBack(true)
            }, 500)
          }
        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  //select changed
  handleChange = (str1, str2, callBack) => {
    this.setState({
      //...this.state,
      strfromdate: str1,
      strenddate: str2

    });
    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }




  getCustomerAcardbyID = async (code, callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getcustomeracardbyid";

      let acard_no = sessionStorage.getItem("acard_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");



      let page = {

        acard_no: acard_no,
        userid: userid,
        companycode: companycode
      };


      await axios
        .post(url, { data: page })
        .then((response) => {

          // AlertError("context from server->" + JSON.stringify(response.data.reservate));


          // setTimeout(function () {
          //   obj.setState({
          //     ...obj.state,
          //     data: response.data.data,
          //     itemscore: response.data.detail,

          //   });
          // }, 100);

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data
              // data: response.data.data,
              // customer: response.data.customer,
              // reservate: response.data.reservate,
              // receiptcar_no: response.data.receiptcar_no,
              // payment: response.data.payment,
              // statusotp: response.data.statusotp,
              // refno: response.data.refno,
              // statusfk: response.data.statusfk,
              // statusfk_invite: response.data.statusfk_invite,
              // statusbkcancel: response.data.statusbkcancel,

              // campaign: response.data.campaign,
              // status_fk: response.data.status_fk,
              // payment_check: response.data.payment_check,
              // customer_check: response.data.customer_check,
              // itempage_monitor2: response.data.monitor2,
              itempage_approve_list: response.data.approve_list,
              // payment_check_Invite: response.data.payment_check_Invite,
              // approve_booking_cancel: response.data.approve_booking_cancel,


            });
          }, 100);


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error);
    }
  };






  getScorecardbyID = async (code, callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getscorecardbyid";

      let cusdb_id = sessionStorage.getItem("cusdb_id");
      let userid = sessionStorage.getItem("userid");


      let page = {

        cusdb_id: cusdb_id,
        userid: userid
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //   AlertError("context getScorecardbyID from server->" + JSON.stringify(response.data.detail));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data.data,
              itemscore: response.data.detail,

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };




  getPage = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,
        // filter_month: sessionStorage.getItem("filter_month"),
        filter_date_from: sessionStorage.getItem("filter_date_from"),
        filter_date_to: sessionStorage.getItem("filter_date_to"),
        filter_userid: sessionStorage.getItem("filter_userid"),
        filter_team: sessionStorage.getItem("filter_team"),
        filter_jobstatus: sessionStorage.getItem("filter_jobstatus"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid")
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/sales/customeracard_list";

      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          let xpagecount = parseInt(response.data[0].totalrecord / this.state.pagesize);
          let ypage = response.data[0].totalrecord % this.state.pagesize;
          if (ypage > 0) {
            xpagecount = xpagecount + 1;
          }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              itempage: response.data,
              totalrecord: response.data[0].totalrecord,
              count_all: response.data[0].count_all,
              count_acard: response.data[0].count_acard,
              count_acard_hot: response.data[0].count_acard_hot,
              count_acard_warm: response.data[0].count_acard_warm,
              count_acard_cold: response.data[0].count_acard_cold,
              count_booking: response.data[0].count_booking,
              count_acard_prebooking: response.data[0].count_acard_prebooking,
              count_acard_fc: response.data[0].count_acard_fc,
              count_acard_matching: response.data[0].count_acard_matching,
              count_acard_salereport: response.data[0].count_acard_salereport,
              count_acard_vdqi: response.data[0].count_acard_vdqi,
              count_acard_sold: response.data[0].count_acard_sold,
              count_acard_reject: response.data[0].count_acard_reject,
              count_acard_wait: response.data[0].count_acard_wait,
              count_acard_warm_reject: response.data[0].count_acard_warm_reject,
              count_acard_warm_wait: response.data[0].count_acard_warm_wait,

              pagecount: xpagecount,
            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };




  getRunningContact = async (callBack) => {
    try {



      let code = "101"

      let page = {
        code: code

      }

      //alert("inbox context get page")
      let url = BaseApi + "/api/sales/acardcontact_genid";


      //  alert("context   ->" + JSON.stringify(page));

      await axios.post(url, { data: page })
        .then((response) => {

          //  alert("context get   ->" + JSON.stringify(response.data));

          this.setState({
            ...this.state,
            data: {
              // item_no : response.data[0].item_no,  
              // jobdate : response.data[0].jobdate, 
              data: response.data.data,

            }
          })

          this.setState({
            ...this.state,
            data: response.data
          });

          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error);
    }
  };


  getAcardContactList = async (callBack) => {
    try {

      let companycode = sessionStorage.getItem("companycode");
      let userid = sessionStorage.getItem("userid");
      let strsearch = sessionStorage.getItem("strcontactcode");



      let page = {

        strsearch: strsearch,
        companycode: companycode,
        userid: userid,
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/acard/acardcontact_list";

      await axios
        .post(url, { data: page })

        .then((response) => {

          // alert("context     ->" + JSON.stringify(response.data));
          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,

              itempage_contactlist: response.data,

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };



  AcardSaveContact = async (callBack) => {

    try {



      let xdata = {

        item_no: sessionStorage.getItem("item_no")
        , acard_no: sessionStorage.getItem("acard_no")
        , contactdate: sessionStorage.getItem("contactdate")
        // , contacttime: sessionStorage.getItem("contacttime")
        , channelcode: sessionStorage.getItem("channelcode")
        , channel: sessionStorage.getItem("channel")
        , detailscode: sessionStorage.getItem("detailscode")
        , details: sessionStorage.getItem("details")
        , actionscode: sessionStorage.getItem("actionscode")
        , actions: sessionStorage.getItem("actions")
        , status: sessionStorage.getItem("status")
        , followup: sessionStorage.getItem("followup")
        , followupdate: sessionStorage.getItem("followupdate")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")

      }



      let url = BaseApi + "/api/acard/acard_save_contact";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  getAcardContactDetailByacardno = async (callBack) => {
    try {

      let companycode = sessionStorage.getItem("companycode");
      let userid = sessionStorage.getItem("userid");
      let acard_no = sessionStorage.getItem("acard_no");



      let page = {

        strsearch: acard_no,
        companycode: companycode,
        userid: userid,
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/acard/acardcontactdetail_listby_acardno";

      await axios
        .post(url, { data: page })

        .then((response) => {

          // alert("context     ->" + JSON.stringify(response.data));
          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,

              itempage_detaillist: response.data,

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };



  getDataContactdetail = async (callBack) => {
    try {


      let strsearch = sessionStorage.getItem("item_no");
      let companycode = sessionStorage.getItem("companycode");
      let userid = sessionStorage.getItem("userid");


      let page = {
        strsearch: strsearch,
        companycode: companycode,
        userid: userid
      };

      //   alert("context   ->" + JSON.stringify(page));

      let url = BaseApi + "/api/acard/acard_getdatadetailbyid";

      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //   AlertError("context getScorecardbyID from server->" + JSON.stringify(response.data.detail));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };





  getPartsetItemsByCarmodel = async (callBack) => {
    try {




      let page = {
        strsearch: sessionStorage.getItem("strsearch"),
        acard_no: sessionStorage.getItem("acard_no"),
        strcarmodel: sessionStorage.getItem("cartype"),
        jobstatus: sessionStorage.getItem("jobstatus"),
        salejobtype: sessionStorage.getItem("salejobtype"),
        contact_no: sessionStorage.getItem("contact_no"),
        filter_category: sessionStorage.getItem("filter_category"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid")
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/acard/partsetitemsbycarmodel";

      await axios
        .post(url, { data: page })

        .then((response) => {

          //  alert("context  itempage_partsetitems    ->" + JSON.stringify(response.data.data));
          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,


              itempage_partsetitems: response.data.data,
              itempage_category: response.data.itemcategory,


            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };





  AcardSavePartsetItems = async (callBack) => {

    try {



      let xdata = {

        productid: sessionStorage.getItem("productid")
        , item_name: sessionStorage.getItem("item_name")
        , unitprice: sessionStorage.getItem("unitprice")
        , quantity: sessionStorage.getItem("quantity")
        , amount: sessionStorage.getItem("amount")
        , unitcost: sessionStorage.getItem("unitcost")
        , vat: sessionStorage.getItem("vat")
        , totalamount: sessionStorage.getItem("totalamount")
        , acard_no: sessionStorage.getItem("acard_no")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_save_selectpartsetitems";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }



  getPartsetItemsBySelect = async (callBack) => {
    try {

      let companycode = sessionStorage.getItem("companycode");
      let userid = sessionStorage.getItem("userid");
      let acard_no = sessionStorage.getItem("acard_no");



      let page = {

        acard_no: acard_no,
        companycode: companycode,
        userid: userid,
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/acard/getpartsetitemsbyselect";

      await axios
        .post(url, { data: page })

        .then((response) => {

          // alert("context     ->" + JSON.stringify(response.data));
          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,

              itempage_partsetitems_select: response.data,

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };








  updateAcardModelcar = async (xdata, callBack) => {

    try {


      let xdata = {


        carmodel: sessionStorage.getItem("carmodel")
        , cartype: sessionStorage.getItem("cartype")
        , colour: sessionStorage.getItem("colour")
        , acard_no: sessionStorage.getItem("acard_no")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_update_modelcar";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  getPageApprove = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,
        // filter_month: sessionStorage.getItem("filter_month"),
        filter_date_from: sessionStorage.getItem("filter_date_from"),
        filter_date_to: sessionStorage.getItem("filter_date_to"),
        filter_userid: sessionStorage.getItem("filter_userid"),
        filter_team: sessionStorage.getItem("filter_team"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        filter_processid: sessionStorage.getItem("processid"),
        strsearch: sessionStorage.getItem("strsearch"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid")
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/acard/acard_approve_list";

      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          let xpagecount = parseInt(response.data.data[0].totalrecord / this.state.pagesize);
          let ypage = response.data.data[0].totalrecord % this.state.pagesize;
          if (ypage > 0) {
            xpagecount = xpagecount + 1;
          }


          //  AlertError("context getPageApprove from server->" + response.data) ;


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,

              // itempage: response.data,
              totalrecord: response.data.data[0].totalrecord,
              // count_approve_request: response.data[0].count_approve_request,
              // count_approve_reject: response.data[0].count_approve_reject,
              // count_approve_all: response.data[0].count_approve_all,
              // count_approve: response.data[0].count_approve,
              // count_approve_cancel : response.data[0].count_approve_cancel,
              pagecount: xpagecount,


              itempage: response.data.data,
              // itempage_processid: response.data.processid,




            });
          }, 100);

          setTimeout(function () {
            callBack(response.data.count_approve);
          }, 100);



        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };






  AcardApproveContact = async (callBack) => {

    try {


      let xdata = {


        acard_no: sessionStorage.getItem("acard_no")
        , item_no: sessionStorage.getItem("item_no")
        , status: sessionStorage.getItem("status")
        , approve: sessionStorage.getItem("approve")
        , approve_note: sessionStorage.getItem("approve_note")
        , substatus: sessionStorage.getItem("substatus")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_approve_contact";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }




  DBrequestupdateContact = async (callBack) => {

    try {



      let xdata = {

        item_no: sessionStorage.getItem("item_no")
        , cusdb_id: sessionStorage.getItem("cusdb_id")
        , acard_no: sessionStorage.getItem("acard_no")
        , contactdate: sessionStorage.getItem("contactdate")
        , channelcode: sessionStorage.getItem("channelcode")
        , channel: sessionStorage.getItem("channel")
        , detailscode: sessionStorage.getItem("detailscode")
        , details: sessionStorage.getItem("details")
        , actionscode: sessionStorage.getItem("actionscode")
        , actions: sessionStorage.getItem("actions")
        , status: sessionStorage.getItem("status")
        , followup: sessionStorage.getItem("followup")
        , followupdate: sessionStorage.getItem("followupdate")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")

      }



      let url = BaseApi + "/api/acard/db_request_update_contact";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }




  getFilterSubstatus = async (substatus, callBack) => {
    try {




      let page = {
        filter_substatus: substatus

      }

      await axios.post(BaseApi + "/api/acard/getsubstatus", { data: page })
        .then((response) => {

          this.setState({
            ...this.state,
            data: response.data,
          });
          setTimeout(function () {
            callBack(response.data)
          }, 500)

        })


    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };






  getColourListbycartype = async (callBack) => {
    try {




      let page = {
        cartype: sessionStorage.getItem("cartype")
        , userid: sessionStorage.getItem("userid")
        , companycode: sessionStorage.getItem("companycode")


      }

      await axios.post(BaseApi + "/api/acard/getcolorlistbycartype", { data: page })
        .then((response) => {

          this.setState({
            ...this.state,
            data: response.data,
          });
          setTimeout(function () {
            callBack(response.data)
          }, 500)

        })


    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };





  updateAcardFee = async (xdata, callBack) => {

    try {


      // let xdata = {


      //    insurance1fee: sessionStorage.getItem("insurance1fee") 
      //   ,insurance1fee_note :  sessionStorage.getItem("insurance1fee_note") 
      //   ,insurance2fee :  sessionStorage.getItem("insurance2fee")  
      //   ,insurance2fee_note :  sessionStorage.getItem("insurance2fee_note") 
      //   ,registerfee :  sessionStorage.getItem("registerfee") 
      //   ,registerfee_note :  sessionStorage.getItem("registerfee_note") 
      //   ,newregisterfee :  sessionStorage.getItem("newregisterfee") 
      //   ,newregisterfee_note :  sessionStorage.getItem("newregisterfee_note") 
      //   ,accessoriefee :  sessionStorage.getItem("accessoriefee") 
      //   ,accessoriefee_note :  sessionStorage.getItem("accessoriefee_note")  
      //   ,moreaccessorie :  sessionStorage.getItem("moreaccessorie") 
      //   ,moreaccessorie_note :  sessionStorage.getItem("moreaccessorie_note") 
      //   ,begining :  sessionStorage.getItem("begining") 
      //   ,begining_note :  sessionStorage.getItem("begining_note") 
      //   ,financecontactfee :  sessionStorage.getItem("financecontactfee") 
      //   ,financecontactfee_note :  sessionStorage.getItem("financecontactfee_note")  
      //   ,vatsubdownfee :  sessionStorage.getItem("vatsubdownfee")  
      //   ,otherfee :  sessionStorage.getItem("otherfee") 
      //   ,otherfee_note :  sessionStorage.getItem("otherfee_note") 
      //   ,otherfee2 :  sessionStorage.getItem("otherfee2") 
      //   ,otherfee2_note :  sessionStorage.getItem("otherfee2_note") 
      //   ,otherfee3 :  sessionStorage.getItem("otherfee3") 
      //   ,otherfee3_note :  sessionStorage.getItem("otherfee3_note")   
      //   ,acard_no: sessionStorage.getItem("acard_no") 
      //   , companycode: sessionStorage.getItem("companycode")
      //   , userid: sessionStorage.getItem("userid")



      // }



      let url = BaseApi + "/api/acard/acard_update_fee";

      //  alert("context updateAcardFee->" + JSON.stringify(xdata));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }





  updateAcardPayment = async (xdata, callBack) => {

    try {

      let url = BaseApi + "/api/acard/acard_update_payment";

      // alert("context updateAcardFee->" + JSON.stringify(xdata));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }






  getInsurance = async (callBack) => {
    try {




      let page = {

        userid: sessionStorage.getItem("userid")
        , companycode: sessionStorage.getItem("companycode")


      }

      await axios.post(BaseApi + "/api/acard/getinsurance", { data: page })
        .then((response) => {

          this.setState({
            ...this.state,
            data: response.data,
          });
          setTimeout(function () {
            callBack(response.data)
          }, 500)

        })


    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };





  getListcartype = async (callBack) => {
    try {




      let page = {

        carmodel: sessionStorage.getItem("carmodel")
        , userid: sessionStorage.getItem("userid")
        , companycode: sessionStorage.getItem("companycode")


      }

      await axios.post(BaseApi + "/api/acard/getcartype", { data: page })
        .then((response) => {

          this.setState({
            ...this.state,
            data: response.data,
          });
          setTimeout(function () {
            callBack(response.data)
          }, 500)

        })


    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };







  getFinancename = async (callBack) => {
    try {




      let page = {

        userid: sessionStorage.getItem("userid")
        , companycode: sessionStorage.getItem("companycode")


      }

      await axios.post(BaseApi + "/api/acard/getfinancename", { data: page })
        .then((response) => {

          this.setState({
            ...this.state,
            data: response.data,
          });
          setTimeout(function () {
            callBack(response.data)
          }, 500)

        })


    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };






  AcardPaymentPromptpay = async (xdata, callBack) => {

    try {


      let xdata = {


        mobilephone: "0949396979"
        // ,insurance1fee_note :  sessionStorage.getItem("insurance1fee_note") 
        // ,insurance2fee :  sessionStorage.getItem("insurance2fee")  
        // ,insurance2fee_note :  sessionStorage.getItem("insurance2fee_note") 



      }



      let url = BaseApi + "/api/acard/acard_promptpay";

      //  alert("context updateAcardFee->" + JSON.stringify(xdata));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }



  saveDataNewBooking = async (xdata, callBack) => {

    try {

      let xdata = {


        strref_no: sessionStorage.getItem("strref_no")
        , acard_no: sessionStorage.getItem("acard_no")
        , confirm_customer_bk: sessionStorage.getItem("confirm_customer_bk")
        , saleperson: sessionStorage.getItem("userid")
        , companycode: sessionStorage.getItem("companycode")



      }

      let url = BaseApi + "/api/acard/acardsavetobooking";

      // alert("context saveDataNewBooking->" + JSON.stringify(xdata));

      await axios.post(url, { data: xdata })
        .then((response) => {
          // .then((res) => {
          //   if (res.data === "true") {
          //     callBack(true)
          //   }
          // })

          this.setState({
            ...this.state,
            data: response.data,
          });
          setTimeout(function () {
            callBack(response.data)
          }, 500)

        })

    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };

  getAll = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {

      obj.getPage(callBack)
    }, 100)

  }




  AcardEditPartsetItems = async (callBack) => {

    try {


      let xdata = {

        productid: sessionStorage.getItem("productid")
        , item_name: sessionStorage.getItem("stritemname")
        , item_no: sessionStorage.getItem("item_no")
        , unitprice: sessionStorage.getItem("unitprice")
        , quantity: sessionStorage.getItem("quantity")
        , amount: sessionStorage.getItem("amount")
        , unitcost: sessionStorage.getItem("unitcost")
        , acard_no: sessionStorage.getItem("acard_no")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_edit_selectpartsetitems";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }




  AcardDeletePartsetItems = async (callBack) => {

    try {


      let xdata = {

        productid: sessionStorage.getItem("productid")
        , item_no: sessionStorage.getItem("item_no")
        , acard_no: sessionStorage.getItem("acard_no")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_delete_selectpartsetitems";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  

  
  AcardGetMargin = async (callBack) => {

    try {


      let xdata = {


        acard_no: sessionStorage.getItem("acard_no")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_getmargin";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          // this.setState({
          //   ...this.state,
          //   data: res.data,
          // });
          setTimeout(function () {
            callBack(res.data)
          }, 100)
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }



  AcardcallMargin = async (callBack) => {

    try {


      let xdata = {


        acard_no: sessionStorage.getItem("acard_no")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_callmargin";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          // this.setState({
          //   ...this.state,
          //   data: res.data,
          // });
          setTimeout(function () {
            callBack(res.data)
          }, 100)
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }




  AcardSaveDiscount = async (callBack) => {

    try {

      let xdata = {



        modelmargin: sessionStorage.getItem("modelmargin")
        , margin_amount1: sessionStorage.getItem("margin_amount1")
        , extracom: sessionStorage.getItem("extracom")
        , othercom: sessionStorage.getItem("othercom")
        , commission: sessionStorage.getItem("commission")
        , comfinance_sale: sessionStorage.getItem("comfinance_sale")
        , comduepayment2: sessionStorage.getItem("comduepayment2")
        , comduepayment: sessionStorage.getItem("comduepayment")
        , lblcomfinance_sale: sessionStorage.getItem("lblcomfinance_sale")
        , comratio: sessionStorage.getItem("comratio")
        , cominterest: sessionStorage.getItem("cominterest")

        , acard_no: sessionStorage.getItem("acard_no")
        , userid: sessionStorage.getItem("userid")
        , companycode: sessionStorage.getItem("companycode")



      }

      let url = BaseApi + "/api/acard/acard_savediscount";

      // alert("context acardsavediscount->" + JSON.stringify(xdata));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }





  getAcardQuatation = async (code, callBack) => {
    try {

      let url = BaseApi + "/api/acard/acard_getacardquatation";

      let quatation_id = sessionStorage.getItem("quatation_id");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        item_no: quatation_id,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              status: JSON.parse(response.data.status),
              doctype: JSON.parse(response.data.doctype),
              acard_no: JSON.parse(response.data.acard_no),
              approve_status: JSON.parse(response.data.approve_status),
              data: response.data.data,
              detail_fee: response.data.detail_fee,
              detail_h: response.data.detail_h,
              detail_h2: response.data.detail_h2,
              detail_h3: response.data.detail_h3,
              detail_discount: response.data.detail_discount,


            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };





  getQuatationList = async (code, callBack) => {
    try {

      let url = BaseApi + "/api/acard/acard_getacardquatation_list";

      let acard_no = sessionStorage.getItem("acard_no");

      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        acard_no: acard_no,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getQuatationList from server->" + JSON.stringify(response.data.data));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data.data,
              itempage_qrlist: response.data.qr,
              itempage_qulist: response.data.qu,



            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };






  NewAcardQuatation = async (code, callBack) => {
    try {

      let url = BaseApi + "/api/acard/acard_newacardquatation";

      let acard_no = sessionStorage.getItem("acard_no");
      let cusdb_id = sessionStorage.getItem("cusdb_id");
      let qr_id = sessionStorage.getItem("qr_id");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        acard_no: acard_no,
        cusdb_id: cusdb_id,
        qr_id: qr_id,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //   AlertError("context getScorecardbyID from server->" + JSON.stringify(response.data.detail));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              status: JSON.parse(response.data.status),
              data: response.data.data,
              detail_fee: response.data.detail_fee,
              detail_h: response.data.detail_h,
              detail_h2: response.data.detail_h2,
              detail_h3: response.data.detail_h3,
              detail_discount: response.data.detail_discount,


            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };




  AcardApproveQuatation = async (callBack) => {

    try {


      let xdata = {


        acard_no: sessionStorage.getItem("acard_no")
        , quatation_id: sessionStorage.getItem("quatation_id")
        , approve: sessionStorage.getItem("approve")
        , approve_note: sessionStorage.getItem("approve_note")
        , substatus: sessionStorage.getItem("substatus")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_approve_quatation";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }



  getPage_Summary_Sales_by_status = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,
        filter_month: sessionStorage.getItem("filter_month"),
        filter_userid: sessionStorage.getItem("filter_userid"),
        filter_team: sessionStorage.getItem("filter_team"),
        filter_jobstatus: sessionStorage.getItem("filter_jobstatus"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid")
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/sales/customeracard_list_by_status";

      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // let xpagecount = parseInt(response.data[0].totalrecord / 20);
          // let ypage = response.data[0].totalrecord % 20;
          // if (ypage > 0) {
          //   xpagecount = xpagecount + 1;
          // }

          let obj = this;

          // AlertError("context   from server->" + JSON.stringify(response.data.db));

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              

 
              item_db: response.data.db,
              item_acard: response.data.acard,
              item_Ahot: response.data.Ahot,
              item_Awarm: response.data.Awarm,
              item_Acold: response.data.Acold,
              item_prebooking: response.data.prebooking,
              item_booking: response.data.booking,
              item_fc: response.data.fc,
              item_matching: response.data.matching,
              item_sale_report: response.data.sale_report,
              item_vdqi: response.data.vdqi,
              item_sold: response.data.sold,
              item_hot_wait: response.data.hot_wait,
              item_warm_wait: response.data.warm_wait,





            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };




  // getAcardReservate = async (code, callBack) => {
  //   try {

  //     let url = BaseApi + "/api/acard/acard_getacardquatation";

  //     let quatation_id = "101210526100544383";
  //     let userid = sessionStorage.getItem("userid");
  //     let companycode = sessionStorage.getItem("companycode");


  //     let page = {

  //       item_no: quatation_id,
  //       userid: userid,
  //       companycode: companycode
  //     };

  //     //   alert("context   ->" + JSON.stringify(page));



  //     await axios
  //       .post(url, { data: page })

  //       .then((response) => {
  //         // console.log(response)

  //         // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


  //         let obj = this;

  //         setTimeout(function () {
  //           obj.setState({
  //             ...obj.state,
  //             status: JSON.parse(response.data.status),
  //             acard_no: JSON.parse(response.data.acard_no),
  //             data: response.data.data,
  //             detail_fee: response.data.detail_fee,
  //             detail_h: response.data.detail_h,
  //             detail_discount: response.data.detail_discount,


  //           });
  //         }, 100);

  //         setTimeout(function () {
  //           callBack(response.data);
  //         }, 100);


  //       });
  //   } catch (error) {

  //     callBack([{ error: error }]);
  //   }
  // };





  updateBooking_Ref_no = async (xdata, callBack) => {

    try {

      let xdata = {


        strref_no: sessionStorage.getItem("strref_no")
        , acard_no: sessionStorage.getItem("acard_no")
        , saleperson: sessionStorage.getItem("userid")
        , companycode: sessionStorage.getItem("companycode")



      }

      let url = BaseApi + "/api/acard/updatebooking_ref_no";

      //  alert("context updateBooking_Ref_no->" + JSON.stringify(xdata));

      await axios.post(url, { data: xdata })

        .then((response) => {
          // console.log(response)

          //  AlertError("context getScorecardbyID from server->" + JSON.stringify(response.data.detail));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }




  NewAcardQR = async (code, callBack) => {
    try {

      let url = BaseApi + "/api/acard/acard_newacardqr";

      let acard_no = sessionStorage.getItem("acard_no");
      let cusdb_id = sessionStorage.getItem("cusdb_id");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        acard_no: acard_no,
        cusdb_id: cusdb_id,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //   AlertError("context getScorecardbyID from server->" + JSON.stringify(response.data.detail));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              status: JSON.parse(response.data.status),
              data: response.data.data,
              detail_fee: response.data.detail_fee,
              detail_h: response.data.detail_h,
              detail_h2: response.data.detail_h2,
              detail_h3: response.data.detail_h3,
              detail_discount: response.data.detail_discount,


            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };



  UpdateModelMargin = async (xdata, callBack) => {

    try {

      let xdata = {

        acard_no: sessionStorage.getItem("acard_no")
        , usedmargin: sessionStorage.getItem("usedmargin")
        , userid: sessionStorage.getItem("userid")
        , companycode: sessionStorage.getItem("companycode")


      }

      let url = BaseApi + "/api/acard/updatemodelmargin";

      // alert("context saveDataNewBooking->" + JSON.stringify(xdata));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }



  getDataReceiptcardetail = async (callBack) => {
    try {


      let strsearch = sessionStorage.getItem("item_no");
      let companycode = sessionStorage.getItem("companycode");
      let userid = sessionStorage.getItem("userid");


      let page = {
        strsearch: strsearch,
        companycode: companycode,
        userid: userid
      };

      //   alert("context   ->" + JSON.stringify(page));

      let url = BaseApi + "/api/acard/acard_getdatareceiptcarbyid";

      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //   AlertError("context getScorecardbyID from server->" + JSON.stringify(response.data.detail));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };





  AcardApproveReceiptcar = async (callBack) => {

    try {


      let xdata = {


        acard_no: sessionStorage.getItem("acard_no")
        , item_no: sessionStorage.getItem("receipt_no")
        , approve: sessionStorage.getItem("approve")
        , approve_note: sessionStorage.getItem("approve_note")
        , substatus: sessionStorage.getItem("substatus")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_approve_receiptcar";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }





  CancelPrebooking = async (callBack) => {

    try {


      let xdata = {


        acard_no: sessionStorage.getItem("acard_no")
        , reservation_no: sessionStorage.getItem("reservation_no")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_cancleprebooking";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }






  getAcardReservate = async (code, callBack) => {
    try {

      let url = BaseApi + "/api/acard/acard_getacardreservate";

      let ref_no1 = sessionStorage.getItem("ref_no1");
      let reference1 = sessionStorage.getItem("reference1");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {
        ref_no1: ref_no1,
        reference1: reference1,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // status: JSON.parse(response.data.status), 
              // doctype: JSON.parse(response.data.doctype), 
              // acard_no: JSON.parse(response.data.acard_no),
              data: response.data.data,
              detail_fee: response.data.detail_fee,
              detail_fee2: response.data.detail_fee2,
              detail_h: response.data.detail_h,
              detail_h2: response.data.detail_h2,
              detail_h3: response.data.detail_h3,
              detail_discount: response.data.detail_discount,
              customer: response.data.customer,
              receiptcar_no: response.data.receiptcar_no,
              epayment: response.data.epayment,
              receiptcar_no: response.data.receiptcar_no,
              reservate: response.data.reservate,
              reservate_info: response.data.reservate_info,
              detail_productlist: response.data.detail_productlist,





            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };




  getReservate_request = async (callBack) => {
    try {

      let url = BaseApi + "/api/acard/acard_listreservate_request";

      let reference1 = sessionStorage.getItem("reference1");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        reference1: reference1,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // alert("context getReservate_request from server->" + JSON.stringify(response.data.servertime));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,

              data: response.data,
              list_licensetype: response.data.list_licensetype,
              list_launchyear: response.data.list_launchyear,
              list_province: response.data.list_province,




            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };






  updateReservteInfo = async (callBack) => {
    try {

      let url = BaseApi + "/api/acard/acard_update_reservate_info";

      let reservation_no = sessionStorage.getItem("reservation_no");
      let acard_no = sessionStorage.getItem("acard_no");
      let licensetype = sessionStorage.getItem("licensetype");
      let reservate_province = sessionStorage.getItem("reservate_province");
      let luanchyear = sessionStorage.getItem("luanchyear");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        reservation_no: reservation_no,
        acard_no: acard_no,
        licensetype: licensetype,
        reservate_province: reservate_province,
        luanchyear: luanchyear,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // alert("context getReservate_request from server->" + JSON.stringify(response.data.servertime));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,

              data: response.data,

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };



  UpdateReservateDuedate = async (callBack) => {
    try {

      let url = BaseApi + "/api/acard/acard_update_reservate_duedate";


      let acard_no = sessionStorage.getItem("acard_no");
      let duedate = sessionStorage.getItem("duedate");
      let duedatetime = sessionStorage.getItem("duedatetime");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        acard_no: acard_no,
        duedate: duedate,
        duedatetime: duedatetime,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // alert("context getReservate_request from server->" + JSON.stringify(response.data.servertime));


          let obj = this;

          // setTimeout(function () {
          //   obj.setState({
          //     ...obj.state,

          //     data: response.data, 

          //   });
          // }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };



  updateAcardDupicateAddress2 = async (callBack) => {

    try {


      let xdata = {


        address_no_2: sessionStorage.getItem("address_no_2")
        , address_road_2: sessionStorage.getItem("address_road_2")
        , address_postcode_2: sessionStorage.getItem("address_postcode_2")
        , address_province_2: sessionStorage.getItem("address_province_2")
        , address_amphur_2: sessionStorage.getItem("address_amphur_2")
        , address_tumbon_2: sessionStorage.getItem("address_tumbon_2")

        , acard_no: sessionStorage.getItem("acard_no")
        , userid: sessionStorage.getItem("userid")

      }



      let url = BaseApi + "/api/acard/acard_update_dupicateaddress2";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  updateAcardDupicateAddress = async (callBack) => {

    try {


      let xdata = {

        address_no: sessionStorage.getItem("address_no")
        , address_road: sessionStorage.getItem("address_road")
        , address_postcode: sessionStorage.getItem("address_postcode")
        , address_province: sessionStorage.getItem("address_province")
        , address_amphur: sessionStorage.getItem("address_amphur")
        , address_tumbon: sessionStorage.getItem("address_tumbon")
        , address_no_3: sessionStorage.getItem("address_no_3")
        , address_road_3: sessionStorage.getItem("address_road_3")
        , address_postcode_3: sessionStorage.getItem("address_postcode_3")
        , address_province_3: sessionStorage.getItem("address_province_3")
        , address_amphur_3: sessionStorage.getItem("address_amphur_3")
        , address_tumbon_3: sessionStorage.getItem("address_tumbon_3")


        , acard_no: sessionStorage.getItem("acard_no")
        , userid: sessionStorage.getItem("userid")

      }



      let url = BaseApi + "/api/acard/acard_update_dupicateaddress3";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  updateCustomerAddress = async (callBack) => {

    try {


      let xdata = {


        // , customername: sessionStorage.getItem("customername")

        // , address_no: sessionStorage.getItem("address_no")
        // , address_road: sessionStorage.getItem("address_road")
        // , address_postcode: sessionStorage.getItem("address_postcode")
        // , address_province: sessionStorage.getItem("address_province")
        // , address_amphur: sessionStorage.getItem("address_amphur")
        // , address_tumbon: sessionStorage.getItem("address_tumbon")

        // , address_no_2: sessionStorage.getItem("address_no_2")
        // , address_road_2: sessionStorage.getItem("address_road_2")
        // , address_postcode_2: sessionStorage.getItem("address_postcode_2")
        // , address_province_2: sessionStorage.getItem("address_province_2")
        // , address_amphur_2: sessionStorage.getItem("address_amphur_2")
        // , address_tumbon_2: sessionStorage.getItem("address_tumbon_2")

        // , address_no_3: sessionStorage.getItem("address_no_3")
        // , address_road_3: sessionStorage.getItem("address_road_3")
        // , address_postcode_3: sessionStorage.getItem("address_postcode_3")
        // , address_province_3: sessionStorage.getItem("address_province_3")
        // , address_amphur_3: sessionStorage.getItem("address_amphur_3")
        // , address_tumbon_3: sessionStorage.getItem("address_tumbon_3")

        // , mobilephone: sessionStorage.getItem("mobilephone")

        // , companycode: sessionStorage.getItem("companycode")
        // ,customerid: sessionStorage.getItem("customerid")

        acard_no: sessionStorage.getItem("acard_no")
        , userid: sessionStorage.getItem("userid")

      }



      let url = BaseApi + "/api/acard/acard_update_customeraddress";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {

          // alert("context     ->" + JSON.stringify(response.data));
          let obj = this;


          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  getInputPartbyFleet = async (callBack) => {
    try {



      let acard_no = sessionStorage.getItem("acard_no");
      let contact_no = sessionStorage.getItem("contact_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        acard_no: acard_no,
        contact_no: contact_no,
        companycode: companycode,
        userid: userid,
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/acard/getpartsetitemsbyfleet";

      await axios
        .post(url, { data: page })

        .then((response) => {

          // alert("context     ->" + JSON.stringify(response.data));
          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,

              itempage_partsetitems_select: response.data,

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };






  getAddressbyID = async (code, callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getaddressbyid";

      let acard_no = sessionStorage.getItem("acard_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");



      let page = {

        acard_no: acard_no,
        userid: userid,
        companycode: companycode
      };


      await axios
        .post(url, { data: page })
        .then((response) => {



          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data
              data: response.data.data,
              customer: response.data.customer,
              districtthai1: response.data.districtthai1,
              districtthai2: response.data.districtthai2,
              districtthai3: response.data.districtthai3,
              tambonthai1: response.data.tambonthai1,
              tambonthai2: response.data.tambonthai2,
              tambonthai3: response.data.tambonthai3,
              postcode1: response.data.postcode1,
              postcode2: response.data.postcode2,
              postcode3: response.data.postcode3,
              customer_check: response.data.customer_check




            });
          }, 500);


          setTimeout(function () {
            callBack(response.data)
          }, 500)

        })


    } catch (error) {
      AlertError(error);
    }
  };



  getAcardbyStatusApprove = async (callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getacardbystatusapprove";

      let acard_no = sessionStorage.getItem("acard_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");



      let page = {

        acard_no: acard_no,
        userid: userid,
        companycode: companycode
      };


      await axios
        .post(url, { data: page })
        .then((response) => {

          // AlertError("context from server->" + JSON.stringify(response.data.reservate));


          // setTimeout(function () {
          //   obj.setState({
          //     ...obj.state,
          //     data: response.data.data,
          //     itemscore: response.data.detail,

          //   });
          // }, 100);

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data
              // data: response.data.data,
              // customer: response.data.customer,

              // reservate: response.data.reservate,
              // receiptcar_no: response.data.receiptcar_no,
              // payment: response.data.payment,
              // statusotp: response.data.statusotp,
              // refno: response.data.refno,
              // statusfk: response.data.statusfk,
              // statusfk_invite: response.data.statusfk_invite,
              // statusbkcancel: response.data.statusbkcancel,

              // campaign: response.data.campaign,
              // status_fk: response.data.status_fk,
              // payment_check: response.data.payment_check,
              // customer_check: response.data.customer_check,
              // itempage_monitor2: response.data.monitor2,
              itempage_approve_list: response.data.approve_list,
              // payment_check_Invite: response.data.payment_check_Invite,
              // approve_booking_cancel: response.data.approve_booking_cancel,


            });
          }, 100);


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error);
    }
  };





  getAcardDatafee = async (callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getacarddatafee";

      let acard_no = sessionStorage.getItem("acard_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");



      let page = {

        acard_no: acard_no,
        userid: userid,
        companycode: companycode
      };


      await axios
        .post(url, { data: page })
        .then((response) => {

          // alert("context from server->" + JSON.stringify(response.data));


          // setTimeout(function () {
          //   obj.setState({
          //     ...obj.state,
          //     data: response.data.data,
          //     itemscore: response.data.detail,

          //   });
          // }, 100);

          // let obj = this;

          //   setTimeout(function () {  
          //      obj.setState({
          //       ...obj.state,

          //     // data: response.data.data,



          //   });
          // }, 100);


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error);
    }
  };



  getAcardDatapayment = async (callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getacarddatapayment";

      let acard_no = sessionStorage.getItem("acard_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");



      let page = {

        acard_no: acard_no,
        userid: userid,
        companycode: companycode
      };


      await axios
        .post(url, { data: page })
        .then((response) => {

          // alert("context from server->" + JSON.stringify(response.data));


          // setTimeout(function () {
          //   obj.setState({
          //     ...obj.state,
          //     data: response.data.data,
          //     itemscore: response.data.detail,

          //   });
          // }, 100);

          // let obj = this;

          //   setTimeout(function () {  
          //      obj.setState({
          //       ...obj.state,

          //     // data: response.data.data,



          //   });
          // }, 100);


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error);
    }
  };


  getAcardDataAddress = async (callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getacarddataaddress";

      let acard_no = sessionStorage.getItem("acard_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");



      let page = {

        acard_no: acard_no,
        userid: userid,
        companycode: companycode
      };


      await axios
        .post(url, { data: page })
        .then((response) => {

          // alert("context from server->" + JSON.stringify(response.data));


          // setTimeout(function () {
          //   obj.setState({
          //     ...obj.state,
          //     data: response.data.data,
          //     itemscore: response.data.detail,

          //   });
          // }, 100);

          // let obj = this;

          //   setTimeout(function () {  
          //      obj.setState({
          //       ...obj.state,

          //     // data: response.data.data,



          //   });
          // }, 100);


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error);
    }
  };


  getAcardDataModel = async (callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getacarddatamodel";

      let acard_no = sessionStorage.getItem("acard_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");



      let page = {

        acard_no: acard_no,
        userid: userid,
        companycode: companycode
      };


      await axios
        .post(url, { data: page })
        .then((response) => {

          // alert("context from server->" + JSON.stringify(response.data));


          // setTimeout(function () {
          //   obj.setState({
          //     ...obj.state,
          //     data: response.data.data,
          //     itemscore: response.data.detail,

          //   });
          // }, 100);

          // let obj = this;

          //   setTimeout(function () {  
          //      obj.setState({
          //       ...obj.state,

          //     // data: response.data.data,



          //   });
          // }, 100);


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error);
    }
  };


  getAcardDataInfo = async (callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getacarddatainfo";

      let acard_no = sessionStorage.getItem("acard_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");



      let page = {

        acard_no: acard_no,
        userid: userid,
        companycode: companycode
      };


      await axios
        .post(url, { data: page })
        .then((response) => {


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error);
    }
  };




  updateAcardAddress = async (callBack) => {

    try {


      let xdata = {

        acard_no: sessionStorage.getItem("acard_no")
        , address_no: sessionStorage.getItem("address_no")
        , address_road: sessionStorage.getItem("address_road")
        , address_postcode: sessionStorage.getItem("address_postcode")
        , address_province: sessionStorage.getItem("address_province")
        , address_amphur: sessionStorage.getItem("address_amphur")
        , address_tumbon: sessionStorage.getItem("address_tumbon")

        , address_no_2: sessionStorage.getItem("address_no_2")
        , address_road_2: sessionStorage.getItem("address_road_2")
        , address_postcode_2: sessionStorage.getItem("address_postcode_2")
        , address_province_2: sessionStorage.getItem("address_province_2")
        , address_amphur_2: sessionStorage.getItem("address_amphur_2")
        , address_tumbon_2: sessionStorage.getItem("address_tumbon_2")

        , address_no_3: sessionStorage.getItem("address_no_3")
        , address_road_3: sessionStorage.getItem("address_road_3")
        , address_postcode_3: sessionStorage.getItem("address_postcode_3")
        , address_province_3: sessionStorage.getItem("address_province_3")
        , address_amphur_3: sessionStorage.getItem("address_amphur_3")
        , address_tumbon_3: sessionStorage.getItem("address_tumbon_3")


        , customername: sessionStorage.getItem("customername")
        , telephone: sessionStorage.getItem("telephone")
        , mobilephone: sessionStorage.getItem("mobilephone")
        , citizenid: sessionStorage.getItem("citizenid")
        , sex: sessionStorage.getItem("sex")
        , dob: sessionStorage.getItem("dob")
        , userid: sessionStorage.getItem("userid")

      }



      let url = BaseApi + "/api/acard/acard_update_address";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  updateAcardInfo = async (callBack) => {

    try {


      let xdata = {

        acard_no: sessionStorage.getItem("acard_no")
        , sourceofcus: sessionStorage.getItem("sourceofcus")
        , media: sessionStorage.getItem("media")
        , cusgroup: sessionStorage.getItem("cusgroup")
        , custype: sessionStorage.getItem("custype")
        , salejobtype: sessionStorage.getItem("salejobtype")
        , contact_no: sessionStorage.getItem("contact_no")
        , userid: sessionStorage.getItem("userid")

      }



      let url = BaseApi + "/api/acard/acard_update_acardinfo";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }



  getAcardDataMonitor2 = async (callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getacardbystatusmonitor2";

      let page = {

        acard_no: sessionStorage.getItem("acard_no")
        , userid: sessionStorage.getItem("userid")

      };


      await axios
        .post(url, { data: page })
        .then((response) => {

          // AlertError("context from server->" + JSON.stringify(response.data.reservate));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,

              itempage_monitor2: response.data.monitor2,

            });
          }, 100);


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error);
    }
  };


  AcardGetInvitePersonid = async (callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getinvitedetail";


      let page = {

        citizenid: sessionStorage.getItem("citizenid"),
        person_id: sessionStorage.getItem("person_id"),
        userid: sessionStorage.getItem("userid"),

      };


      await axios
        .post(url, { data: page })
        .then((response) => {



          // let obj = this;
          // setTimeout(function () {
          //   obj.setState({
          //     ...obj.state,




          //     status: response.status,

          //   });
          // }, 100);


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error);
    }
  };





  UpdatePersonInvite = async (callBack) => {

    try {


      let xdata = {



        person_id: sessionStorage.getItem("person_id")
        , person_group1: sessionStorage.getItem("person_group1")
        , person_group2: sessionStorage.getItem("person_group2")
        , person_group3: sessionStorage.getItem("person_group3")
        , title: sessionStorage.getItem("title")
        , firstname: sessionStorage.getItem("firstname")
        , lastname: sessionStorage.getItem("lastname")
        , gender: sessionStorage.getItem("gender")
        , mobilephone: sessionStorage.getItem("mobilephone")
        , citizenid: sessionStorage.getItem("citizenid")
        , address_no: sessionStorage.getItem("address_no")
        , address_road: sessionStorage.getItem("address_road")
        , address_postcode: sessionStorage.getItem("address_postcode")
        , address_province: sessionStorage.getItem("address_province")
        , address_amphur: sessionStorage.getItem("address_amphur")
        , address_tumbon: sessionStorage.getItem("address_tumbon")
        , details1: sessionStorage.getItem("details1")
        , details2: sessionStorage.getItem("details2")
        , details3: sessionStorage.getItem("details3")
        , details4: sessionStorage.getItem("details4")
        , details5: sessionStorage.getItem("details5")
        , person_status: sessionStorage.getItem("person_status")
        , acard_no: sessionStorage.getItem("acard_no")
        , userid: sessionStorage.getItem("userid")


      }



      let url = BaseApi + "/api/acard/acard_update_personinvite";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }





  AcardGetInviteChkagen = async (callBack) => {
    try {

      let url = BaseApi + "/api/sales/acard_getinvitechkagent";


      let page = {

        strthai_id: sessionStorage.getItem("citizenid")

      };


      await axios
        .post(url, { data: page })
        .then((response) => {


          // alert("context from server status >>->" + JSON.stringify(response.status));
          // alert("context from server DAta >->" + JSON.stringify(response.data.data));
          // alert("context from server Name >>->" + JSON.stringify(response.data.data.name));




          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error);
    }
  };




  AcardEditPartInvite = async (callBack) => {

    try {


      let xdata = {
  
          productid: sessionStorage.getItem("productid")
        , item_name: sessionStorage.getItem("stritemname")
        , item_no: sessionStorage.getItem("item_no")
        , unitprice: sessionStorage.getItem("unitprice")
        , quantity: sessionStorage.getItem("quantity")
        , amount: sessionStorage.getItem("amount")
        , unitcost: sessionStorage.getItem("unitcost")

        , status: sessionStorage.getItem("chk_agen_status")
        , message: sessionStorage.getItem("chk_agen_message")
        , gender: sessionStorage.getItem("chk_agen_gender")
        , province: sessionStorage.getItem("chk_agen_province")
        , livein: sessionStorage.getItem("chk_agen_livein")
        , data_status: sessionStorage.getItem("chk_agen_data_status")
        , regis_date: sessionStorage.getItem("chk_agen_regis_date")
        , campaign: sessionStorage.getItem("chk_agen_campaign")
        , end_date: sessionStorage.getItem("chk_agen_end_date")
        , docs_img: sessionStorage.getItem("chk_agen_docs_img")
        , sale_owner: sessionStorage.getItem("chk_agen_sale_owner") 
        , citizenid: sessionStorage.getItem("citizenid")  
        , person_id : sessionStorage.getItem("person_id")
        , acard_no: sessionStorage.getItem("acard_no")
        , companycode: sessionStorage.getItem("companycode")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_edit_partinvite";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {

 

          setTimeout(function () {
            callBack(res.data)
          }, 100)
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  
  

  Updatecitizenid = async (callBack) => {

    try {


      let xdata = {
  
          acard_no: sessionStorage.getItem("acard_no")
        , citizenid: sessionStorage.getItem("citizenid")  
        , citizenid_new: sessionStorage.getItem("citizenid_new")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/acard/acard_update_citizenid";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {

 

          setTimeout(function () {
            callBack(res.data)
          }, 100)
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  render() {
    return (
      <Score_Card_Context.Provider
        value={{
          ...this.state,
          handleChange: this.handleChange,
          setData: this.setData,
          saveData: this.saveData,
          getData: this.getData,
          getRunning: this.getRunning,
          deleteData: this.deleteData,
          getPage: this.getPage,
          firstPage: this.firstPage,
          previousPage: this.previousPage,
          nextPage: this.nextPage,
          lastPage: this.lastPage,
          updateData: this.updateData,
          getCustomerAcardbyID: this.getCustomerAcardbyID,
          getScorecardbyID: this.getScorecardbyID,
          getRunningContact: this.getRunningContact,
          getAcardContactList: this.getAcardContactList,
          AcardSaveContact: this.AcardSaveContact,
          getAcardContactDetailByacardno: this.getAcardContactDetailByacardno,
          getDataContactdetail: this.getDataContactdetail,
          getPartsetItemsByCarmodel: this.getPartsetItemsByCarmodel,
          AcardSavePartsetItems: this.AcardSavePartsetItems,
          getPartsetItemsBySelect: this.getPartsetItemsBySelect,
          updateAcardInfo: this.updateAcardInfo,
          updateAcardAddress: this.updateAcardAddress,
          updateAcardModelcar: this.updateAcardModelcar,
          updateAcardFee: this.updateAcardFee,
          getPageApprove: this.getPageApprove,
          AcardApproveContact: this.AcardApproveContact,
          DBrequestupdateContact: this.DBrequestupdateContact,
          getFilterSubstatus: this.getFilterSubstatus,
          getColourListbycartype: this.getColourListbycartype,
          updateAcardPayment: this.updateAcardPayment,
          getInsurance: this.getInsurance,
          getListcartype: this.getListcartype,
          getFinancename: this.getFinancename,
          firstPageApprove: this.firstPageApprove,
          previousPageApprove: this.previousPageApprove,
          nextPageApprove: this.nextPageApprove,
          AcardPaymentPromptpay: this.AcardPaymentPromptpay,
          saveDataNewBooking: this.saveDataNewBooking,
          getAll: this.getAll,
          AcardEditPartsetItems: this.AcardEditPartsetItems,
          AcardDeletePartsetItems: this.AcardDeletePartsetItems,
          AcardcallMargin: this.AcardcallMargin,
          AcardSaveDiscount: this.AcardSaveDiscount,
          getAcardQuatation: this.getAcardQuatation,
          getQuatationList: this.getQuatationList,
          NewAcardQuatation: this.NewAcardQuatation,
          AcardApproveQuatation: this.AcardApproveQuatation,
          getPage_Summary_Sales_by_status: this.getPage_Summary_Sales_by_status,
          updateBooking_Ref_no: this.updateBooking_Ref_no,
          NewAcardQR: this.NewAcardQR,
          UpdateModelMargin: this.UpdateModelMargin,
          getDataReceiptcardetail: this.getDataReceiptcardetail,
          AcardApproveReceiptcar: this.AcardApproveReceiptcar,
          CancelPrebooking: this.CancelPrebooking,
          getAcardReservate: this.getAcardReservate,
          getReservate_request: this.getReservate_request,
          updateReservteInfo: this.updateReservteInfo,
          UpdateReservateDuedate: this.UpdateReservateDuedate,
          updateAcardDupicateAddress2: this.updateAcardDupicateAddress2,
          updateAcardDupicateAddress: this.updateAcardDupicateAddress,
          updateCustomerAddress: this.updateCustomerAddress,
          getInputPartbyFleet: this.getInputPartbyFleet,
          getAddressbyID: this.getAddressbyID,
          getAcardbyStatusApprove: this.getAcardbyStatusApprove,
          getAcardDatafee: this.getAcardDatafee,
          getAcardDatapayment: this.getAcardDatapayment,
          getAcardDataAddress: this.getAcardDataAddress,
          getAcardDataModel: this.getAcardDataModel,
          getAcardDataInfo: this.getAcardDataInfo,
          getAcardDataMonitor2: this.getAcardDataMonitor2,
          AcardGetInvitePersonid: this.AcardGetInvitePersonid,
          UpdatePersonInvite: this.UpdatePersonInvite,
          AcardGetInviteChkagen: this.AcardGetInviteChkagen,
          AcardEditPartInvite: this.AcardEditPartInvite,
          AcardGetMargin:  this.AcardGetMargin,
          Updatecitizenid: this.Updatecitizenid,



        }}
      >
        {this.props.children}
      </Score_Card_Context.Provider>

    );
  }
}
const Score_Card_Consumer = Score_Card_Context.Consumer;

export { Score_Card_Provider, Score_Card_Consumer, Score_Card_Context };

export function withScore_Card_Consumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <Score_Card_Consumer>
        {value => <Component {...props} context={value} />}
      </Score_Card_Consumer>
    );
  };
}

