import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

    AlertError,
} from '../../components/Utility/MyString';

const DB_V2_Context = React.createContext();


const ServiceApi = BaseApi + "/api/sales/db_save";

export default class DB_v2_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                group1_id: '',
                group1_name3: '',
                group1_name1: '',
                item_no: '',
                item_id: '',
                item_name1: '',
                item_name2: '',
                item_name3: '',
                input_caption: '',
                cusdb_id: '',
                jobdate: '',
                jobtime: '',
                saleperson: '',
                saleteam: '',
                sourceofcus: '',
                customername: '',
                address_no: '',
                address_road: '',
                address_tumbon: '',
                address_amphur: '',
                address_province: '',
                address_postcode: '',
                telephone: '',
                mobilephone: '',
                ext_lineid: '',
                ext_fbid: '',
                ext_email: '',
                ext_car_brand: '',
                ext_car_model: '',
                ext_car_year: '',
                note: '',
                jobstatus: '',
                inputtype: '',
                persontype: '',
                personname_prx: '',
                personname_main: '',
                personname_sfx: '',
                details1: '',
                details2: '',
                details3: '',
                details4: '',
                details5: '',
                ischecked: 0,
                product: '',
                visible: '',
                chk_ahot: '',
                chk_booking: '',
                chk_scorecard: '',
                score: '',
                companycode: ''
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage_G01: [
                {
                    group1_id: '',
                    group1_name3: '',
                    group2_id: '',
                    item_no: '',
                    customername: '',
                    G01_01_01: '',
                    G01_01_02: '',
                    G01_01_03: '',
                    G01_02_01: '',
                    G01_02_02: '',
                    G01_03_01: '',
                    G01_04_01: '',
                    G01_05_01: '',
                    G01_07_01: '',
                    G01_08_01: '',
                    G01_09_01: '',
                    G01_10_01: '',
                    totalrecord: 0
                }],

            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            strfromdate: '',
            address_postcode: '',
            ext_car_brand: '',
            strenddate: '',
            companycode: '',
            totalrecord: 0,
            count_all: 0,
            count_db: 0,
            count_acard: 0,
            count_booking: 0,
            pagecount: 0,
            ischecked: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {

            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }

    saveDataNewDB_v2 = async (xdata, callBack) => {

        try {



            let xdata = {


                G01_01_01: sessionStorage.getItem("G01_01_01"),
                G01_01_02: sessionStorage.getItem("G01_01_02"),
                G01_01_03: sessionStorage.getItem("G01_01_03"),
                G01_02_01: sessionStorage.getItem("G01_02_01"),
                G01_02_02: sessionStorage.getItem("G01_02_02"),
                G01_03_01: sessionStorage.getItem("G01_03_01"),
                G01_04_01: sessionStorage.getItem("G01_04_01"),
                G01_05_01: sessionStorage.getItem("G01_05_01"),
                G01_06_01: sessionStorage.getItem("G01_06_01"),
                G01_07_01: sessionStorage.getItem("G01_07_01"),
                G01_08_01: sessionStorage.getItem("G01_08_01"),
                G01_09_01: sessionStorage.getItem("G01_09_01"),
                G01_10_01: sessionStorage.getItem("G01_10_01"),
                inputjobs: sessionStorage.getItem("inputjobs"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")
            }



            let url = BaseApi + "/api/sales/db_savedatanewdb_v2";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    this.setState({
                        ...this.state,
                        data: res.data
                    });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }



    CheckCustomernameDuplicate_v2 = async (callBack) => {
        try {

            let data = {
                title: sessionStorage.getItem("G01_01_01"),
                firstname: sessionStorage.getItem("G01_01_02"),
                lastname: sessionStorage.getItem("G01_01_03"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")

            }



            await axios.post(BaseApi + "/api/sales/db_checkcustomername", { data: data })
                .then((response) => {



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };



    getDb_By_CusID_V2 = async (code, callBack) => {
        try {

            //  alert("getprovince");

            let page = {
                cusdb_id: sessionStorage.getItem("cusdb_id"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")
            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getdb_by_cusid_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_G01: response.data.G01,
                            // itempage_G02: response.data.G02,
                            // itempage_G03: response.data.G03,
                            // itempage_G04: response.data.G04,
                            // itempage_G05: response.data.G05,
                            // itempage_G06: response.data.G06,
                            // itempage_G07: response.data.G07,
                            // itempage_G08: response.data.G08,
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };




    getDBG01_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {
                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")
            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getdb_g01_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };




    saveDataDBG01_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {

                G01_01_01: sessionStorage.getItem('G01_01_01'),
                G01_01_02: sessionStorage.getItem('G01_01_02'),
                G01_01_03: sessionStorage.getItem('G01_01_03'),
                G01_02_01: sessionStorage.getItem('G01_02_01'),
                G01_02_02: sessionStorage.getItem('G01_02_02'),
                G01_04_01: sessionStorage.getItem('G01_04_01'),
                G01_05_01: sessionStorage.getItem('G01_05_01'),
                G01_06_01: sessionStorage.getItem('G01_06_01'),
                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")
            }


            // alert("context saveDataDBG01_V2 from context->" + JSON.stringify(page));

            await axios.post(BaseApi + "/api/sales/getsavedb_g01_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };




    getDBG07_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {
                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")
            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getdb_g07_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };


    saveDataDBG07_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {

                G07_01_01: sessionStorage.getItem('G07_01_01'),
                G07_02_01: sessionStorage.getItem('G07_02_01'),
                G07_03_01: sessionStorage.getItem('G07_03_01'),
                G07_04_01: sessionStorage.getItem('G07_04_01'),
                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")
            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getsavedb_g07_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };



    saveDataDBG07_05_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {

                G07_05_01: sessionStorage.getItem('G07_05_01'),
                G07_05_02: sessionStorage.getItem('G07_05_02'),
                G07_05_03: sessionStorage.getItem('G07_05_03'),
                G07_05_04: sessionStorage.getItem('G07_05_04'),
                G07_05_05: sessionStorage.getItem('G07_05_05'),
                G07_05_06: sessionStorage.getItem('G07_05_06'),
                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")





            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getsavedb_g07_05_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };
 
    
    saveDataDBG07_07_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {

                G07_07_01: sessionStorage.getItem('G07_07_01'), 
                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")





            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getsavedb_g07_07_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };
 
    
    sentToAcard_V2 = async (xdata, callBack) => {

        try {  

            let xdata = {
                cusdb_id: sessionStorage.getItem("cusdb_id"),
                agent_code: sessionStorage.getItem("agent_code"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")
            }
 

            let url = BaseApi + "/api/sales/db_senttoacard_v2";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    getPage = async (callBack) => {
        try {


            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: sessionStorage.getItem("strsearch"),
                filter_month: sessionStorage.getItem("filter_month"),
                filter_userid: sessionStorage.getItem("filter_userid"),
                filter_team: sessionStorage.getItem("filter_team"),
                filter_job: sessionStorage.getItem("filter_job"),
                filter_status: sessionStorage.getItem("filter_status"),
                filter_sortby: sessionStorage.getItem("filter_sortby"),
                filter_sorttype: sessionStorage.getItem("filter_sorttype"),
                filter_substatus: sessionStorage.getItem("filter_substatus"),
                companycode: sessionStorage.getItem("companycode"),
                userid: sessionStorage.getItem("userid")
            }

            let url = BaseApi + "/api/sales/db_page_new_v2";

            await axios.post(url, { data: page })

                .then((response) => {

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            count_all: response.data[0].count_all,
                            count_db: response.data[0].count_db,
                            count_acard: response.data[0].count_acard,
                            count_acard_hot: response.data[0].count_acard_hot,
                            count_acard_warm: response.data[0].count_acard_warm,
                            count_acard_cold: response.data[0].count_acard_cold,
                            count_booking: response.data[0].count_booking,
                            count_acard_prebooking: response.data[0].count_acard_prebooking,
                            count_acard_fc: response.data[0].count_acard_fc,
                            count_acard_matching: response.data[0].count_acard_matching,
                            count_acard_salereport: response.data[0].count_acard_salereport,
                            count_acard_vdqi: response.data[0].count_acard_vdqi,
                            count_acard_sold: response.data[0].count_acard_sold,
                            count_acard_reject: response.data[0].count_acard_reject,
                            count_acard_wait: response.data[0].count_acard_wait,
                            count_acard_warm_reject: response.data[0].count_acard_warm_reject,
                            count_acard_warm_wait: response.data[0].count_acard_warm_wait,
                            count_acard_cancel: response.data[0].count_acard_cancel,
                            pagecount: xpagecount
                        });
                    }, 100)

                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)

                })

        } catch (error) {
            // AlertError("error :->" + error.message);
            AlertError("error->" + error.code + ":" + error.message);
            callBack([{ error: error.message }])
        }
    };


    
    saveDataDBG02_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {

                G02_01_01: sessionStorage.getItem('G02_01_01'),
                G02_02_01: sessionStorage.getItem('G02_02_01'),
                G02_03_01: sessionStorage.getItem('G02_03_01'),
                G02_04_01: sessionStorage.getItem('G02_04_01'),
                G02_04_02: sessionStorage.getItem('G02_04_02'),
                G02_05_01: sessionStorage.getItem('G02_05_01'),

                G02_06_01: sessionStorage.getItem('G02_06_01'),
                G02_07_01: sessionStorage.getItem('G02_07_01'),
                G02_01_01_id: sessionStorage.getItem('G02_01_01_id'),
                G02_02_01_id: sessionStorage.getItem('G02_02_01_id'),
                G02_03_01_id: sessionStorage.getItem('G02_03_01_id'),
                G02_07_01_id: sessionStorage.getItem('G02_07_01_id'),

                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")


                


            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getsavedb_g02_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };


    
    getDBG03_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {
                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")
            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getdb_g03_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };

 
    saveDataDBG03_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {

                G03_01_01: sessionStorage.getItem('G03_01_01'),
                G03_02_01: sessionStorage.getItem('G03_02_01'),
                G03_03_01: sessionStorage.getItem('G03_03_01'),
                G03_04_01: sessionStorage.getItem('G03_04_01'),
                G03_05_01: sessionStorage.getItem('G03_05_01'),
                G03_06_01: sessionStorage.getItem('G03_06_01'),

                G03_06_02: sessionStorage.getItem('G03_06_02'),
                G03_07_01: sessionStorage.getItem('G03_07_01'),
                G03_02_01_id: sessionStorage.getItem('G03_02_01_id'),
                G03_07_01_id: sessionStorage.getItem('G03_07_01_id'),
      

                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")

                
            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getsavedb_g03_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };
 
    
    getDb_ItemMutiSelect_V2 = async (callBack) => {
        try {


            let page = {
                cusdb_id: sessionStorage.getItem("cusdb_id")
                , item_id: sessionStorage.getItem("item_id")
                , companycode: sessionStorage.getItem("companycode")
                , userid: sessionStorage.getItem("userid")

            }


            await axios.post(BaseApi + "/api/sales/getdb_itemmutiselect_v2", { data: page })
                .then((response) => {

                     

                    this.setState({
                        ...this.state,
                        userAuthorize: response.data,
                    });

                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)


                   


                })




        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

 
    saveDataDBG04_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {

                G04_01_01: sessionStorage.getItem('G04_01_01'),
                G04_02_01: sessionStorage.getItem('G04_02_01'),
                G04_02_02: sessionStorage.getItem('G04_02_02'),
                G04_02_03: sessionStorage.getItem('G04_02_03'),
                G04_02_04: sessionStorage.getItem('G04_02_04'),
                G04_02_05: sessionStorage.getItem('G04_02_05'),

                G04_02_06: sessionStorage.getItem('G04_02_06'),
                G04_03_01: sessionStorage.getItem('G04_03_01'),
                G04_04_01: sessionStorage.getItem('G04_04_01'),
                G04_05_01: sessionStorage.getItem('G04_05_01'),
                G04_06_01: sessionStorage.getItem('G04_06_01'),
                G04_07_01: sessionStorage.getItem('G04_07_01'),
                G04_08_01: sessionStorage.getItem('G04_08_01'),
                G04_03_01_id: sessionStorage.getItem('G04_03_01_id'), 
                G04_04_01_id: sessionStorage.getItem('G04_04_01_id'),
                G04_05_01_id: sessionStorage.getItem('G04_05_01_id'), 
 
                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")

                
            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getsavedb_g04_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };
 

    
    
    getDBG08_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {
                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")
            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getdb_g08_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this



                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };



    saveDataDBG08_V2 = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {

                G08_01_02: sessionStorage.getItem('G08_01_02'),
                G08_01_03: sessionStorage.getItem('G08_01_03'),
                G08_01_04: sessionStorage.getItem('G08_01_04'),
                G08_08_01: sessionStorage.getItem('G08_08_01'),
                G08_09_01: sessionStorage.getItem('G08_09_01'), 
                G08_01_02_id: sessionStorage.getItem('G08_01_02_id'), 
                G08_01_03_id: sessionStorage.getItem('G08_01_03_id'),
                G08_01_04_id: sessionStorage.getItem('G08_01_04_id'),
                G08_08_01_id: sessionStorage.getItem('G08_08_01_id'),
                G08_09_01_id: sessionStorage.getItem('G08_09_01_id'), 

                cusdb_id: sessionStorage.getItem("cusdb_id"),
                userid: sessionStorage.getItem("userid")

                
            }

            //    alert("context getDb_Gup1 from context->" + JSON.stringify(page)); 

            await axios.post(BaseApi + "/api/sales/getsavedb_g08_v2", { data: page })
                .then((response) => {

                    //  alert("context getDb_Gup1 from server->" + JSON.stringify(response.data[1]));
                    let obj = this 

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })

        } catch (error) {


            AlertError("error->" + error.code + ":" + error.message);
            //callBack()
        }
    };

    render() {
        return (
            <DB_V2_Context.Provider
                value={{
                    ...this.state,

                    saveDataNewDB_v2: this.saveDataNewDB_v2,
                    CheckCustomernameDuplicate_v2: this.CheckCustomernameDuplicate_v2,
                    getDb_By_CusID_V2: this.getDb_By_CusID_V2,
                    getDBG01_V2: this.getDBG01_V2,
                    saveDataDBG01_V2: this.saveDataDBG01_V2,
                    getDBG07_V2: this.getDBG07_V2,
                    saveDataDBG07_V2: this.saveDataDBG07_V2,
                    saveDataDBG07_05_V2: this.saveDataDBG07_05_V2,
                    saveDataDBG07_07_V2: this.saveDataDBG07_07_V2,
                    sentToAcard_V2: this.sentToAcard_V2,
                    getPage: this.getPage,
                    saveDataDBG02_V2: this.saveDataDBG02_V2,
                    getDBG03_V2: this.getDBG03_V2,
                    saveDataDBG03_V2: this.saveDataDBG03_V2,
                    getDb_ItemMutiSelect_V2: this.getDb_ItemMutiSelect_V2,
                    saveDataDBG04_V2: this.saveDataDBG04_V2,
                    getDBG08_V2: this.getDBG08_V2,
                    saveDataDBG08_V2: this.saveDataDBG08_V2,


                }}
            >
                {this.props.children}
            </DB_V2_Context.Provider>

        );
    }
}
const DB_v2_Consumer = DB_V2_Context.Consumer;

export { DB_v2_Provider, DB_v2_Consumer, DB_V2_Context };

export function withDB_v2__Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <DB_v2_Consumer>
                {value => <Component {...props} context={value} />}
            </DB_v2_Consumer>
        );
    };
}

