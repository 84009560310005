import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

  AlertError,
} from '../../components/Utility/MyString';

const Reservate_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/sales/acard_save";



export default class Reservate_Provider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        item_no: '',
        acard_no: '',
        sourceofcus: '',
        media: '',
        cusgroup: '',
        custype: '',
        colour: '',
        salejobtype: '',
        acard_contace: '',
        customername: '',
        mobilephone: '',
        userid: '',
        companycode: ''
      },
      userAuthorize: [
        {
          userid: '',
          menucode: '',
          menutext: '',
          useuse: 0,
          isinsert: 0,
          isupdate: 0,
          isdelete: 0
        }
      ],
      itempage: [
        {
          item_no: '',
          acard_no: '',
          companycode: '',
          totalrecord: 1
        }],
      // itemscore: [
      //   {
      //     item_id: '',
      //     group2_id: '',
      //     item_name1: '',
      //     item_detail: '',
      //     itm_item_id: '',
      //     score: '',
      //     totalrecord: 1
      //   }],

      // itempage_contactlist: [
      //   {
      //     menucode: '',
      //     menutext: ''

      //   }],
      // itempage_detaillist: [
      //   {
      //     item_no: '',
      //     contactdate: '',
      //     contacttime: '',
      //     channel: '',
      //     details: '',
      //     actions: '',
      //     status: '',
      //     followup: '',
      //     followupdate: '',
      //     acard_no: '',
      //     customername: '',

      //   }],
      itempage_discount: [
        {
          productid: '',
          partsetname: '',
          category: '',
          partsetno: '',
          item_name: '',
          quantity: '',
          unitprice: '',
          amount: '',
          vat: '',
          totalamount: '',

        }],


      

      itempage_receiptcar_no: [
        {
          item_no:'',
          receipt_date: '',
          itemname:'',
          receipt_no:'',
          amount: '',

        }],
      

        itempage_monitor2: [
        {
          act_date:'',
          item_no:'',
          details: '',
          username: '',

        }],
      // itempage_qrlist: [
      //   {
      //     quatationdate: '',
      //     item_no: '',
      //     itemname: '',
      //     amount: '',

      //   }],
      // itempage_qulist: [
      //   {
      //     quatationdate: '',
      //     item_no: '',
      //     itemname: '',
      //     amount: '',

      //   }],



      // itempage_category: [
      //   {

      //     itemcategory: '',


      //   }],

      // item_db: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',


      //   }],

      // item_Ahot: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',
      //   }],

      // item_Awarm: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',
      //   }],

      // item_Acold: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',


      //   }],
      // item_prebooking: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',


      //   }],

      // item_booking: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',
      //   }],

      // item_fc: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',
      //   }],

      // item_matching: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',
      //   }],

      // item_sale_report: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',
      //   }],

      // item_vdqi: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',
      //   }],

      // item_sold: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',
      //   }],
      // item_hot_wait: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',
      //   }],
      // item_warm_wait: [
      //   {
      //     itemname: '',
      //     customername: '',
      //     job_name1: '',
      //   }],



      pageindex: 1,
      pagesize: 20,
      strsearch: '',
      strfromdate: '',
      strenddate: '',
      companycode: '',
      totalrecord: 0,
      pagecount: 0,
      isLogin: false
    }
  }


  setData = async (values) => {
    this.setState({
      ...this.state,
      data: values
    });

    console.log("state->" + JSON.stringify(this.state))

  };


  firstPage = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  previousPage = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  nextPage = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  lastPage = async (callBack) => {
    this.setState({
      pageindex: this.state.pagecount
    });

    let obj = this

    setTimeout(function () {
      //alert("Last Page ...." + obj.state.pageindex)
      obj.getPage(callBack);
    }, 200)

  }

  
  

  getPage = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,
        filter_month: sessionStorage.getItem("filter_month"),
        filter_userid: sessionStorage.getItem("filter_userid"),
        filter_team: sessionStorage.getItem("filter_team"),
        filter_jobstatus: sessionStorage.getItem("filter_jobstatus"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid")
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/reservate/reservate_page";

      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          let xpagecount = parseInt(response.data[0].totalrecord / 20);
          let ypage = response.data[0].totalrecord % 20;
          if (ypage > 0) {
            xpagecount = xpagecount + 1;
          }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              itempage: response.data,
              totalrecord: response.data[0].totalrecord,
              count_all: response.data[0].count_all,
              count_acard: response.data[0].count_acard,
              count_acard_hot: response.data[0].count_acard_hot,
              count_acard_warm: response.data[0].count_acard_warm,
              count_acard_cold: response.data[0].count_acard_cold,
              count_booking: response.data[0].count_booking,
              count_acard_prebooking: response.data[0].count_acard_prebooking,
              count_acard_fc: response.data[0].count_acard_fc,
              count_acard_matching: response.data[0].count_acard_matching,
              count_acard_salereport: response.data[0].count_acard_salereport,
              count_acard_vdqi: response.data[0].count_acard_vdqi,
              count_acard_sold: response.data[0].count_acard_sold,
              count_acard_reject: response.data[0].count_acard_reject,
              count_acard_wait: response.data[0].count_acard_wait,
              count_acard_warm_reject: response.data[0].count_acard_warm_reject,
              count_acard_warm_wait: response.data[0].count_acard_warm_wait,

              pagecount: xpagecount,
            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };


  

  getReservatebyID = async (code, callBack) => {
    try {

      let url = BaseApi + "/api/reservate/reservate_getdata";

      let reservation_no = sessionStorage.getItem("reservation_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        reservation_no: reservation_no,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // status: JSON.parse(response.data.status), 
              // doctype: JSON.parse(response.data.doctype), 
              // acard_no: JSON.parse(response.data.acard_no),
              data: response.data.data,
              // detail_fee: response.data.detail_fee,
              // detail_h: response.data.detail_h,
              // detail_h2: response.data.detail_h2,
              // detail_h3: response.data.detail_h3,
              itempage_monitor2: response.data.monitor2,
              customer: response.data.customer,
              // receiptcar_no: response.data.receiptcar_no,
              // epayment: response.data.epayment,
              itempage_receiptcar_no: response.data.receiptcar_no,
              reservate: response.data.reservate,
              campaign: response.data.campaign,
              itempage_discount: response.data.acard_discount,

              
          

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };

  

  

  GetdataReceipt_no = async (code, callBack) => {
    try {

      let url = BaseApi + "/api/reservate/receipt_no_getdata";

      let reservation_no = sessionStorage.getItem("reservation_no");
      let receipt_no = sessionStorage.getItem("receipt_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        reservation_no: reservation_no,
        receipt_no: receipt_no,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
            
              data: response.data.data,
              

            });
          }, 100);

          // setTimeout(function () {
          //   callBack(response.data);
          // }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };

  

  

  CancelReceipt_no = async ( callBack) => {
    try {

      let url = BaseApi + "/api/reservate/receipt_no_cancel";

      let reservation_no = sessionStorage.getItem("reservation_no");
      let receipt_no = sessionStorage.getItem("receipt_no");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        reservation_no: reservation_no,
        receipt_no: receipt_no,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));
 
      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
            
              data: response.data.data,
              

            });
          }, 100);

          // setTimeout(function () {
          //   callBack(response.data);
          // }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };

  

  CancelFNConfirm = async ( callBack) => {
    try {

      let url = BaseApi + "/api/reservate/fn_confirm_cancel";

   


      let page = {

        reservation_no: sessionStorage.getItem("reservation_no")  
        , acard_no: sessionStorage.getItem("acard_no") 
        , confirm2: sessionStorage.getItem("confirm2")
        , ref_date2: sessionStorage.getItem("ref_date2")
        , remark2: sessionStorage.getItem("remark2")  
        , userid: sessionStorage.getItem("userid") 
      };

      //   alert("context   ->" + JSON.stringify(page));
 
      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
            
              data: response.data.data,
              

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };

  

  CancelMatching = async ( callBack) => {
    try {

      let url = BaseApi + "/api/reservate/matching_cancel";

      let reservation_no = sessionStorage.getItem("reservation_no");
      let strnote = sessionStorage.getItem("strnote");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        reservation_no: reservation_no,
        strnote: strnote,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));
 
      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
            
              data: response.data.data,
              

            });
          }, 100);

          // setTimeout(function () {
          //   callBack(response.data);
          // }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };

  

  CancelSale = async ( callBack) => {
    try {

      let url = BaseApi + "/api/reservate/sale_cancel";

      let reservation_no = sessionStorage.getItem("reservation_no");
      let strnote = sessionStorage.getItem("strnote");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        reservation_no: reservation_no,
        strnote: strnote,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));
 
      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
            
              data: response.data.data,
              

            });
          }, 100);

          // setTimeout(function () {
          //   callBack(response.data);
          // }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };

  

  CancelVDQI = async ( callBack) => {
    try {

      let url = BaseApi + "/api/reservate/vdqi_cancel";

      let reservation_no = sessionStorage.getItem("reservation_no");
      let strnote = sessionStorage.getItem("strnote");
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        reservation_no: reservation_no,
        strnote: strnote,
        userid: userid,
        companycode: companycode
      };

      //   alert("context   ->" + JSON.stringify(page));
 
      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
            
              data: response.data.data,
              

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };



  ConfirmFN = async ( callBack) => {
    try {

      let url = BaseApi + "/api/reservate/fn_confirm";

       

      let page = {

         reservation_no: sessionStorage.getItem("reservation_no")  
        , acard_no: sessionStorage.getItem("acard_no")
        , ref_no2: sessionStorage.getItem("invite_ref_no2")
        , confirm2: sessionStorage.getItem("confirm2")
        , ref_date2: sessionStorage.getItem("ref_date2")
        , remark2: sessionStorage.getItem("remark2") 
        , edittype : sessionStorage.getItem("edittype") 
        , userid: sessionStorage.getItem("userid") 
      
      };

        // alert("context   ->" + JSON.stringify(page));

    
 
      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //  AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
            
              data: response.data.data,
              

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };

  

  
  RejectFN = async ( callBack) => {
    try {

      let url = BaseApi + "/api/reservate/reject_fn_confirm";

      

      let page = {
         reservation_no: sessionStorage.getItem("reservation_no")  
        , acard_no: sessionStorage.getItem("acard_no") 
        , confirm2: sessionStorage.getItem("confirm2")
        , ref_date2: sessionStorage.getItem("ref_date2")
        , remark2: sessionStorage.getItem("remark2")  
        , userid: sessionStorage.getItem("userid") 
      };

      //   alert("context   ->" + JSON.stringify(page));
 
      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
            
              data: response.data.data,
              

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };


  


  updateReservteInfo = async ( callBack) => {
    try {

      let url = BaseApi + "/api/reservate/fn_update_info";

       

      let page = {

         reservation_no: sessionStorage.getItem("reservation_no")  
        , acard_no: sessionStorage.getItem("acard_no")
        , financereq_date: sessionStorage.getItem("financereq_date") 
        , finance_person: sessionStorage.getItem("finance_person")
        , finance_sign_date: sessionStorage.getItem("finance_sign_date") 
        , finance_province : sessionStorage.getItem("finance_province") 
        , finance_confirmdate : sessionStorage.getItem("finance_confirmdate")
        , remark : sessionStorage.getItem("remark")
        , ssmi : sessionStorage.getItem("ssmi")
        , register_status : sessionStorage.getItem("register_status")  
        , matching_status : sessionStorage.getItem("matching_status") 
        , reservate_status : sessionStorage.getItem("reservate_status") 
        , car_shipdate : sessionStorage.getItem("car_shipdate")  
        , booking_vip_status : sessionStorage.getItem("booking_vip_status")  
        , userid: sessionStorage.getItem("userid") 
       
      };

        // alert("context   ->" + JSON.stringify(page));

    
 
      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //  AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
            
              data: response.data.data,
              

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };

   

  getDataFinanceInfo = async ( callBack) => {
    try {

      let url = BaseApi + "/api/reservate/fn_getdata_info";

       

      let page = {

         reservation_no: sessionStorage.getItem("reservation_no")  
        , acard_no: sessionStorage.getItem("acard_no") 
        , userid: sessionStorage.getItem("userid") 
       
      };

        // alert("context   ->" + JSON.stringify(page));

    
 
      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          //  AlertError("context getAcardQuatation from server->" + JSON.parse(response.data.status));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
            
              data: response.data.data,
              

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };


  render() {
    return (
      <Reservate_Context.Provider
        value={{
          ...this.state,
          handleChange: this.handleChange,
          setData: this.setData,
          saveData: this.saveData,
          getData: this.getData,
          getRunning: this.getRunning,
          deleteData: this.deleteData,
          getPage: this.getPage,
          firstPage: this.firstPage,
          previousPage: this.previousPage,
          nextPage: this.nextPage,
          lastPage: this.lastPage,
          getReservatebyID: this.getReservatebyID,
          GetdataReceipt_no: this.GetdataReceipt_no,
          CancelReceipt_no: this.CancelReceipt_no,
          CancelFNConfirm: this.CancelFNConfirm,
          CancelMatching: this.CancelMatching,
          CancelSale: this.CancelSale,
          CancelVDQI: this.CancelVDQI,
          ConfirmFN: this.ConfirmFN,
          RejectFN: this.RejectFN,
          updateReservteInfo: this.updateReservteInfo,
          getDataFinanceInfo : this.getDataFinanceInfo,

         

        }}
      >
        {this.props.children}
      </Reservate_Context.Provider>

    );
  }
}
const Reservate_Consumer = Reservate_Context.Consumer;

export { Reservate_Provider, Reservate_Consumer, Reservate_Context };

export function withReservate_Consumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <Reservate_Consumer>
        {value => <Component {...props} context={value} />}
      </Reservate_Consumer>
    );
  };
}

