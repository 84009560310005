import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

    AlertError,
} from '../../components/Utility/MyString';

const Acard_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/sales/acard_save";



export default class Acard_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                item_no: '',
                acard_no: '',
                jobdate: '',
                jobtime: '',
                saleperson: '',
                saleteam: '',
                sourceofcus: '',
                customername: '',
                address_no: '',
                address_road: '',
                address_tumbon: '',
                address_amphur: '',
                address_province: '',
                address_postcode: '',
                telephone: '',
                mobilephone: '',
                ext_lineid: '',
                ext_fbid: '',
                ext_email: '',
                ext_car_brand: '',
                ext_car_model: '',
                ext_car_year: '',
                note: '',
                jobstatus: '',
                inputtype: '',
                persontype: '',
                personname_prx: '',
                personname_main: '',
                personname_sfx: '',
                details1: '',
                details2: '',
                details3: '',
                details4: '',
                details5: '',
                companycode: ''
            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    item_no: '',
                    acard_no: '',
                    jobdate: '',
                    jobtime: '',
                    saleperson: '',
                    saleteam: '',
                    sourceofcus: '',
                    customername: '',
                    address_no: '',
                    address_road: '',
                    address_tumbon: '',
                    address_amphur: '',
                    address_province: '',
                    address_postcode: '',
                    telephone: '',
                    mobilephone: '',
                    ext_lineid: '',
                    ext_fbid: '',
                    ext_email: '',
                    ext_car_brand: '',
                    ext_car_model: '',
                    ext_car_year: '',
                    note: '',
                    jobstatus: '',
                    inputtype: '',
                    persontype: '',
                    personname_prx: '',
                    personname_main: '',
                    personname_sfx: '',
                    details1: '',
                    details2: '',
                    details3: '',
                    details4: '',
                    details5: '',
                    companycode: '',
                    totalrecord: 1
                }],
            itempage_receiptcar: [
                {
                    item_no: '',
                    itemname: '',
                    unitprice: '',
                    companycode: '',
                    totalrecord: 1
                }],
            itempage_receiptcarlist: [
                {
                    receipt_no: '',
                    recdiptdate: '',
                    grandtotal: '',
                    companycode: '',
                    totalrecord: 1
                }],

            itempage_fk_list: [
                {
                    imagename: '',

                }],

            itempage_getcampaign: [
                {
                    item_code: '',
                    itemname: '',

                }], 

            itempage_fk_DOC009: [
                {
                    docno: '',

                }],

                itempage_approve_list: [
                    {
                      process_id: '',
                      createdate_th: '',
                      request_details1: '',
                      approve_status: '',
                      approve_status2: '',
                      qty: ''
            
            
                    }],


            pageindex: 1,
            pagesize: 10,
            strsearch: '',
            strfromdate: '',
            strenddate: '',
            address_postcode: '',
            ext_car_brand: '',

            companycode: '',
            totalrecord: 0,
            pagecount: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }

    getPage = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: this.state.strsearch,
                strfromdate: this.state.strfromdate,
                strenddate: this.state.strenddate,
                companycode: this.state.companycode,
                userid: 'sa'
            }

            //alert("inbox context get page")

            let url = BaseApi + "/api/sales/acard_page";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                    //  alert("context acard  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data,
                            totalrecord: response.data[0].totalrecord,
                            pagecount: xpagecount
                        });
                    }, 500)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)


                })


        } catch (error) {
            AlertError("error->" + error.code + ":" + error.message);

            // AlertError(error.code + ":" + error.message   );
            callBack("")
        }
    };




    getData = async (code, callBack) => {
        try {

            //  alert(code);
            //alert("service api ->" + ServiceApi)




            await axios.get(BaseApi + "/api/sales/acard_getbyid", { params: { code: code } })
                .then((response) => {

                    // AlertError("context getData  from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError("error->" + error.code + ":" + error.message);

            callBack()
        }
    };



    saveData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            //    alert("acard context save -> " + xdata)

            await axios.post(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    updateData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            await axios.put(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    deleteData = async (xdata, callBack) => {

        try {

            //alert("delete from context -> " + ServiceApi )

            await axios.delete(ServiceApi, {
                data: {
                    code: xdata.code
                }
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            //message.success('Delete Data Complete..', 5);
                            callBack(true)
                        }, 500)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    //select changed
    handleChange = (str1, str2, callBack) => {
        this.setState({
            //...this.state,
            strfromdate: str1,
            strenddate: str2

        });
        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getSummary(callBack)
        }, 200)

    }




    getRunning = async (code, callBack) => {
        try {

            //alert("product context getpage -> " + this.state.strsearch);

            let code = "101"

            let page = {
                code: code

            }

            //alert("inbox context get page")
            let url = BaseApi + "/api/sales/acard_genid";


            // alert("context getpage ->" + JSON.stringify(page));

            await axios.post(url, { data: page })
                .then((response) => {

                    //   alert("context get running ->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: {
                            item_no: response.data[0].item_no,
                            acard_no: response.data[0].acard_no,
                            jobdate: response.data[0].jobdate,
                            jobtime: response.data[0].jobtime,

                        },
                        dataitem: [],
                        datadealer: []
                    })

                    let xobj = this
                    setTimeout(function () {
                        callBack(xobj.state.data)
                    }, 100)

                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    getAcardInfo = async (callBack) => {
        try {



            await axios.post(BaseApi + "/api/sales/acard_info", { params: { userid: "" } })
                .then((response) => {

                    // alert("context event get getAcardlist from server->" +  response.data.code);



                    // sessionStorage.setItem("geteventnext",  response.data.code)

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data

                        });
                    }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getAcardInfo_des = async (callBack) => {
        try {

            let acard_info_id = sessionStorage.getItem("acard_info_id")

            let page = {
                code: acard_info_id

            }



            await axios.post(BaseApi + "/api/sales/acard_info_des", { data: page })
                .then((response) => {

                    // alert("context event get getAcardlist from server->" +  response.data.code);

                    // sessionStorage.setItem("geteventnext",  response.data.code)

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data


                        });
                    }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getprovince = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {
                code: ""

            }



            await axios.post(BaseApi + "/api/sales/getprovince", { data: page })
                .then((response) => {

                    //    AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getDistrict = async (callBack) => {
        try {

            let ProvinceThai = sessionStorage.getItem("ProvinceThai")

            let page = {
                code: ProvinceThai

            }

            await axios.post(BaseApi + "/api/sales/getdistrict", { data: page })
                .then((response) => {

                    // alert("context event get getAcardlist from server->" + JSON.stringify(response.data));

                    // sessionStorage.setItem("geteventnext",  response.data.code)

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data


                        });
                    }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getTambon = async (callBack) => {
        try {

            let DistrictThai = sessionStorage.getItem("DistrictThai")

            let page = {
                code: DistrictThai

            }

            await axios.post(BaseApi + "/api/sales/gettambon", { data: page })
                .then((response) => {


                    // sessionStorage.setItem("geteventnext",  response.data.code)

                    let obj = this

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage: response.data

                        });
                    }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getPostcode = async (callBack) => {
        try {

            let tumbonid = sessionStorage.getItem("TambonID")
            let districtthai = sessionStorage.getItem("DistrictThai")

            let page = {
                code: tumbonid
                , districtthai: districtthai

            }

            await axios.post(BaseApi + "/api/sales/getpostcode", { data: page })
                .then((response) => {

                    this.setState({
                        ...this.state,
                        data: response.data,
                    });
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };

    getbrand = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {
                code: ""
            }


            await axios.post(BaseApi + "/api/sales/getbrand", { data: page })
                .then((response) => {

                    // AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };


    getmodelname = async (callBack) => {
        try {

            let brand = sessionStorage.getItem("brand")

            let page = {
                code: brand

            }


            await axios.post(BaseApi + "/api/sales/getmodelname", { data: page })
                .then((response) => {

                    // AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
                    /*
                    this.setState({
                        ...this.state,               
                        itempage : response.data,              
                    });
                    */
                    setTimeout(function () {
                        callBack(response.data)
                    }, 500)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };




    getCustomerID = async (code, callBack) => {
        try {

            let url = BaseApi + "/api/acard/getcustomeridfrom_acard";

            let acard_no = sessionStorage.getItem("acard_no");

            await axios.get(url, { params: { code: acard_no } })
                .then((response) => {

                    //AlertError("context from server->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: response.data
                    });

                    setTimeout(function () {
                        callBack(response.data)
                    }, 100)

                })


        } catch (error) {
            AlertError(error);
        }
    };




    SaveReceiptcar = async (xdata, callBack) => {

        try {

            let xdata = {

                receiptcarno: sessionStorage.getItem("receiptcarno")
                , customerid: sessionStorage.getItem("customerid")
                , ref_no: sessionStorage.getItem("ref_no")
                , receiptdate: sessionStorage.getItem("receiptdate")
                , acard_no: sessionStorage.getItem("acard_no")
                , fee: sessionStorage.getItem("fee")
                , slipno: sessionStorage.getItem("slipno")
                , userid: sessionStorage.getItem("userid")
                , companycode: sessionStorage.getItem("companycode")


            }

            let url = BaseApi + "/api/acard/acard_savereceiptcar";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    this.setState({
                        ...this.state,
                        data: res.data
                    });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }




    getDataReceiptcar = async (code, callBack) => {
        try {

            let url = BaseApi + "/api/acard/acard_getdatareceiptcar";

            let receipt_no = sessionStorage.getItem("receipt_no");
            let acard_no = sessionStorage.getItem("acard_no");

            let userid = sessionStorage.getItem("userid");
            let companycode = sessionStorage.getItem("companycode");


            let page = {

                receipt_no: receipt_no,
                acard_no: acard_no

            };

            //   alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    //    AlertError("context getDataReceiptcar from server->" + response.data.details) ;


                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            data: response.data.data,
                            itempage_receiptcar: response.data.details,
                            customer: response.data.customer,

                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error: error }]);
        }
    };



    getPageReceiptcar = async (code, callBack) => {
        try {

            let url = BaseApi + "/api/acard/acard_getpagereceiptcar";

            let acard_no = sessionStorage.getItem("acard_no");
            let userid = sessionStorage.getItem("userid");
            let companycode = sessionStorage.getItem("companycode");


            let page = {

                acard_no: acard_no

            };

            //   alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    //    AlertError("context getDataReceiptcar from server->" + response.data.details) ;


                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_receiptcarlist: response.data.data,
                            //   itempage_receiptcar:  response.data.details ,


                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error: error }]);
        }
    };




    Cancel_booking_request = async (callBack) => {

        try {

            let xdata = {


                acard_no: sessionStorage.getItem("acard_no")
                , approve_note: sessionStorage.getItem("approve_note")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/acard/acard_cancel_booking_request";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    this.setState({
                        ...this.state,
                        data: res.data
                    });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    getDataCancelAdvancepaydetail = async (callBack) => {
        try {

            let url = BaseApi + "/api/acard/acard_getdatacanceladvancepay";

            let strsearch = sessionStorage.getItem("item_no");
            let userid = sessionStorage.getItem("userid");



            let page = {

                strsearch: strsearch,
                userid: userid

            };

            //   alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    // AlertError("context getDataCancelAdvancepaydetail from server->" + response.data.fk_list) ;


                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_fk_list: response.data.fk_list,
                            itempage_fk_DOC009: response.data.fk_DOC009,
                            itempage_approve_list: response.data.approve_list

                            //   itempage_receiptcar:  response.data.details ,


                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error: error }]);
        }
    };




    AcardApproveCancelBooking = async (callBack) => {

        try {


            let xdata = {


                acard_no: sessionStorage.getItem("acard_no")
                , item_no: sessionStorage.getItem("item_no")
                , approve: sessionStorage.getItem("approve")
                , approve_note: sessionStorage.getItem("approve_note")
                , substatus: sessionStorage.getItem("substatus")
                , userid: sessionStorage.getItem("userid")



            }



            let url = BaseApi + "/api/acard/acard_approve_cancel_booking";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    Approve_booking_request = async (callBack) => {

        try {

            let xdata = {


                acard_no: sessionStorage.getItem("acard_no")
                , confirm_customer_bk: sessionStorage.getItem("confirm_customer_bk")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/acard/acard_approve_booking_request";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    this.setState({
                        ...this.state,
                        data: res.data
                    });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }



    getDataApproveBookingVIP = async (callBack) => {
        try {

            let url = BaseApi + "/api/acard/acard_getdatabookingvip";

            let strsearch = sessionStorage.getItem("item_no");
            let userid = sessionStorage.getItem("userid");



            let page = {

                strsearch: strsearch,
                userid: userid

            };

            //   alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    // AlertError("context getDataCancelAdvancepaydetail from server->" + response.data.fk_list) ;


                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                          ...obj.state,
                        
                          itempage_approve_list: response.data.approve_list
                           
            
                        });
                      }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error: error }]);
        }
    };






    AcardApproveBookingVip = async (callBack) => {

        try {


            let page = {


                acard_no: sessionStorage.getItem("acard_no")
                , item_no: sessionStorage.getItem("item_no")
                , approve: sessionStorage.getItem("approve")
                , approve_note: sessionStorage.getItem("approve_note")
                , substatus: sessionStorage.getItem("substatus")
                , userid: sessionStorage.getItem("userid")



            }



            let url = BaseApi + "/api/acard/acard_approve_booking_vip";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    // AlertError("context getDataCancelAdvancepaydetail from server->" + response.data.fk_list) ;


                    let obj = this;

                    // setTimeout(function () {
                    //     obj.setState({
                    //         ...obj.state,
                    //         itempage_fk_list: response.data.fk_list,
                    //         //   itempage_receiptcar:  response.data.details ,


                    //     });
                    // }, 100);

                    setTimeout(function () {
                        callBack(response.data.data);
                    }, 100);


                });

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }




    Invite_request = async (callBack) => {

        try {

            let xdata = {


                acard_no: sessionStorage.getItem("acard_no")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/acard/acard_invite_request";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    this.setState({
                        ...this.state,
                        data: res.data
                    });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    getDataInvitedetail = async (callBack) => {
        try {

            let url = BaseApi + "/api/acard/acard_getdatainvite";

            let strsearch = sessionStorage.getItem("item_no");
            let userid = sessionStorage.getItem("userid");



            let page = {

                strsearch: strsearch,
                userid: userid

            };

            //   alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    // AlertError("context getDataInvitedetail from server->" + response.data.fk_list) ;


                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_fk_list: response.data.fk_list,
                            itempage_approve_list: response.data.approve_list
                        


                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error: error }]);
        }
    };


    AcardApproveInvite = async (callBack) => {

        try {


            let xdata = {


                acard_no: sessionStorage.getItem("acard_no")
                , item_no: sessionStorage.getItem("item_no")
                , approve: sessionStorage.getItem("approve")
                , approve_note: sessionStorage.getItem("approve_note")
                , substatus: sessionStorage.getItem("substatus")
                , userid: sessionStorage.getItem("userid")



            }



            let url = BaseApi + "/api/acard/acard_approve_invite";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }



    getCampaign = async (callBack) => {
        try {

            //  alert("getprovince");

            let page = {

                userid: sessionStorage.getItem("userid")

            }



            await axios.post(BaseApi + "/api/acard/getcampaign", { data: page })
                .then((response) => {

                    //    AlertError("context from server getprovince getall->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        itempage_getcampaign: response.data,
                    });

                    // setTimeout(function () {
                    //     callBack(response.data)
                    // }, 100)

                })


        } catch (error) {
            AlertError(error.message);
            //callBack()
        }
    };






    SaveAcardCampaign = async (callBack) => {

        try {

            let xdata = {


                campaign_code: sessionStorage.getItem("campaign_code")
                , campaign_amount1: sessionStorage.getItem("campaign_amount1")
                , campaign_amount2: sessionStorage.getItem("campaign_amount2")
                , acard_no: sessionStorage.getItem("acard_no")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/acard/acard_save_campaign";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    this.setState({
                        ...this.state,
                        data: res.data
                    });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    QM1Request = async (callBack) => {

        try {

            let xdata = {


                acard_no: sessionStorage.getItem("acard_no")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/acard/acard_approve_qm1_request";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }



    getDataQM1detail = async (callBack) => {
        try {

            let url = BaseApi + "/api/acard/acard_getdataqm1";

            let strsearch = sessionStorage.getItem("item_no");
            let userid = sessionStorage.getItem("userid");



            let page = {

                strsearch: strsearch,
                userid: userid

            };

            //   alert("context   ->" + JSON.stringify(page));



            await axios
                .post(url, { data: page })

                .then((response) => {
                    // console.log(response)

                    // alert("context getDataQM1detail from server->" + JSON.stringify(response)) ;


                    let obj = this;

                    setTimeout(function () {
                        obj.setState({
                            ...obj.state,
                            itempage_fk_list: response.data.fk_list,
                            itempage_approve_list:  response.data.approve_list
                            //   itempage_receiptcar:  response.data.details ,


                        });
                    }, 100);

                    setTimeout(function () {
                        callBack(response.data);
                    }, 100);


                });
        } catch (error) {

            callBack([{ error: error }]);
        }
    };

    AcardApproveQM1 = async (callBack) => {

        try {


            let xdata = {


                acard_no: sessionStorage.getItem("acard_no")
                , item_no: sessionStorage.getItem("item_no")
                , ref_no: sessionStorage.getItem("item_no_qm1")
                , approve: sessionStorage.getItem("approve")
                , approve_note: sessionStorage.getItem("approve_note")
                , substatus: sessionStorage.getItem("substatus")
                , userid: sessionStorage.getItem("userid")



            }



            let url = BaseApi + "/api/acard/acard_approve_qm1";

            //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    PreFinanceConfirm_request = async (callBack) => {

        try {

            let xdata = {


                acard_no: sessionStorage.getItem("acard_no")
                , userid: sessionStorage.getItem("userid")



            }

            let url = BaseApi + "/api/acard/acard_approve_prefinanceconfirm_request";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    QM1EDITRequest = async (callBack) => {

        try {

            let xdata = {

                acard_no: sessionStorage.getItem("acard_no")
                , qm1edittype: sessionStorage.getItem("qm1edittype")
                , userid: sessionStorage.getItem("userid")

            }

            let url = BaseApi + "/api/acard/acard_approve_qm1edit_request";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }




    CheckStatusReservation_no = async (callBack) => {

        try {

            let xdata = {

                 reservation_no: sessionStorage.getItem("strreservation_no")
                ,acard_no: sessionStorage.getItem("acard_no")
                , userid: sessionStorage.getItem("userid")

            }

            let url = BaseApi + "/api/acard/acard_check_reservation_no";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    // this.setState({
                    //     ...this.state,
                    //     data: res.data
                    // });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

 
    TransferReservate = async (callBack) => {

        try {

            let xdata = {

                reservation_no: sessionStorage.getItem("strreservation_no")
                , acard_no: sessionStorage.getItem("acard_no")
                , confirm_customer_bk: sessionStorage.getItem("confirm_customer_bk")
                , userid: sessionStorage.getItem("userid")
                , companycode: sessionStorage.getItem("companycode")

            }

            let url = BaseApi + "/api/acard/acard_transfer_reservation";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    this.setState({
                        ...this.state,
                        data: res.data
                    });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    Booking_Fleet = async (callBack) => {

        try {

            let xdata = {


                acard_no: sessionStorage.getItem("acard_no")
                , contact_no: sessionStorage.getItem("contact_no")
                , amountfleet: sessionStorage.getItem("amountfleet")
                , userid: sessionStorage.getItem("userid")
                , companycode: sessionStorage.getItem("companycode")

            }

            let url = BaseApi + "/api/acard/acard_booking_fleet";

            // alert("context SaveReceiptcar->" + JSON.stringify(xdata));

            await axios.post(url, { data: xdata })

                .then((res) => {
                    this.setState({
                        ...this.state,
                        data: res.data
                    });

                    //  AlertError("context from server->" + JSON.stringify(res.data));

                    setTimeout(function () {
                        callBack(res.data)
                    }, 100)

                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
 
 

    render() {
        return (
            <Acard_Context.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData,
                    saveData: this.saveData,
                    getData: this.getData,
                    getAcardInfo: this.getAcardInfo,
                    getAcardInfo_des: this.getAcardInfo_des,
                    getRunning: this.getRunning,
                    deleteData: this.deleteData,
                    getPage: this.getPage,
                    getprovince: this.getprovince,
                    getDistrict: this.getDistrict,
                    getTambon: this.getTambon,
                    getPostcode: this.getPostcode,
                    getbrand: this.getbrand,
                    getmodelname: this.getmodelname,
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,
                    updateData: this.updateData,
                    getCustomerID: this.getCustomerID,
                    SaveReceiptcar: this.SaveReceiptcar,
                    getDataReceiptcar: this.getDataReceiptcar,
                    getPageReceiptcar: this.getPageReceiptcar,
                    Cancel_booking_request: this.Cancel_booking_request,
                    getDataCancelAdvancepaydetail: this.getDataCancelAdvancepaydetail,
                    AcardApproveCancelBooking: this.AcardApproveCancelBooking,
                    Approve_booking_request: this.Approve_booking_request,
                    getDataApproveBookingVIP: this.getDataApproveBookingVIP,
                    AcardApproveBookingVip: this.AcardApproveBookingVip,
                    Invite_request: this.Invite_request,
                    getDataInvitedetail: this.getDataInvitedetail,
                    AcardApproveInvite: this.AcardApproveInvite,
                    getCampaign: this.getCampaign,
                    SaveAcardCampaign: this.SaveAcardCampaign,
                    getQM1: this.getQM1,
                    QM1Request: this.QM1Request,
                    getDataQM1detail: this.getDataQM1detail,
                    AcardApproveQM1: this.AcardApproveQM1,
                    PreFinanceConfirm_request: this.PreFinanceConfirm_request,
                    QM1EDITRequest: this.QM1EDITRequest,
                    CheckStatusReservation_no: this.CheckStatusReservation_no,
                    TransferReservate: this.TransferReservate,
                    Booking_Fleet: this.Booking_Fleet,
                     




                }}
            >
                {this.props.children}
            </Acard_Context.Provider>

        );
    }
}
const Acard_Consumer = Acard_Context.Consumer;

export { Acard_Provider, Acard_Consumer, Acard_Context };

export function withAcard_Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <Acard_Consumer>
                {value => <Component {...props} context={value} />}
            </Acard_Consumer>
        );
    };
}

