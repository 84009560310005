import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Typography } from 'antd'

const { Text } = Typography

const TextConfirmSave = 'ต้องการบันทึกข้อมูลใช่หรือไม่ ?'
const TextConfirmUpdate = 'ต้องการแก้ไขมูลใช่หรือไม่ ?'
const TextConfirmDelete = 'ต้องการลบข้อมูลใช่หรือไม่ ?'

const TextCompleteSave = 'บันทึกข้อมูลเรียบร้อยแล้ว !!'
const TextCompleteUpdate = 'แก้ไขมูลเรียบร้อยแล้ว !!'
const TextCompleteDelete = 'ลบข้อมูลเรียบร้อยแล้ว !!'

const TextTitleConfirm = 'Confirm'

const confirm = Modal.confirm


const AlertCompleSave = async () => {
  Modal.success({
    title: 'Confirm',
    content: TextCompleteSave,
  });
  /*   
  confirm({
      title: 'Result',
      content: xxcomplete,
      onOk() {
          //callbackConfirm(true)
      },
      onCancel() {
          //callbackConfirm(false)
      },
    })       
    */
}



const AlertCompleUpdate = async () => {
  Modal.success({
    title: 'Result',
    content: TextCompleteUpdate,
  });
}

const AlertCompleDelete = async () => {
  Modal.success({
    title: 'Result',
    content: TextCompleteDelete,
  });
}

const AlertError = async (message) => {
  Modal.error({
    title: 'Error',
    content: message,
  });
}

const AlertNormal = async (title, message) => {
  Modal.info({
    title: title,
    content: (
      <div>
        <p>{message}</p>
        <p>...</p>
      </div>
    ),
    onOk() { },
  });
}

const AlertInfo = async (message) => {
  //success
  Modal.success({
    title: "",
    content: (
      <div>
        <p>{message}</p>
        {/* <p>...</p> */}
      </div>
    ),
    onOk() {

    },
  });
}

const AlertOTPError = async (message) => {
  //success
  Modal.error({
    title: "ทำรายการOTPไม่สำเร็จ อาจเกิดจากสาเหตุดังนี้",
    content: (
      <div>
        <p>1.รหัสOTPหมดอายุ (OTPจะมีอายุการใช้งาน60นาที่ หลังจากเรากดส่งให้ลูกค้า)</p>
        <p>2.รหัสOTPไม่ถูกต้อง(กรณีส่งOTPมากกว่า1ครั้ง ต้องใส่รหัสตัวล่าสุดเท่านั้น)</p>
        <p>3.หมายเลขโทรศัพท์ หรือระบบของผู้ให้บริการ OTP ขัดข้อง</p>

      </div>
    ),
    onOk() {

    },
  });
}

const AlertInfo2 = async (title, message, callbackConfirm) => {
  //success
  Modal.success({
    title: title,
    content: (
      <div>
        <p>{message}</p>

      </div>
    ),
    onOk() {
      callbackConfirm(true)
    },
  });
}

const ShowConfirmSave = async (message, callbackConfirm) => {
  confirm({
    title: TextTitleConfirm,
    content: message + ' ' + TextConfirmSave,
    onOk() {
      callbackConfirm(true)
    },
    onCancel() {
      callbackConfirm(false)
    },
  })
}


const ShowConfirm = async (message, callbackConfirm) => {
  confirm({
    title: TextTitleConfirm,
    content: message,
    onOk() {
      callbackConfirm(true)
    },
    onCancel() {
      callbackConfirm(false)
    },
  })
}



const ShowConfirmUpdate = async (callbackConfirm) => {
  confirm({
    title: TextTitleConfirm,
    content: TextConfirmUpdate,
    onOk() {
      callbackConfirm(true)
    },
    onCancel() {
      callbackConfirm(false)
    },
  })
}

const ShowConfirmDelete = async (data, callbackConfirm) => {
  confirm({
    title: TextTitleConfirm,
    content: TextConfirmDelete,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      callbackConfirm(data, true)
    },
    onCancel() {
      callbackConfirm(data, false)
    },
  });
}

const ShowConfirmSave2 = async (message, callbackConfirm) => {
  confirm({
    title: TextTitleConfirm,
    content: (
      <div>
        <p>{message}</p>
        <p>
          {TextConfirmSave}
        </p>
      </div>



    ),
    okText: 'Yes',
    onOk() {
      callbackConfirm(true)
    },
    onCancel() {
      callbackConfirm(false)
    },
  })
}


const AlertWarning = async (title, message) => {
  Modal.warning({
    title: title,
    zIndex: "9999999",
    content: (
      <div>
        <p>{message}</p>
      </div>
    ),
    onOk() { },
  });
}


const ShowConfirmRequest = async (message, callbackConfirm) => {
  confirm({
    title: "ยืนยันตัวตนไปยังหมายเลข",
    content: (
      <div>
        {/* <p >{message}</p>  */}
        <h3>
          <font color="red">{message}</font>
        </h3>

        <p>
          กรุณาตรวจสอบความถูกต้อง
        </p>
      </div>


    ),
    okText: 'Yes',
    onOk() {
      callbackConfirm(true)
    },
    onCancel() {
      callbackConfirm(false)
    },
  })
}



const ShowConfirmCancel = async (title, message1, message2, message3, message4, message5, callbackConfirm) => {
  confirm({
    title: title,
    content: (
      <div>
        {/* <p >{message}</p>  */}
        <h6>
          <font color="red">{message1}</font>
        </h6>
        <h6>
          <font color="red">{message2}</font>
        </h6>
        <h6>
          <font color="red">{message3}</font>
        </h6>
        <h6>
          <font color="red">{message4}</font>
        </h6>
        <h6>
          <font color="red">{message5}</font>
        </h6>



      </div>


    ),
    okText: 'Yes',
    onOk() {
      callbackConfirm(true)
    },
    onCancel() {
      callbackConfirm(false)
    },
  })
}

const ShowConfirmApprove = async (message, message2, callbackConfirm) => {
  Modal.confirm({
    title: "ยืนยันนการ",
    content: (
      <div>
        
          <h4><font color="blue">{message}</font>  <font color="blue">{message2}</font> </h4>
       
        
        <p>
          {TextConfirmSave}
        </p>
      </div>



    ),
    okText: 'Yes',
    onOk() {
      callbackConfirm(true)
    },
    onCancel() {
      callbackConfirm(false)
    },
  })
}


const ShowConfirmReject = async (message, message2,callbackConfirm) => {
  Modal.confirm({
    title: "ยืนยันนการ",
    content: (
      <div>
          <h4> <font color="red">{message}  </font>   <font color="blue">{message2}</font>     </h4>
        
        
        
        <p>
          {TextConfirmSave}
        </p>
      </div>



    ),
    okText: 'Yes',
    onOk() {
      callbackConfirm(true)
    },
    onCancel() {
      callbackConfirm(false)
    },
  })
}

//myString = useRef  ,  myString.current.getAlert();
const MyAlert = forwardRef((props, ref) => {

  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  useImperativeHandle(ref, () => ({

    getAlert() {
      alert("getAlert from Child");
    }
    ,
    AlertCompleteSave() {
      setTitle('Result')
      setContent(TextCompleteSave)
      ShowModal();
    },
    AlertCompleteUpdate() {
      setTitle('Result')
      setContent(TextCompleteUpdate)
      ShowModal();
    },
    AlertCompleteDelete() {
      setTitle('Result')
      setContent(TextCompleteDelete)
      ShowModal();
    },
    AlertError(message) {
      setTitle('Error')
      setContent(message)
      ShowModal();
    }

  }));

  const ShowModal = () => {
    setVisible(true)
  };

  const hideModal = () => {
    setVisible(false)
    //props.callbackConfirm()
  };

  const hideModal2 = () => {
    setVisible(false)
    //callbackConfirm
  };








  return (
    <div>

      <Modal
        title={title}
        visible={visible}
        onOk={hideModal}
        onCancel={hideModal2}
        okText="OK"
        cancelText="CANCEL"
      >
        <h4>
          <Text mark>
            {content}
          </Text>
        </h4>

      </Modal>
    </div>
  )
});


export {
  AlertCompleSave,  //function 
  AlertCompleUpdate, //function 
  AlertCompleDelete, //function 
  AlertError, //function 
  AlertWarning, //function
  AlertNormal, //function 
  AlertInfo,//function
  AlertInfo2,
  AlertOTPError,
  ShowConfirm,
  ShowConfirmSave, //function 
  ShowConfirmSave2,
  ShowConfirmUpdate, //function 
  ShowConfirmDelete, //function 
  ShowConfirmRequest,
  ShowConfirmCancel,
  ShowConfirmApprove,
  ShowConfirmReject,

  MyAlert //Component call by useRef Hook nad Place component on page
}