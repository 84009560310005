import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

  AlertError,
} from '../../components/Utility/MyString';

const TBR_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/sales/acard_save";



export default class TBR_Provider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        item_no: '',
        acard_no: '',
        sourceofcus: '',
        media: '',
        cusgroup: '',
        custype: '',
        colour: '',
        salejobtype: '',
        acard_contace: '',
        customername: '',
        mobilephone: '',
        userid: '',
        companycode: ''
      },
      userAuthorize: [
        {
          userid: '',
          menucode: '',
          menutext: '',
          useuse: 0,
          isinsert: 0,
          isupdate: 0,
          isdelete: 0
        }
      ],
      itempage: [
        {
          item_no: '',
          acard_no: '',
          companycode: '',
          totalrecord: 1
        }],
      itempage_matching: [
        {
          item_no: '',
          acard_no: '',
          companycode: '',
          totalrecord: 1
        }],

      itempage_fk_list: [
        {
          imagename: '',

        }],
      itempage_fk_Qm1: [
        {
          docno: '',
          acard_no: ''

        }],
      itempage_approve_list: [
        {
          process_id: '',
          createdate_th: '',
          request_details1: '',
          ref_no: '',
          qtyapprove: '',
          qty: ''


        }],


      pageindex: 1,
      pagesize: 20,
      strsearch: '',
      strfromdate: '',
      strenddate: '',
      companycode: '',
      totalrecord: 0,
      pagecount: 0,
      isLogin: false
    }
  }


  setData = async (values) => {
    this.setState({
      ...this.state,
      data: values
    });

    console.log("state->" + JSON.stringify(this.state))

  };


  firstPage = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage_matching(callBack)
    }, 200)

  }

  previousPage = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage_matching(callBack)
    }, 200)

  }

  nextPage = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage_matching(callBack)
    }, 200)

  }

  lastPage = async (callBack) => {
    this.setState({
      pageindex: this.state.pagecount
    });

    let obj = this

    setTimeout(function () {
      //alert("Last Page ...." + obj.state.pageindex)
      obj.getPage_matching(callBack);
    }, 200)

  }




  getPage_matching = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,
        filter_month: sessionStorage.getItem("filter_month"),
        filter_userid: sessionStorage.getItem("filter_userid"),
        filter_team: sessionStorage.getItem("filter_team"),
        filter_jobstatus: sessionStorage.getItem("filter_jobstatus"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid")
      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/tbr/matchingcontrol_page";

      await axios
        .post(url, { data: page })

        .then((response) => {

          //  alert("context  getPage_matching    ->" + JSON.stringify(response.data[1].totalrecord));

          let xpagecount = parseInt(response.data[0].totalrecord / 20);
          let ypage = response.data[0].totalrecord % 20;
          if (ypage > 0) { xpagecount = xpagecount + 1 }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,


              itempage: response.data,
              totalrecord: response.data[0].totalrecord,
              pagecount: xpagecount


            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };


  getMatchingFC = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,
        cartype: sessionStorage.getItem("cartype"),
        color: sessionStorage.getItem("color"),
        reservation_no: sessionStorage.getItem("reservation_no"),
        filter_sortby: sessionStorage.getItem("filter_match_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_match_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid")




      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/tbr/getmatchingfc";

      await axios
        .post(url, { data: page })

        .then((response) => {

          //  alert("context  getPage_matching    ->" + JSON.stringify(response.data[0].stock));
          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,


              itempage_matching: response.data,



            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };


  SaveMatchingFC = async (callBack) => {
    try {



      let page = {

        dn_no: sessionStorage.getItem("dn_no"),
        reservation_no: sessionStorage.getItem("reservation_no"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid")




      };

      //   alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/tbr/savematchingfc";


      await axios.post(url, { data: page })

        .then((res) => {
          // this.setState({
          //     ...this.state,
          //     data: res.data
          // });

          //  AlertError("context from server->" + JSON.stringify(res.data));

          setTimeout(function () {
            callBack(res.data)
          }, 100)

        })



    } catch (error) {

      callBack([{ error: error }]);
    }
  };



  getApproveTbrFc = async (callBack) => {
    try {

      let url = BaseApi + "/api/tbr/tbr_getdataapprovefc";

      let strsearch = sessionStorage.getItem("item_no");
      let userid = sessionStorage.getItem("userid");



      let page = {

        strsearch: strsearch,
        userid: userid

      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // AlertError("context getDataInvitedetail from server->" + response.data.fk_list) ;


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              itempage_fk_list: response.data.fk_list,
              itempage_fk_Qm1: response.data.fk_qm1,
              itempage_approve_list: response.data.approve_list
              


            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };



  SupApproveFC = async (callBack) => {

    try {


      let xdata = {


        acard_no: sessionStorage.getItem("acard_no")
        , item_no: sessionStorage.getItem("item_no")
        , approve_status: sessionStorage.getItem("approve_status")
        , approve_note: sessionStorage.getItem("approve_note")
        , substatus: sessionStorage.getItem("substatus")
        , userid: sessionStorage.getItem("userid")



      }



      let url = BaseApi + "/api/tbr/sup_approve_fc";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((res) => {
          if (res.data === "true") {
            callBack(true)
          }
        })

    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }




  render() {
    return (
      <TBR_Context.Provider
        value={{
          ...this.state,
          handleChange: this.handleChange,
          setData: this.setData,
          saveData: this.saveData,
          getData: this.getData,
          getRunning: this.getRunning,
          deleteData: this.deleteData,
          getPage_matching: this.getPage_matching,
          firstPage: this.firstPage,
          previousPage: this.previousPage,
          nextPage: this.nextPage,
          lastPage: this.lastPage,
          getMatchingFC: this.getMatchingFC,
          SaveMatchingFC: this.SaveMatchingFC,
          getApproveTbrFc: this.getApproveTbrFc,
          SupApproveFC: this.SupApproveFC,




        }}
      >
        {this.props.children}
      </TBR_Context.Provider>

    );
  }
}
const TBR_Consumer = TBR_Context.Consumer;

export { TBR_Provider, TBR_Consumer, TBR_Context };

export function withTBR_Consumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <TBR_Consumer>
        {value => <Component {...props} context={value} />}
      </TBR_Consumer>
    );
  };
}

