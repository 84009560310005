import React, { Component } from "react";
import axios from "axios";
import BaseApi from "../../Api/BaseApi";

import { AlertError } from "../../components/Utility/MyString";

const UserContext = React.createContext();
const ServiceApi = BaseApi + "/api/admin/branch_user";

export default class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        userid: "",
        password: "",
        firstname: "",
        lastname: "",
        telephone: "",
        email: "",
        activestatus: "1",
        position: 0,
        level: 0,
        teamid: "",
        branchid: "",
        companycode: "",
        companyname: "",
      },
      userAuthorize: [
        {
          userid: "",
          menucode: "",
          menutext: "",
          useuse: 0,
          isinsert: 0,
          isupdate: 0,
          isdelete: 0,
        },
      ],
      itempage: [
        {
          userid: "",
          password: "",
          fullname: "",
          email: "",
          activestatus: "1",
          position: "",
          level: "",
          remark: "",
          teamid: "",
          branchid: "",
          companycode: "",
          companyname: "",
          totalrecord: 1,
        },
      ],
      pageindex: 1,
      pagesize: 100,
      strsearch: "",
      filter_userid:'',
      filter_team:'',
      filter_month:'',
      companycode: "",
      totalrecord: 0,
      pagecount: 0,
      isLogin: false,
    };
  }

  setData = async (values) => {
    this.setState({
      ...this.state,
      data: values,
    });

    console.log("state->" + JSON.stringify(this.state));
  };

  firstPage = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1,
    });

    let obj = this;
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack);
    }, 200);
  };

  previousPage = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("");
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1,
    });

    let obj = this;
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack);
    }, 200);
  };

  nextPage = async (callBack) => {
    if (this.state.pageindex === this.state.pagecount) {
      callBack("");
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1,
    });

    let obj = this;
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack);
    }, 200);
  };

  lastPage = async (callBack) => {
    this.setState({
      pageindex: this.state.pagecount,
    });

    let obj = this;

    setTimeout(function () {
      //alert("Last Page ...." + obj.state.pageindex)
      obj.getPage(callBack);
    }, 200);
  };

  getPage = async (callBack) => {
    try {
      //alert("product context getpage -> " + this.state.userid);

      let page = {
        pageindex: this.state.pageindex,
        pagesize: this.state.pagesize,
        strsearch:  sessionStorage.getItem("strsearch"),
        filter_team : sessionStorage.getItem("filter_team"),
        filter_position :sessionStorage.getItem("filter_position"),
        companycode:  this.state.companycode,
        userid:  this.state.userid,
      };

      //alert("inbox context get page")

      let url = BaseApi + "/api/admin/admin_user_page";

      //alert("context getpage ->" + JSON.stringify(page));

      await axios.post(url, { data: page }).then((response) => {
        //AlertError("getpage totalrecord ->" + JSON.stringify(response.data));

        let xpagecount = parseInt(response.data[0].totalrecord / 20);
        let ypage = response.data[0].totalrecord % 20;
        if (ypage > 0) {
          xpagecount = xpagecount + 1;
        }

        let obj = this;

        setTimeout(function () {
          obj.setState({
            ...obj.state,
            itempage: response.data,
            totalrecord: response.data[0].totalrecord,
            pagecount: xpagecount,
          });
        }, 100);

        setTimeout(function () {
          callBack(response.data);
        }, 100);
      });
    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("");
    }
  };

  getAllMenu = async (callBack) => {
    try {
      //alert(userid);
      let url = BaseApi + "/api/admin/admin_user_menu";

      await axios.get(url, { params: { userid: "" } }).then((response) => {
        //AlertError("context from server->" + JSON.stringify(response.data));

        this.setState({
          ...this.state,
          userAuthorize: response.data,
        });
        setTimeout(function () {
          callBack(response.data);
        }, 200);
      });
    } catch (error) {
      AlertError(error.message);
      callBack("");
    }
  };

  getMenubyUserid = async (userid, callBack) => {
    try {
      //alert(userid);
      let url = BaseApi + "/api/admin/admin_user_menu";

      await axios.post(url, { data: { userid: userid } }).then((response) => {
        //AlertError("context from server->" + JSON.stringify(response.data));

        this.setState({
          ...this.state,
          userAuthorize: response.data,
        });
        setTimeout(function () {
          callBack(response.data);
        }, 200);
      });
    } catch (error) {
      AlertError(error.message);
      callBack("");
    }
  };

  getData = async (userid, password, callBack) => {
    const _this = this;
 
    try {
      //  alert("userid " +  userid  + "password " + password);

      await axios
        .get(ServiceApi, { params: { userid: userid } })
        .then((response) => {
          // this.setState({
          //   ...this.state,
          //   data: response.data,
          // });
          setTimeout(function () {

            let xuserid = userid
            let yuserid = response.data.userid

           if (yuserid === undefined ) {

            return callBack(undefined);
           }

            if (
 
              yuserid.toLowerCase() === xuserid.toLowerCase()   &&
              response.data.password === password
            ) {
              // console.log(response.data)

              _this.setState({ isLogin: true });
              sessionStorage.setItem("userid",  response.data.userid);
              sessionStorage.setItem("level",   response.data.level);
              sessionStorage.setItem("companycode",  response.data.companycode);
              sessionStorage.setItem("companyname",  response.data.companyname);
              sessionStorage.setItem("position",  response.data.position);
              sessionStorage.setItem("teamid",  response.data.teamid);
              
              sessionStorage.setItem("isLogin", JSON.stringify(1));
              return callBack(response.data);
            }
            return callBack(undefined);

            // console.log(response)
          }, 100);
        });
    } catch (error) {
      AlertError(error.code + ":" + error.message   );
      callBack();
    }
  };

  getUserAdmin = async (userid, callBack) => {
    try {
      //alert(userid);

      await axios
        .get(ServiceApi, { params: { userid: userid } })
        .then((response) => {
          //AlertError("context from server->" + JSON.stringify(response.data));

          setTimeout(function () {
            callBack(response.data);
          }, 500);
        });
    } catch (error) {
      AlertError(error.message);
      callBack("");
    }
  };

  getAll = async (callBack) => {
    try {
      //alert(userid);

      await axios
        .get(BaseApi + "/api/admin/branch_user_all", { params: { userid: "" } })
        .then((response) => {
          //AlertError("context from server->" + JSON.stringify(response.data));
          /*
          this.setState({
              ...this.state,               
              itempage : response.data,              
          });
          */
          setTimeout(function () {
            callBack(response.data);
          }, 500);
        });
    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };
  getAll2 = async (callBack) => {
    try {
      //alert(userid);

      await axios
        .get(BaseApi + "/api/admin/branch_user_all2", {
          params: { userid: "" },
        })
        .then((response) => {
          //AlertError("context from server->" + JSON.stringify(response.data));
          /*
          this.setState({
              ...this.state,               
              itempage : response.data,              
          });
          */
          setTimeout(function () {
            callBack(response.data);
          }, 500);
        });
    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };

  saveData = async (xdata, callBack) => {
    try {
      //callBack()
      //xdata.password = this.state.data.password
      //alert("password->" + this.state.data.password)
      //alert("user context save -> " + JSON.stringify(xdata))

      await axios
        .post(ServiceApi, {
          data: xdata,
        })
        .then((res) => {
          if (res.data === "true") {
            callBack(true);
          }
        });
    } catch (error) {
      AlertError(error.message);
      callBack(false);
    }
  };

  updateData = async (xdata, callBack) => {
    try {
      //callBack()
      //xdata.password = this.state.data.password
      //alert("password->" + this.state.data.password)

      await axios
        .put(ServiceApi, {
          data: xdata,
        })
        .then((res) => {
          if (res.data === "true") {
            callBack(true);
          }
        });
    } catch (error) {
      AlertError(error.message);
      callBack(false);
    }
  };

  deleteData = async (xdata, callBack) => {
    try {
      //alert("delete from context -> " + ServiceApi )

      await axios
        .delete(ServiceApi, {
          data: {
            userid: xdata.userid,
          },
        })
        .then((res) => {
          if (res.data === "true") {
            setTimeout(function () {
              //message.success('Delete Data Complete..', 5);
              callBack(true);
            }, 500);
          }
        });
    } catch (error) {
      AlertError(error.message);
      callBack(false);
    }
  };

  setisLogin = (xisLogin, userid) => {
    //submit to server

    this.setState({
      isLogin: xisLogin,
      data: {
        userid: userid,
      },
    });
  };

  setLogin = (userid, password, callBack) => {
    //submit to server

    // this.setState({
    //   isLogin: true,
    //   data:{
    //     userid: userid,
    //     password: password
    //   }

    // })

    this.getData(userid, password, callBack);
  };

  setLogout = (callBack) => {
    this.setState({
      isLogin: false,
      data: {
        userid: "",
        password: "",
      },
    });

    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("level");
    sessionStorage.removeItem("companycode");
    sessionStorage.removeItem("companyname");
    sessionStorage.removeItem("isLogin");
    sessionStorage.removeItem("auth_menu");

    setTimeout(()=>{
      callBack(true)
    },500)


  };

  saveAuthorize = async (xdata, callBack) => {
    try {
      let url = BaseApi + "/api/admin/admin_authorize";
      //let url = BaseApi + "/a"
      alert("save author " + JSON.stringify(xdata));

      await axios
        .post(url, {
          data: xdata,
        })
        .then((res) => {
          if (res.data === "true") {
            setTimeout(function () {
              callBack(true);
            }, 2000);
          }
        });
    } catch (error) {
      AlertError(error.message);
      callBack(false);
    }
  };

  //select changed
  handleChange = (str, callBack) => {
    this.setState({
      //...this.state,
      strsearch: str,
    });

    let obj = this;
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack);
    }, 200);
  };

  //function
  componentDidMount() {
    //this.getData();
  }

  getMenubyUserid2 = async (userid, callBack) => {
    try {
      //alert(userid);
      let url = BaseApi + "/api/admin/admin_user_menu2";

      await axios.post(url, { data: { userid: userid } }).then((response) => {
        //AlertError("menu from server->" + JSON.stringify(response.data));

        this.setState({
          ...this.state,
          userAuthorize: response.data,
        });

        //this.genMenuItem(response.data)

        setTimeout(function () {
          //window.location.reload()
          //document.title = "Database : " + sessionStorage.getItem("databasename")
          callBack(response.data);
        }, 100);
      });
    } catch (error) {
      //AlertError(error.message)
      callBack("");
    }
  };

getPagemenuByID = async (userid,callBack) => {
    try {
  
      let userid = sessionStorage.getItem("userid")
      let   companycode = sessionStorage.getItem("companycode");

      let page = {
        companycode:   companycode,
        userid: userid,
      };

      //alert("inbox context get page")

      let url = BaseApi + "/api/admin/user_menu_dashboard";

    
      await axios.post(url, { data: page }).then((response) => {
       

        this.setState({
          ...this.state,
          userAuthorize: response.data,
        });
 
        setTimeout(function () {
          callBack(response.data);
        }, 100);
      });
    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("");
    }
  };
 
 

  getUserListbyteam_newuser = async (callBack) => {
    try {
      let page = {
        strsearch: "Newuser",
        filter_team: sessionStorage.getItem("filter_team"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid"),
      };

      await axios
        .post(BaseApi + "/api/admin/getuserlistbyteam", { data: page })
        .then((response) => {
          //    AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
          /*
                this.setState({
                    ...this.state,               
                    itempage : response.data,              
                });
                */
          setTimeout(function () {
            callBack(response.data);
          }, 500);
        });
    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };

  getUserListbyteam = async (callBack) => {
    try {
      let page = {
        strsearch: sessionStorage.getItem("strsearch"), 
        filter_team: sessionStorage.getItem("filter_team"), 
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid"),
      };

      await axios
        .post(BaseApi + "/api/admin/getuserlistbyteam", { data: page })
        .then((response) => {
          //    AlertError("context from server getprovince getall->" + JSON.stringify(response.data));
          /*
                this.setState({
                    ...this.state,               
                    itempage : response.data,              
                });
                */
          setTimeout(function () {
            callBack(response.data);
          }, 100);
        });
    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };

  
  getDateTime = async (callBack) => {
    try {

        let url = BaseApi + "/api/admin/getdatetime";
        
        let code  ='';

      await axios.post(url, { params: { code: code } })
        .then((response)=>{

        // AlertError("context getDateTime from server->" + JSON.stringify(response.data));
       
          this.setState({
              ...this.state,               
              data : response.data
          });

          setTimeout(function(){
            callBack(response.data)
          },100)

        })
      
      
    } catch (error) {
      AlertError(error);
    }
  };

  

  
  getFilterteam = async (callBack) => {
    try {
      let page = {
        strsearch: sessionStorage.getItem("strsearch"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid"),
      };

      await axios
        .post(BaseApi + "/api/admin/getfilterteam", { data: page })
        .then((response) => {
        
          // AlertError("context getFilterteam from server->" + JSON.stringify(response.data));

          setTimeout(function () {
            callBack(response.data);
          }, 100);
        });
    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };

  
  
  getFilterJob = async (callBack) => {
    try {
      let page = {
        filter_month: sessionStorage.getItem("filter_month"),
        filter_team : sessionStorage.getItem("filter_team"),
        filter_userid: sessionStorage.getItem("filter_userid"),
        companycode: sessionStorage.getItem("companycode"),
        userid: sessionStorage.getItem("userid"),
      };

      await axios
        .post(BaseApi + "/api/admin/getfilterjob", { data: page })
        .then((response) => {
        
          setTimeout(function () {
            callBack(response.data);
          }, 100);
        });
    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };

  

  getUserAll = async (callBack) => {
    try {

      let url = BaseApi + "/api/admin/getuserall";

 
      let userid = sessionStorage.getItem("userid");
      let companycode = sessionStorage.getItem("companycode");


      let page = {

        userid: userid 
        
      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

        //    AlertError("context getDataReceiptcar from server->" + response.data.details) ;


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              itempage:  response.data ,
   

            });
          }, 100);

          // setTimeout(function () {
          //   callBack(response.data);
          // }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };

  

  
  
  getFilterteamAll = async (callBack) => {
    try {
      let page = {
        strsearch: sessionStorage.getItem("strsearch"),
        companycode: sessionStorage.getItem("companycode"),
        userid: 'All',
      };

      await axios
        .post(BaseApi + "/api/admin/getfilterteam", { data: page })
        .then((response) => {
        
          // AlertError("context getFilterteam from server->" + JSON.stringify(response.data));

          setTimeout(function () {
            callBack(response.data);
          }, 100);
        });
    } catch (error) {
      AlertError(error.message);
      //callBack()
    }
  };


  

  

  UpdateUserlog = async (callBack) => {
  try {

      let url = BaseApi + "/api/admin/updateuserlog";

       

      let page = {

          acard_no: sessionStorage.getItem("acard_no") ,
          status: "Success" ,
          act : sessionStorage.getItem("act") ,
          pageurl: sessionStorage.getItem("pageurl") , 
          sp: sessionStorage.getItem("sp") ,
          details : sessionStorage.getItem("details") ,
          userid: sessionStorage.getItem("userid") 

      };

      //   alert("context   ->" + JSON.stringify(page));



      await axios
          .post(url, { data: page })

          .then((response) => {
              // console.log(response)

              // AlertError("context getDataCancelAdvancepaydetail from server->" + response.data.fk_list) ;


              let obj = this;

              // setTimeout(function () {
              //     obj.setState({
              //         ...obj.state,
              //         itempage_fk_list: response.data.fk_list,
              //         //   itempage_receiptcar:  response.data.details ,


              //     });
              // }, 100);

              setTimeout(function () {
                  callBack(response.data);
              }, 100);


          });
  } catch (error) {

      callBack([{ error: error }]);
  }
};



  

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          handleChange: this.handleChange,
          setData: this.setData,
          saveData: this.saveData,
          getData: this.getData,
          deleteData: this.deleteData,
          getPage: this.getPage,
          firstPage: this.firstPage,
          previousPage: this.previousPage,
          nextPage: this.nextPage,
          lastPage: this.lastPage,
          setLogin: this.setLogin,
          setLogout: this.setLogout,
          getAll: this.getAll,
          getAll2: this.getAll2,
          getUserAdmin: this.getUserAdmin,
          getUserListbyteam: this.getUserListbyteam,
          getUserListbyteam_newuser: this.getUserListbyteam_newuser,
          updateData: this.updateData,
          getAllMenu: this.getAllMenu,
          getMenubyUserid: this.getMenubyUserid,
          getMenubyUserid2: this.getMenubyUserid2,
          getPagemenuByID: this.getPagemenuByID,
          saveAuthorize: this.saveAuthorize,
          setisLogin: this.setisLogin,
          getDateTime: this.getDateTime,
          getFilterteam: this.getFilterteam,
          getFilterJob: this.getFilterJob,
          getUserAll: this.getUserAll,
          getFilterteamAll: this.getFilterteamAll,
          UpdateUserlog: this.UpdateUserlog
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
const UserConsumer = UserContext.Consumer;

export { UserProvider, UserConsumer, UserContext };

export function withUserConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <UserConsumer>
        {(value) => <Component {...props} context={value} />}
      </UserConsumer>
    );
  };
}
