 
 
  
 
const BaseApi = "https://qms-toyotaparagon.com" 

// const BaseApi = "http://127.0.0.1:5000" 
 
export default BaseApi;

 