import React, { Component } from "react";
import { HashRouter, BrowserRouter,Redirect, Route, Switch } from "react-router-dom";

import { UserProvider } from "./context/Admin/UserContext";
import {MenuProvider} from "./context/Admin/MenuItemContext";
import { ProductProvider } from './context/Product/ProductContext';

import { ProductList_Provider } from "./context/Product/ProductList_Context";
import { Productdetailbyregister_Provider } from './context/Productdetailbyregister_Context'; 

// ============================================================
// import { Summarybysales_Provider } from './context/Summarybysales_Context';
// import { SummarybyteamProvider } from './context/SummarybyteamContext';

import { Product_Sold_Provider } from './context/Product/Product_Sold_Context';
// import { Productcurrentstock_Provider } from './context/Productcurrentstock_Context'; 
 
// import { Productlistbymodel_Provider } from './context/Productlistbymodel_Context'; 
// import { ProductSearch_Provider } from './context/ProductSearch_Context'; 

import { Address_Provider } from './context/Address/Address_Context';
import { Acard_Provider } from './context/Sales/Acard_Context';
import  { Account_Provider } from './context/Sales/Account_Context';
import  { Personal_Provider } from './context/Sales/Personal_Context';

import { DB_v2_Provider } from './context/Sales/DB_V2_Context';
import { DB_Provider } from './context/Sales/DB_Context';
import { Job_Provider } from './context/Sales/Job_Context';
import { DB_RPT_Provider } from './context/Sales/DB_RPT_Context';
import { Filekeep_Provider } from './context/Filekeep/Filekeep_Context'; 
 
 import { Score_Card_Provider }  from './context/Sales/Score_Card_Context';
 import { Reservate_Provider }  from './context/Sales/Reservate_Context';
 
 import { SaleInfo_Provider }  from './context/Sales/SaleInfo_Context';

 import { OTP_Provider }  from './context/Sales/OTP_Context';

 import { TBR_Provider }  from './context/Sales/TBR_Context';

 import { MP_Provider }  from './context/Sales/Mp_Context';
// ============================================================

import "./scss/style.scss";

import "antd/dist/antd.css";
import 'swiper/swiper.min.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

//context


// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <UserProvider>
        <MenuProvider>
          <ProductList_Provider>
            <Productdetailbyregister_Provider>

          {/* <Productcurrentstock_Provider> 
          <Productlistbybrand_Provider>
          <Productlistbymodel_Provider>  */}
          {/* <ProductSearch_Provider> */}

          <Product_Sold_Provider> 
          <DB_Provider>
          <DB_RPT_Provider> 
          <Job_Provider>
          <Address_Provider>
          <Filekeep_Provider>
          <Acard_Provider>
          <Score_Card_Provider>
          <ProductProvider>
          <OTP_Provider>
          <Account_Provider>
          <Reservate_Provider>
          <SaleInfo_Provider>
          <TBR_Provider>
          <Personal_Provider>
          <MP_Provider>
          <DB_v2_Provider>


         
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>


          </DB_v2_Provider>
          </MP_Provider>
          </Personal_Provider>
          </TBR_Provider>
          </SaleInfo_Provider>
          </Reservate_Provider>
          </Account_Provider>
          </OTP_Provider>
          </ProductProvider>
          </Score_Card_Provider>
          </Acard_Provider>
          </Filekeep_Provider>
          </Address_Provider>
          </Job_Provider>
          </DB_RPT_Provider> 
          </DB_Provider>
          </Product_Sold_Provider>
          {/* </ProductSearch_Provider> */}
          {/* </Productlistbymodel_Provider> 
          </Productlistbybrand_Provider> 
          </Productcurrentstock_Provider>     */}
         
         
          </Productdetailbyregister_Provider>
          </ProductList_Provider>
          </MenuProvider>
        </UserProvider>
      </HashRouter>
    );
  }
}

export default App;


