import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {
    // AlertCompleSave,
    AlertError,
} from '../../components/Utility/MyString';

const Filekeep_Context = React.createContext();


const ServiceApi = BaseApi + "/api/sales/db_save";

export default class Filekeep_Provider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                fk_group_id: '',
                fk_type_id: '',
                register: '',
                docno: '',
                link_url: '',
                imagename: '',
                companycode: '',
                remark: ''

            },
            userAuthorize: [
                {
                    userid: '',
                    menucode: '',
                    menutext: '',
                    useuse: 0,
                    isinsert: 0,
                    isupdate: 0,
                    isdelete: 0
                }
            ],
            itempage: [
                {
                    fk_group_id: '',
                    fk_type_id: '',
                    register: '',
                    docno: '',
                    link_url: '',
                    imagename: '',
                    companycode: '',
                    remark: '',
                    totalrecord: 1
                }],
            fk_list_FK01: [
                {
                    fk_type_id: '',
                    imagename: ''
                }],
            fk_list_FK02: [
                {
                    fk_type_id: '',
                    imagename: ''
                }],
            fk_list_FK03: [
                {
                    fk_type_id: '',
                    imagename: ''
                }],
            fk_list_FK04: [
                {
                    fk_type_id: '',
                    imagename: ''
                }],
            fk_list_FK05: [
                {
                    fk_type_id: '',
                    imagename: ''
                }],
            fk_list_FK06: [
                {
                    fk_type_id: '',
                    imagename: ''
                }],
            fk_list_FK07: [
                {
                    fk_type_id: '',
                    imagename: ''
                }],
            fk_list_FK08: [
                {
                    fk_type_id: '',
                    imagename: ''
                }],
            fk_list_FK09: [
                {
                    fk_type_id: '',
                    imagename: ''
                }],
            fk_list_FK10: [
                {
                    fk_type_id: '',
                    imagename: ''
                }],
            pageindex: 1,
            pagesize: 20,
            strsearch: '',
            companycode: '',
            totalrecord: 0,
            pagecount: 0,
            ischecked: 0,
            isLogin: false
        }
    }


    setData = async (values) => {
        this.setState({
            ...this.state,
            data: values
        });

        console.log("state->" + JSON.stringify(this.state))

    };


    firstPage = async (callBack) => {
        this.setState({
            ...this.state,
            pageindex: 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    previousPage = async (callBack) => {
        if (this.state.pageindex === 1) {
            callBack("")
            return;
        }
        this.setState({
            ...this.state,
            pageindex: this.state.pageindex - 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    nextPage = async (callBack) => {

        if (this.state.pageindex === this.state.pagecount) {
            callBack("")
            return;
        }

        this.setState({
            ...this.state,
            pageindex: this.state.pageindex + 1
        })

        let obj = this
        setTimeout(function () {
            //alert("from context->" + obj.state.strsearch);
            obj.getPage(callBack)
        }, 200)

    }

    lastPage = async (callBack) => {
        this.setState({
            pageindex: this.state.pagecount
        });

        let obj = this

        setTimeout(function () {
            //alert("Last Page ...." + obj.state.pageindex)
            obj.getPage(callBack);
        }, 200)

    }

    getPage = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let xstrsearch = sessionStorage.getItem("strsearch")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")

            let page = {
                pageindex: this.state.pageindex,
                pagesize: this.state.pagesize,
                strsearch: xstrsearch,
                companycode: companycode,
                userid: userid
            }


            let url = BaseApi + "/api/filekeep/filekeep_page";



            await axios.post(url, { data: page })


                .then((response) => {


                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,
                        // totalrecord: response.data[0].totalrecord, 
                        pagecount: xpagecount
                    });
                    // }, 100)


                    // setTimeout(function () {
                    //     callBack(response.data)
                    // }, 200)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };



    updateData = async (xdata, callBack) => {

        try {

            //callBack()
            //xdata.password = this.state.data.password
            //alert("password->" + this.state.data.password)

            await axios.put(ServiceApi, {
                data: xdata
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }

    deleteData = async (xdata, callBack) => {

        try {

            //alert("delete from context -> " + ServiceApi )

            await axios.delete(ServiceApi, {
                data: {
                    code: xdata.code
                }
            })
                .then((res) => {
                    if (res.data === "true") {
                        setTimeout(function () {
                            //message.success('Delete Data Complete..', 5);
                            callBack(true)
                        }, 500)
                    }
                })


        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }




    //select changed
    handleChange = (str1, callBack) => {


        this.setState({

            ...this.state,

            strsearch: str1.strsearch


        });
        let obj = this
        setTimeout(function () {

            obj.getPage(callBack)
        }, 200)

    }





    //function
    componentDidMount() {
        //this.getData();    
    }

    getRunning = async (code, callBack) => {
        try {

            //alert("product context getpage -> " + this.state.strsearch);
            let page = {

                fk_group_id: sessionStorage.getItem("fk_group_id"),
                fk_type_id: sessionStorage.getItem("fk_type_id"),
                acard_no: sessionStorage.getItem("acard_no"),
                userid: sessionStorage.getItem("userid"),
            }

            //alert("inbox context get page") 
            let url = BaseApi + "/api/filekeep/filekeep_running";


            //  alert("context getpage ->" + JSON.stringify(page));

            await axios.post(url, { data: page })
                .then((response) => {

                    //  alert("context get running ->" + JSON.stringify(response.data));

                    this.setState({
                        ...this.state,
                        data: {
                            filename: response.data[0].filename,
                            // cusdb_id : response.data[0].cusdb_id, 
                            // jobdate : response.data[0].jobdate, 
                            // jobtime : response.data[0].jobtime, 

                        },
                        dataitem: [],
                        datadealer: []
                    })

                    let xobj = this
                    setTimeout(function () {
                        callBack(xobj.state.data)
                    }, 100)

                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };









    Myresize = async (xdata, callBack) => {

        try {

            //  alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/filekeep/filekeep_resizeimage";

            let page = {

                acard_no: sessionStorage.getItem("acard_no"),
                fk_group_id: sessionStorage.getItem("fk_group_id"),
                fk_type_id: sessionStorage.getItem("fk_type_id"),
                filename: sessionStorage.getItem("filename"),
                companycode: sessionStorage.getItem("companycode")

            }



            await axios.post(url, {
                data: page
            })

                .then((res) => {


                    setTimeout(function () {
                        callBack(res.data)
                    }, 200)
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }




    getPageFileType = async (xdata, callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let fk_group_id = sessionStorage.getItem("fk_group_id")
            let register = sessionStorage.getItem("register")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")

            let page = {
                fk_group_id: fk_group_id,
                register: register,
                companycode: companycode,
                userid: userid
            }

            //    alert("inbox context get page")

            let url = BaseApi + "/api/filekeep/filetype_page";

            // alert("context getPageFileType   ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                    //  alert("context DB  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    let ypage = response.data[0].totalrecord % 20;
                    if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data
                        // totalrecord: response.data[0].totalrecord, 
                        // pagecount: xpagecount
                    });
                    // }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };





    getFileListPage = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let fk_group_id = sessionStorage.getItem("fk_group_id")
            let fk_type_id = sessionStorage.getItem("fk_type_id")
            let acard_no = sessionStorage.getItem("acard_no")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")

            let page = {
                fk_group_id: fk_group_id,
                fk_type_id: fk_type_id,
                acard_no: acard_no,
                companycode: companycode,
                userid: userid
            }

            //    alert("inbox context get page")

            let url = BaseApi + "/api/filekeep/filelist_page";

            // alert("context getPageFileType   ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                    //  alert("context DB  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    // let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    // let ypage = response.data[0].totalrecord % 20;
                    // if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,


                    });
                    // }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };



    MyListFile = async (xdata, callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/filekeep/filekeep_listfile";

            let page = {

                acard_no: sessionStorage.getItem("acard_no"),
                fk_group_id: sessionStorage.getItem("fk_group_id"),
                fk_type_id: sessionStorage.getItem("fk_type_id"),
                filename: sessionStorage.getItem("filename"),
                companycode: sessionStorage.getItem("companycode")

            }

            //alert("inbox context get page")

            

                // alert("context MyListFile->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    MyDeleteImage = async ( callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/filekeep/filekeep_deleteimage";

 

            let page = {

                acard_no: sessionStorage.getItem("acard_no"),
                fk_group_id: sessionStorage.getItem("fk_group_id"),
                fk_type_id: sessionStorage.getItem("fk_type_id"),
                filename: sessionStorage.getItem("filename"),
                companycode: sessionStorage.getItem("companycode")


            }


            // alert("context   MyDeleteImage 2 ->" + JSON.stringify(page));


            await axios.post(url, {
                data: page
            })
                .then((res) => {
                    if (res.data === "true") {
                        callBack(true)
                    }
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }




    getfk_group_id = async (callBack) => {

        try {
            let url = BaseApi + "/api/filekeep/filekeep_getfk_group_id";

            let page = {

                // register: sessionStorage.getItem("register"),
                // fk_group_id :sessionStorage.getItem("fk_group_id") ,
                // fk_type_id :sessionStorage.getItem("fk_type_id") ,
                // filename :sessionStorage.getItem("filename"), 
                companycode: sessionStorage.getItem("companycode")

            }
            await axios.post(url, {
                data: page
            })
                .then((res) => {

                    this.setState({
                        ...this.state,
                        itempage: res.data,
                    });


                    setTimeout(function () {
                        callBack(res.data)
                    }, 500)
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    getfk_type_id = async (callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/filekeep/filekeep_getfk_type_id";

            let page = {

                // register: sessionStorage.getItem("register"),
                fk_group_id: sessionStorage.getItem("fk_group_id"),
                // fk_type_id :sessionStorage.getItem("fk_type_id") ,
                // acard_no :sessionStorage.getItem("acard_no"), 
                companycode: sessionStorage.getItem("companycode")

            }


            await axios.post(url, {
                data: page
            })
                .then((res) => {

                    this.setState({
                        ...this.state,
                        itempage: res.data,
                    });

                    setTimeout(function () {
                        callBack(res.data)
                    }, 500)
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }


    getPageFileList = async (callBack) => {
        try {



            let page = {

                acard_no: sessionStorage.getItem("acard_no"),
                userid: sessionStorage.getItem("userid")
            }

            //    alert("inbox context get page")

            let url = BaseApi + "/api/filekeep/filelist";

            // alert("context getPageFileType   ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        fk_list_FK01: response.data.fk_list_FK01,
                        fk_list_FK02: response.data.fk_list_FK02,
                        fk_list_FK03: response.data.fk_list_FK03,
                        fk_list_FK04: response.data.fk_list_FK04,
                        fk_list_FK05: response.data.fk_list_FK05,
                        fk_list_FK06: response.data.fk_list_FK06,
                        fk_list_FK07: response.data.fk_list_FK07,
                        fk_list_FK08: response.data.fk_list_FK08,
                        fk_list_FK09: response.data.fk_list_FK09,
                        fk_list_FK10: response.data.fk_list_FK10,


                    });
                    // }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    

    
    SaveFilekeep = async ( callBack) => {

        try {

            // alert("context Myresize->" + JSON.stringify(xdata));

            let url = BaseApi + "/api/filekeep/filekeep_saveimage";




            let page = {

                acard_no: sessionStorage.getItem("acard_no"),
                fk_group_id: sessionStorage.getItem("fk_group_id"),
                fk_type_id: sessionStorage.getItem("fk_type_id"),
                filename: sessionStorage.getItem("filename"),
                companycode: sessionStorage.getItem("companycode"),
                filetype: sessionStorage.getItem("filetype"),

            }


            //    alert("context getpage productlistbybrand 2 ->" + JSON.stringify(page));


            await axios.post(url, {    data: page  })
                .then((res) => {


                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: res.data,


                    });
                    // }, 100)

                    setTimeout(function () {
                        callBack(res.data)
                    }, 200)
                })

        } catch (error) {
            AlertError(error.message);
            callBack(false)
        }
    }
    //function
    componentDidMount() {
        //this.getData();    
    }

    

    

    CheckFile = async (callBack) => {
        try {

            //alert("product context getpage -> " + this.state.userid);

            let fk_group_id = sessionStorage.getItem("fk_group_id")
            let fk_type_id = sessionStorage.getItem("fk_type_id")
            let checkfile = sessionStorage.getItem("checkfile")
            let acard_no = sessionStorage.getItem("acard_no")
            let companycode = sessionStorage.getItem("companycode")
            let userid = sessionStorage.getItem("userid")

            let page = {
                fk_group_id: fk_group_id,
                fk_type_id: fk_type_id,
                checkfile: checkfile,
                acard_no: acard_no,
                companycode: companycode,
                userid: userid
            }

            //    alert("inbox context get page")

            let url = BaseApi + "/api/filekeep/checkfile";

        //    alert("context CheckFile   ->" + JSON.stringify(page));

            await axios.post(url, { data: page })


                .then((response) => {

                    //  alert("context DB  paragon  sales- getpage ->" + JSON.stringify(response.data));

                    // let xpagecount = parseInt(response.data[0].totalrecord / 20);
                    // let ypage = response.data[0].totalrecord % 20;
                    // if (ypage > 0) { xpagecount = xpagecount + 1 }

                    let obj = this

                    // setTimeout(function () {
                    obj.setState({
                        ...obj.state,
                        itempage: response.data,


                    });
                    // }, 100)


                    setTimeout(function () {
                        callBack(response.data)
                    }, 200)


                })


        } catch (error) {
            //AlertError("error->" + error.message);
            callBack("")
        }
    };

    render() {
        return (
            <Filekeep_Context.Provider
                value={{
                    ...this.state,
                    handleChange: this.handleChange,
                    setData: this.setData,
                    getRunning: this.getRunning,
                    firstPage: this.firstPage,
                    previousPage: this.previousPage,
                    nextPage: this.nextPage,
                    lastPage: this.lastPage,
                    deleteData: this.deleteData,
                    updateData: this.updateData,
                    getPage: this.getPage,
                    getPageFileType: this.getPageFileType,
                    getfk_group_id: this.getfk_group_id,
                    getfk_type_id: this.getfk_type_id,
                    Myresize: this.Myresize,
                    getFileListPage: this.getFileListPage,
                    MyDeleteImage: this.MyDeleteImage,
                    MyListFile: this.MyListFile,
                    getPageFileList: this.getPageFileList,
                    SaveFilekeep: this.SaveFilekeep,
                    CheckFile: this.CheckFile,

                }}
            >
                {this.props.children}
            </Filekeep_Context.Provider>

        );

    }
}
const Filekeep_Consumer = Filekeep_Context.Consumer;

export { Filekeep_Provider, Filekeep_Consumer, Filekeep_Context };

export function withFilekeep_Consumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <Filekeep_Consumer>
                {value => <Component {...props} context={value} />}
            </Filekeep_Consumer>
        );
    };
}

