import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

  AlertError,
} from '../../components/Utility/MyString';

const OTP_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "";



export default class OTP_Provider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        item_no: '',
        acard_no: '', 
        customername: '',
        mobilephone: '',
        doc_type: "",
        statusconfirm: "",
        description: "",
        eventCode: "",
        transactionType: "",
        payeeProxyId: "",
        payeeProxyType: "",
        payeeAccountNumber: "",
        payeeName: "",
        payerAccountNumber: "",
        payerName: "",
        sendingBankCode: "",
        receivingBankCode: "",
        amount: "",
        transactionId: "",
        fastEasySlipNumber: "",
        transactionDateandTime: "",
        billPaymentRef1: "",
        billPaymentRef2: "",
        billPaymentRef3: "",
        currencyCode: "",
        channelCode: "",
        companycode: "",
        userid: "",
      },
      userAuthorize: [
        {
          userid: '',
          menucode: '',
          menutext: '',
          useuse: 0,
          isinsert: 0,
          isupdate: 0,
          isdelete: 0
        }
      ],
      itempage: [
        {
          item_no: '',
          acard_no: '',
          companycode: '',
          totalrecord: 1
        }],

        itempage_paymentlist: [
          {
            item_no: '',
            acard_no: '',
            companycode: '',
            totalrecord: 1
          }],
 
          itempage_otp: [
            {
              item_no: '',
              acard_no: '',
              companycode: '',
              totalrecord: 1
            }],

      pageindex: 1,
      pagesize: 20,
      strsearch: '',
      strfromdate: '',
      strenddate: '',
      companycode: '',
      totalrecord: 0,
      pagecount: 0,
      isLogin: false
    }
  }

 
 
  

  requestOtp = async (callBack) => {
    try {

      let url = BaseApi + "/api/acard/requestotp";

      // let acard_no = sessionStorage.getItem("acard_no"); 
      // let userid = sessionStorage.getItem("userid");
      // let companycode = sessionStorage.getItem("companycode");

      let mobilephone = sessionStorage.getItem("mobilephone");

      // alert(mobilephone)

      let page = {

      
        mobilephone: mobilephone 
        // acard_no: acard_no,
        // userid: userid,
        // companycode: companycode
      };

          // alert("context   ->" + JSON.stringify(page));
  
      await axios
        .post(url, { data: page })

        .then((response) => {

          // console.log("response >>>>" + response)

            // alert("context requestOtp from server->" + JSON.stringify(response.data));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data,
                

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

        console.log("error.  >>  " +  error  );

      callBack(error);

      // callBack([{ error: error }]);
    }
  };

  

  verifyOtp = async (callBack) => {
    try {

      let url = BaseApi + "/api/acard/verifyotp";

      
      let token = sessionStorage.getItem("token");
      let pin = sessionStorage.getItem("pin");
      // let userid = sessionStorage.getItem("userid");
      // let companycode = sessionStorage.getItem("companycode");


      let page = {

        // acard_no: acard_no,
        token: token,
        pin: pin,
        // userid: userid,
        // companycode: companycode
      };

          // alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

            //  alert("context requestOtp from server->" + JSON.stringify(response.data));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data,
                

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };


  

  SaveOtptoken = async (callBack) => {
    try {

      let url = BaseApi + "/api/acard/saveotptoken";
 
      let refdocno1 = "";
      let refdocno2 = "";
      let refdocno3 = "";
 
      let page = {

        acard_no: sessionStorage.getItem("acard_no"),
        cusdb_id: sessionStorage.getItem("cusdb_id"),
        otptype:  sessionStorage.getItem("otptype"),
        refdocno1: sessionStorage.getItem("refdocno1"),
        refdocno2: sessionStorage.getItem("refdocno2"),
        refdocno3: sessionStorage.getItem("refdocno3"),
        mobilephone: sessionStorage.getItem("mobilephone"),
        token: sessionStorage.getItem("token"), 
        refno: sessionStorage.getItem("refno"),
        statusreq : sessionStorage.getItem("statusreq"),
        userid: sessionStorage.getItem("userid"),
        companycode: sessionStorage.getItem("companycode")
      };

          // alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

            //  alert("context requestOtp from server->" + JSON.stringify(response.data));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data,
                

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };


  UpdateOtpComplete = async (callBack) => {
    try {

      let url = BaseApi + "/api/acard/updateotpcomplete";
 
      let page = {

        
        token: sessionStorage.getItem("token"), 
        refotp: sessionStorage.getItem("refno"),
        statusotp : sessionStorage.getItem("statusotp"),
        otp_descriptions : sessionStorage.getItem("otp_descriptions"),
     
      };

          // alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

            //  alert("context requestOtp from server->" + JSON.stringify(response.data));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data,
                

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{error}]);
    }
  };


  

  CheckOtpTimeout = async (callBack) => {
    try {

      let url = BaseApi + "/api/acard/checktimeoutotp";
 
     
 
      let page = {

        acard_no: sessionStorage.getItem("acard_no"),
        cusdb_id: sessionStorage.getItem("cusdb_id"), 
        mobilephone: sessionStorage.getItem("mobilephone"), 
        userid: sessionStorage.getItem("userid"),
        companycode: sessionStorage.getItem("companycode")
      };

          // alert("context   ->" + JSON.stringify(page));



      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

            //  alert("context requestOtp from server->" + JSON.stringify(response.data));


          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data,
                

            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };

  

  PaymentGetToken = async (callBack) => {
    
 
 
      try {

        let url = BaseApi + "/api/acard/requestpaymenttoken";
    
  
        let mobilephone = sessionStorage.getItem("mobilephone");
  
        let page = {
  
        
          mobilephone: mobilephone 
 
        };
  
            // alert("context   ->" + JSON.stringify(page));
    
        await axios
          .post(url, { data: page })
  
          .then((response) => {
  
            // console.log(response)
  
              // alert("context PaymentGetToken from server->" + JSON.stringify(response.data));
  
  
            let obj = this;
  
            // setTimeout(function () {
            //   obj.setState({
            //     ...obj.state,
            //     data: response.data,
                  
  
            //   });
            // }, 100);
  
            setTimeout(function () {
              callBack(response.data);
            }, 100);
  
  
          });
      } catch (error) {
  
          console.log("error.  >>  " +  error  );
  
        callBack(error);
  
        // callBack([{ error: error }]);
      }
  };

  
  PaymentCreateQR = async (callBack) => {
    
 
 
    try {

      let url = BaseApi + "/api/acard/paymentcreateqr";

       
      let page = { 
        amount: sessionStorage.getItem("amount1"),
        ref1:   sessionStorage.getItem("reference1"),
        ref2:  sessionStorage.getItem("reference2"),
        authorization: "Bearer " + sessionStorage.getItem("accessToken") 
      };

        //  alert("context PaymentCreateQR  ->" + JSON.stringify(page));
  
      await axios
        .post(url, { data: page })

        .then((response) => {

          // console.log(response)

            // alert("context PaymentCreateQR from server->" + JSON.stringify(response.data));


          let obj = this;

          // setTimeout(function () {
          //   obj.setState({
          //     ...obj.state,
          //     data: response.data,
                

          //   });
          // }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

        console.log("error.  >>  " +  error  );

      callBack(error);

      // callBack([{ error: error }]);
    }
};


NewPayment = async (callBack) => {
  try {

    let url = BaseApi + "/api/acard/newpayment";

    let page = {

      item_no: sessionStorage.getItem("reference1") || '', 
      doc_type : sessionStorage.getItem("doc_type") || '', 
      doc_no : sessionStorage.getItem("reservation_no") || '', 
      receipt_type : sessionStorage.getItem("receipt_type") || '', 
      ref_doc_type1 : sessionStorage.getItem("ref_doc_type1") || '',  
      receipt_no : sessionStorage.getItem("receipt_no") || '', 
      ref_no1 : sessionStorage.getItem("ref_no1") || '', 
      ref_doc_type2 : sessionStorage.getItem("ref_doc_type2") || '', 
      ref_no2 : sessionStorage.getItem("ref_no2") || '', 
      ref_doc_type3 : sessionStorage.getItem("ref_doc_type3") || '', 
      ref_no3 : sessionStorage.getItem("ref_no3") || '',  
      amount1 : sessionStorage.getItem("amount1") || '0', 
      amount2 : sessionStorage.getItem("amount2") || '0', 
      amount3 : sessionStorage.getItem("amount3") || '0',  
      personid: sessionStorage.getItem("customerid") || '', 
      reference1: sessionStorage.getItem("reference1") || '',
      reference2 :  sessionStorage.getItem("reference2") || '',
      reference3 :  sessionStorage.getItem("reference3") || '',
      authorization:  sessionStorage.getItem("accessToken") || '' ,
      qrImage:  sessionStorage.getItem("qrImage") || '' ,
      qrRawData:  sessionStorage.getItem("qrRawData")  || '',
      statusreq:  sessionStorage.getItem("tokenstatus")  || '',
      statustoken:  sessionStorage.getItem("tokenstatus") || '' ,
      statusqr:  sessionStorage.getItem("statusqr") || '',
      strpayment_note :  sessionStorage.getItem("strpayment_note") || '',
      userid: sessionStorage.getItem("userid"),
      companycode: sessionStorage.getItem("companycode")
   
    };

        // alert("context   ->" + JSON.stringify(page));



    await axios
      .post(url, { data: page })

      .then((response) => {
        // console.log(response)

          //  alert("context requestOtp from server->" + JSON.stringify(response.data));


        let obj = this;

        setTimeout(function () {
          obj.setState({
            ...obj.state,
            data: response.data,
              

          });
        }, 100);

        setTimeout(function () {
          callBack(response.data);
        }, 100);


      });
  } catch (error) {

    callBack([{error}]);
  }
};


  
ConfirmPayment = async (callBack) => {
    
 
 
  try {

    let url = BaseApi + "/api/acard/confirmpayment";

     
    let page = { 
      transactionDate: sessionStorage.getItem("transactionDate"),
      ref1:  sessionStorage.getItem("reference1"),
      ref2:  sessionStorage.getItem("reference2"),
      authorization: "Bearer " + sessionStorage.getItem("accessToken") 
    };

        // alert("context   ->" + JSON.stringify(page));

    await axios
      .post(url, { data: page })

      .then((response) => {

        // console.log(response)

          // alert("context PaymentCreateQR from server->" + JSON.stringify(response.data));


        let obj = this;

        // setTimeout(function () {
        //   obj.setState({
        //     ...obj.state,
        //     data: response.data,
              

        //   });
        // }, 100);

        setTimeout(function () {
          callBack(response.data);
        }, 100);


      });
  } catch (err) {

      console.log("error.  >>  " +  err  );

    callBack(err);

    // callBack([{ error: error }]);
  }
};



UpdateConfirmPayment = async (xdata,callBack) => {
  try {

    let url = BaseApi + "/api/acard/updateconfirmpayment";

    let page = {
 
       doc_type :  sessionStorage.getItem("doc_type")
      , statusconfirm :  sessionStorage.getItem("statusconfirm")
      , description :  sessionStorage.getItem("description")
      , eventCode :  sessionStorage.getItem("eventCode")
      , transactionType :  sessionStorage.getItem("transactionType")
      , payeeProxyId :  sessionStorage.getItem("payeeProxyId")
      , payeeProxyType :  sessionStorage.getItem("payeeProxyType")
      , payeeAccountNumber :  sessionStorage.getItem("payeeAccountNumber")
      , payeeName :  sessionStorage.getItem("payeeName")
      , payerAccountNumber :  sessionStorage.getItem("payerAccountNumber")
      , payerName :  sessionStorage.getItem("payerName")
      , sendingBankCode :  sessionStorage.getItem("sendingBankCode")
      , receivingBankCode :  sessionStorage.getItem("receivingBankCode")
      , amount :  sessionStorage.getItem("amount")
      , transactionId :  sessionStorage.getItem("transactionId")
      , fastEasySlipNumber :  sessionStorage.getItem("fastEasySlipNumber")
      , transactionDateandTime :  sessionStorage.getItem("transactionDateandTime")
      , billPaymentRef1 :  sessionStorage.getItem("billPaymentRef1")
      , billPaymentRef2 :  sessionStorage.getItem("billPaymentRef2")
      , billPaymentRef3 :  sessionStorage.getItem("billPaymentRef3")
      , currencyCode :  sessionStorage.getItem("currencyCode")
      , channelCode :  sessionStorage.getItem("channelCode")
      , userid :  sessionStorage.getItem("userid")
      , companycode :  sessionStorage.getItem("companycode")
       

    };
 
 

    await axios
      .post(url, { data: page })

      .then((response) => {
        // console.log(response)

          //  alert("context requestOtp from server->" + JSON.stringify(response.data));


        let obj = this;

        setTimeout(function () {
          obj.setState({
            ...obj.state,
            data: response.data,
              

          });
        }, 100);

        setTimeout(function () {
          callBack(response.data);
        }, 100);


      });
  } catch (error) {

    callBack([{error}]);
  }
};




getDataPayment = async (callBack) => {
  try {

    let url = BaseApi + "/api/acard/getdatapayment";

    let page = {

      // personid : sessionStorage.getItem("customerid"),
      // doc_type : sessionStorage.getItem("doc_type"),
      // doc_no : sessionStorage.getItem("doc_no"),
      reference1: sessionStorage.getItem("reference1"), 
      reference2: sessionStorage.getItem("reference2"), 
      userid : sessionStorage.getItem("userid"),
      companycode  : sessionStorage.getItem("companycode"),
    };

        // alert("context   ->" + JSON.stringify(page));



    await axios
      .post(url, { data: page })

      .then((response) => {
        // console.log(response)

          //  alert("context requestOtp from server->" + JSON.stringify(response.data));


        let obj = this;

        setTimeout(function () {
          obj.setState({
            ...obj.state,
            // data: response.data,
            epayment: response.data.epayment,  
            epayment_bill: response.data.epayment_bill, 
            customer: response.data.customer,   

          });
        }, 100);

        setTimeout(function () {
          callBack(response.data);
        }, 100);


      });
  } catch (error) {

    callBack([{error}]);
  }
};
 


PaymentCreateQR_102 = async (callBack) => {
    
 
 
  try {

    let url = BaseApi + "/api/acard/paymentcreateqr_102";

     
    let page = { 
      amount: sessionStorage.getItem("amount1"),
      ref1:   sessionStorage.getItem("reference1"),
      ref2:  sessionStorage.getItem("reference2"),
      authorization: "Bearer " + sessionStorage.getItem("accessToken") 
    };

      //  alert("context PaymentCreateQR  ->" + JSON.stringify(page));

    await axios
      .post(url, { data: page })

      .then((response) => {

        // console.log(response)

          // alert("context PaymentCreateQR from server->" + JSON.stringify(response.data));


        let obj = this;

        // setTimeout(function () {
        //   obj.setState({
        //     ...obj.state,
        //     data: response.data,
              

        //   });
        // }, 100);

        setTimeout(function () {
          callBack(response.data);
        }, 100);


      });
  } catch (error) {

      console.log("error.  >>  " +  error  );

    callBack(error);

    // callBack([{ error: error }]);
  }
};





UploadFileConfirmpayment = async (xdata,callBack) => {
  try {

    let url = BaseApi + "/api/acard/updatefileconfirmpayment";

    let page = {
 
        payeeProxyId :  sessionStorage.getItem("payeeProxyId")
      , payeeProxyType :  sessionStorage.getItem("payeeProxyType")
      , payeeAccountNumber :  sessionStorage.getItem("payeeAccountNumber")
      , payeeName :  sessionStorage.getItem("payeeName")
      , payerAccountNumber :  sessionStorage.getItem("payerAccountNumber")
      , payerName :  sessionStorage.getItem("payerName")
      , sendingBankCode :  sessionStorage.getItem("sendingBankCode")
      , receivingBankCode :  sessionStorage.getItem("receivingBankCode")
      , amount :  sessionStorage.getItem("amount")
      , transactionId :  sessionStorage.getItem("transactionId")
      , fastEasySlipNumber :  sessionStorage.getItem("fastEasySlipNumber")
      , transactionDateandTime :  sessionStorage.getItem("transactionDateandTime")
      , billPaymentRef1 :  sessionStorage.getItem("billPaymentRef1")
      , billPaymentRef2 :  sessionStorage.getItem("billPaymentRef2")
      , billPaymentRef3 :  sessionStorage.getItem("billPaymentRef3")
      , currencyCode :  sessionStorage.getItem("currencyCode")
      , channelCode :  sessionStorage.getItem("channelCode")
      , transactionType :  sessionStorage.getItem("transactionType")
      
       

    };
 
 

    await axios
      .post(url, { data: page })

      .then((response) => {
        // console.log(response)

          //  alert("context requestOtp from server->" + JSON.stringify(response.data));


        let obj = this;

        // setTimeout(function () {
        //   obj.setState({
        //     ...obj.state,
        //     data: response.data,
              

        //   });
        // }, 100);

        setTimeout(function () {
          callBack(response.data);
        }, 100);


      });
  } catch (error) {

    callBack([{error}]);
  }
};
 


CancelQRCode = async (callBack) => {
    
 
 
  try {

    let url = BaseApi + "/api/acard/cancelqrcode";

     
    let page = { 
     
      reference1:   sessionStorage.getItem("reference1"),
      reference2:  sessionStorage.getItem("reference2"),
      amount1: sessionStorage.getItem("amount1"), 
      userid : sessionStorage.getItem("userid"),
      companycode  : sessionStorage.getItem("companycode"),
        
    };

      //  alert("context PaymentCreateQR  ->" + JSON.stringify(page));

    await axios
      .post(url, { data: page })

      .then((response) => {

        // console.log(response)

          // alert("context PaymentCreateQR from server->" + JSON.stringify(response.data));


        let obj = this;

        // setTimeout(function () {
        //   obj.setState({
        //     ...obj.state,
        //     data: response.data,
              

        //   });
        // }, 100);

        setTimeout(function () {
          callBack(response.data);
        }, 100);


      });
  } catch (error) {

      console.log("error.  >>  " +  error  );

    callBack(error);

    // callBack([{ error: error }]);
  }
};




SaveOtptoken_bkcancel = async (callBack) => {
  try {

    let url = BaseApi + "/api/acard/saveotptoken";

    let refdocno1 = "";
    let refdocno2 = "";
    let refdocno3 = "";

    let page = {

      acard_no: sessionStorage.getItem("acard_no"),
      cusdb_id: sessionStorage.getItem("cusdb_id"),
      otptype:  sessionStorage.getItem("otptype"),
      refdocno1: sessionStorage.getItem("refdocno1"),
      refdocno2: sessionStorage.getItem("refdocno2"),
      refdocno3: sessionStorage.getItem("refdocno3"),
      mobilephone: sessionStorage.getItem("mobilephone"),
      token: sessionStorage.getItem("token_bkcancel"), 
      refno: sessionStorage.getItem("refno_bkcancel"),
      statusreq : sessionStorage.getItem("statusreq"),
      userid: sessionStorage.getItem("userid"),
      companycode: sessionStorage.getItem("companycode")
    };

        // alert("context   ->" + JSON.stringify(page));



    await axios
      .post(url, { data: page })

      .then((response) => {
        // console.log(response)

          //  alert("context requestOtp from server->" + JSON.stringify(response.data));


        let obj = this;

        setTimeout(function () {
          obj.setState({
            ...obj.state,
            data: response.data,
              

          });
        }, 100);

        setTimeout(function () {
          callBack(response.data);
        }, 100);


      });
  } catch (error) {

    callBack([{ error: error }]);
  }
};

previousPage = async (callBack) => {
  if (this.state.pageindex === 1) {
      callBack("")
      return;
  }
  this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
  })

  let obj = this
  setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPageOtp(callBack)
  }, 200)

}

firstPage = async (callBack) => {
  this.setState({
      ...this.state,
      pageindex: 1
  })

  let obj = this
  setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPageOtp(callBack)
  }, 200)

}

nextPage = async (callBack) => {

  if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
  }

  this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
  })

  let obj = this
  setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPageOtp(callBack)
  }, 200)

}
 
getPageOtp = async (callBack) => {
  try {


      let page = {
          pageindex: this.state.pageindex,
          pagesize: this.state.pagesize,
          strsearch: sessionStorage.getItem("strsearch"),
          filter_month: sessionStorage.getItem("filter_month"), 
          companycode: sessionStorage.getItem("companycode"),
          userid: sessionStorage.getItem("userid")
      }

      //    alert("inbox context get page")

      let url = BaseApi + "/api/acard/getpageotp";

      // alert("context   getPageJob ->" + JSON.stringify(page));

      await axios.post(url, { data: page })


          .then((response) => {


              let xpagecount = parseInt(response.data[0].totalrecord / 20);
              let ypage = response.data[0].totalrecord % 20;
              if (ypage > 0) { xpagecount = xpagecount + 1 }

              let obj = this

              // setTimeout(function () {
              obj.setState({
                  ...obj.state,
                  itempage_otp: response.data,
                  totalrecord: response.data[0].totalrecord,
                  pagecount: xpagecount
              });
              // }, 100)


              // setTimeout(function () {
              callBack(response.data)
              // }, 100)


          })


  } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
  }
};



  render() {
    return (
      <OTP_Context.Provider
        value={{
          ...this.state,
         
          requestOtp: this.requestOtp,
          verifyOtp: this.verifyOtp,
          SaveOtptoken: this.SaveOtptoken,
          UpdateOtpComplete: this.UpdateOtpComplete,
          CheckOtpTimeout: this.CheckOtpTimeout,
          PaymentGetToken: this.PaymentGetToken,
          PaymentCreateQR: this.PaymentCreateQR,
          NewPayment : this.NewPayment,
          PaymentUpdate: this.PaymentUpdate,
          ConfirmPayment: this.ConfirmPayment,
          UpdateConfirmPayment: this.UpdateConfirmPayment,
          getDataPayment: this.getDataPayment,
          PaymentCreateQR_102: this.PaymentCreateQR_102,
          UploadFileConfirmpayment: this.UploadFileConfirmpayment,
          CancelQRCode: this.CancelQRCode,
          SaveOtptoken_bkcancel: this.SaveOtptoken_bkcancel,
          getPageOtp: this.getPageOtp,
          nextPage: this.nextPage,
          previousPage: this.previousPage,
          firstPage: this.firstPage
     

        }}
      >
        {this.props.children}
      </OTP_Context.Provider>

    );
  }
}
const OTP_Consumer = OTP_Context.Consumer;

export { OTP_Provider, OTP_Consumer, OTP_Context };

export function withOTP_Consumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <OTP_Consumer>
        {value => <Component {...props} context={value} />}
      </OTP_Consumer>
    );
  };
}

