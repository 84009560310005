import React, { Component } from "react";
import axios from "axios";
import BaseApi from "../../Api/BaseApi";

import { AlertError } from "../../components/Utility/MyString";

const MenuContext = React.createContext();
const ServiceApi = BaseApi + "/api/admin/admin_menu";

export default class MenuProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        menucode: "",
        menutext: "",
        description1: "",
        description2: "",
        isroot: "",
        isparent: "",
        parentcode: "",
        formname: "",
        level: "",
        openstatus: "",
        imgurl: "",
        icon: "",
        userid: "",
     
      },
      userAuthorize: [
        {
          userid: "",
          menucode: "",
          menutext: "",
          useuse: 0,
          isinsert: 0,
          isupdate: 0,
          isdelete: 0,
        },
      ],
      itempage: [
        {
            menucode: "",
            menutext: "",
            description1: "",
            description2: "",
            isroot: "",
            isparent: "",
            parentcode: "",
            formname: "",
            level: "",
            openstatus: "",
            imgurl: "",
            icon: "",
          totalrecord: 1,
        },
      ],
      pageindex: 1,
      pagesize: 20,
      strsearch: "",
      companycode: "",
      totalrecord: 0,
      pagecount: 0,
 
    };
  }

  setData = async (values) => {
    this.setState({
      ...this.state,
      data: values,
    });

    console.log("state->" + JSON.stringify(this.state));
  };
 
  
  saveData = async (xdata, callBack) => {
    try {
 
        // alert("menuitem context save -> " + JSON.stringify(xdata))

        await axios.post(ServiceApi, {
            data: xdata
        })
            .then((res) => {
                if (res.data === "true") {
                    callBack(true)
                }
            })
    } catch (error) {
      AlertError(error.message);
      callBack(false);
    }
  };
 
  getMenubymenucode = async (menucode,callBack) => {
    try {
      let menucode  =  sessionStorage.getItem("menucode")  
      

      await axios
        .get(BaseApi + "/api/admin/admin_menu", {params: {code: menucode } })
     
        .then((response) => {


            // AlertError("context from server getMenubymenucode->" + JSON.stringify(response.data));
     
          setTimeout(function () {
            callBack(response.data);
          }, 100);
        });
    } catch (error) {
      AlertError(error.message);
     
    }
  };

  
  getAllMenu = async (callBack) => {
    try {
      //alert(userid);
      let url = BaseApi + "/api/admin/admin_user_menu";

      await axios.get(url, { params: { userid: "" } }).then((response) => {
        //AlertError("context from server->" + JSON.stringify(response.data));

        this.setState({
          ...this.state,
          itempage: response.data,
        });
        setTimeout(function () {
          callBack(response.data);
        }, 200);
      });
    } catch (error) {
      AlertError(error.message);
      callBack("");
    }
  };

  render() {
    return (
      <MenuContext.Provider
        value={{
          ...this.state,
          handleChange: this.handleChange,
          setData: this.setData,
          saveData: this.saveData, 
          getMenubymenucode: this.getMenubymenucode,
          getAllMenu: this.getAllMenu,
        }}
      >
        {this.props.children}
      </MenuContext.Provider>
    );
  }
}
const MenuConsumer = MenuContext.Consumer;

export { MenuProvider, MenuConsumer, MenuContext };

export function withMenuConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <MenuConsumer>
        {(value) => <Component {...props} context={value} />}
      </MenuConsumer>
    );
  };
}
