import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

  AlertError,
} from '../../components/Utility/MyString';

const MP_Context = React.createContext();
// const ServiceApi = BaseApi + "/api/report/admin_r01";

const ServiceApi = BaseApi + "/api/sales/acard_save";



export default class MP_Provider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        item_no: '',
        acard_no: '',
        sourceofcus: '',
        media: '',
        cusgroup: '',
        custype: '',
        colour: '',
        salejobtype: '',
        acard_contace: '',
        customername: '',
        mobilephone: '',
        userid: '',
        companycode: ''
      },
      userAuthorize: [
        {
          userid: '',
          menucode: '',
          menutext: '',
          useuse: 0,
          isinsert: 0,
          isupdate: 0,
          isdelete: 0
        }
      ],
      itempage_list: [
        {
          person_id: '',
          acard_no: '',
          totalrecord: 1
        }],

      itempage_qtype2: [
        {
          item_no: '',
          item_name: '',
          totalrecord: 1
        }],
      itempage_question: [
        {
          item_no: '',
          item_name: '',
          totalrecord: 1
        }],

      itempage_answer: [
        {
          item_no: '',
          item_name: '',
          totalrecord: 1
        }],


        itempage_mp: [
        {
          item_no: '',
          item_name: '',
          totalrecord: 1
        }],

        itempage: [
          {
            item_no: '',
            item_name: '',
            totalrecord: 1
          }],



      pageindex: 1,
      pagesize: 20,
      strsearch: '',
      strfromdate: '',
      strenddate: '',
      companycode: '',
      totalrecord: 0,
      pagecount: 0,
      isLogin: false
    }
  }


  setData = async (values) => {
    this.setState({
      ...this.state,
      data: values
    });

    console.log("state->" + JSON.stringify(this.state))

  };


  firstPage = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  previousPage = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  nextPage = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  lastPage = async (callBack) => {
    this.setState({
      pageindex: this.state.pagecount
    });

    let obj = this

    setTimeout(function () {
      //alert("Last Page ...." + obj.state.pageindex)
      obj.getPage(callBack);
    }, 200)

  }




  getPage = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize, 
        filter_type: sessionStorage.getItem("filter_type"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),
        userid: sessionStorage.getItem("userid")
      };

      // alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/mp/getpage";

      await axios
        .post(url, { data: page })

        .then((response) => {

          //  alert("context  getPage_matching    ->" + JSON.stringify(response.data[1].totalrecord));

          let xpagecount = parseInt(response.data[0].totalrecord / 20);
          let ypage = response.data[0].totalrecord % 20;
          if (ypage > 0) { xpagecount = xpagecount + 1 }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,


              itempage: response.data,
              totalrecord: response.data[0].totalrecord,
              pagecount: xpagecount


            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };





  UpdatePersonInfo = async (callBack) => {

    try {


      let xdata = {



        person_id: sessionStorage.getItem("person_id")
        , person_group1: sessionStorage.getItem("person_group1")
        , person_group2: sessionStorage.getItem("person_group2")
        , person_group3: sessionStorage.getItem("person_group3")
        , title: sessionStorage.getItem("title")
        , firstname: sessionStorage.getItem("firstname")
        , lastname: sessionStorage.getItem("lastname")
        , gender: sessionStorage.getItem("gender")
        , mobilephone: sessionStorage.getItem("mobilephone")
        , citizenid: sessionStorage.getItem("citizenid")
        , address_no: sessionStorage.getItem("address_no")
        , address_road: sessionStorage.getItem("address_road")
        , address_postcode: sessionStorage.getItem("address_postcode")
        , address_province: sessionStorage.getItem("address_province")
        , address_amphur: sessionStorage.getItem("address_amphur")
        , address_tumbon: sessionStorage.getItem("address_tumbon")
        , details1: sessionStorage.getItem("details1")
        , details2: sessionStorage.getItem("details2")
        , details3: sessionStorage.getItem("details3")
        , details4: sessionStorage.getItem("details4")
        , details5: sessionStorage.getItem("details5")
        , person_status: sessionStorage.getItem("person_status")
        , userid: sessionStorage.getItem("userid")


      }



      let url = BaseApi + "/api/MP/update_MP_info";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }



  GetMPInfo = async (callBack) => {
    try {

      let url = BaseApi + "/api/MP/get_MP_info";


      let page = {

        person_id: sessionStorage.getItem("person_id"),
        userid: sessionStorage.getItem("userid"),

      };


      await axios
        .post(url, { data: page })
        .then((response) => {

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              itempage_list: response.data.details,


            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);



        })


    } catch (error) {
      AlertError(error);
    }
  };



  saveMPItem = async (callBack) => {

    try {


      let xdata = {



        item_name: sessionStorage.getItem("item_name")
        , mtype: sessionStorage.getItem("mtype")
        , mplan: sessionStorage.getItem("mplan")
        , datefrom: sessionStorage.getItem("datefrom")
        , dateto: sessionStorage.getItem("dateto")
        , mstatus: sessionStorage.getItem("mstatus")
        , userid: sessionStorage.getItem("userid")
        , remark: sessionStorage.getItem("remark")


      }



      let url = BaseApi + "/api/mp/insert_mp_item";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }



  getDataMP = async (callBack) => {

    try {


      let xdata = {

        item_no: sessionStorage.getItem("item_no")

      }



      let url = BaseApi + "/api/mp/getdata_mp_item";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          // alert("context getDataMP ->" + JSON.stringify(response.data.data[0]));

          // alert("context getDataMP ->" + JSON.stringify(response.data.question[0].ref_answer));

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data.data,
              itempage_question: response.data.question,
              // itempage_answer: response.data.answer,



            });
          }, 100);




          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }




  UpdateQuestion = async (callBack) => {

    try {


      let xdata = {

        ref_mp_item: sessionStorage.getItem("ref_mp_item")
        , item_no_q: sessionStorage.getItem("item_no_q")
        , item_name_q: sessionStorage.getItem("item_name_q")
        , qstatus: sessionStorage.getItem("qstatus")
        , mtype: sessionStorage.getItem("mtype")
        , qtype: sessionStorage.getItem("qtype")
        , actionQtype: sessionStorage.getItem("actionQtype")
        , userid: sessionStorage.getItem("userid")


      }



      let url = BaseApi + "/api/mp/update_mp_question";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  FinalQuestion = async (callBack) => {

    try {


      let xdata = {

        ref_mp_item: sessionStorage.getItem("ref_mp_item")
        , ref_mp_question: sessionStorage.getItem("ref_mp_question")
        , item_no_a: sessionStorage.getItem("item_no_a")
        , item_name_a: sessionStorage.getItem("item_name_a")
        , qstatus: sessionStorage.getItem("qstatus")
        , astatus: sessionStorage.getItem("astatus")
        , qtype: sessionStorage.getItem("qtype")
        , actionAtype: sessionStorage.getItem("actionAtype")
        , userid: sessionStorage.getItem("userid")


      }

      let url = BaseApi + "/api/mp/final_mp_question";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }




  SaveAnswer = async (callBack) => {

    try {


      let xdata = {

        ref_mp_item: sessionStorage.getItem("ref_mp_item")
        , ref_mp_question: sessionStorage.getItem("ref_mp_question")
        , item_no_a: sessionStorage.getItem("item_no_a")
        , item_name_a: sessionStorage.getItem("item_name_a")
        , qstatus: sessionStorage.getItem("qstatus")
        , astatus: sessionStorage.getItem("astatus")
        , qtype: sessionStorage.getItem("qtype")
        , actionAtype: sessionStorage.getItem("actionAtype")
        , userid: sessionStorage.getItem("userid")


      }

      let url = BaseApi + "/api/mp/save_mp_answer";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              itempage_qtype2: response.data,


            });
          }, 100);




          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  getMpQuestion = async (callBack) => {

    try {


      let xdata = {

        item_no: sessionStorage.getItem("ref_mp_item")

      }



      let url = BaseApi + "/api/mp/getdata_mp_question";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          // alert("context getDataMP ->" + JSON.stringify(response.data.data[0]));

          // alert("context getDataMP ->" + JSON.stringify(response.data.question[0].ref_answer));

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data.data,
              itempage_question: response.data.question,
              // itempage_answer: response.data.answer,



            });
          }, 100);




          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }



  SaveCusAnswer = async (callBack) => {

    try {


      let xdata = {

        ref_mp_item: sessionStorage.getItem("ref_mp_item")
        , ref_mp_question: sessionStorage.getItem("ref_mp_question")
        , item_no_a: sessionStorage.getItem("item_no_a")
        , item_name: sessionStorage.getItem("item_name")
        , cusdb_id: sessionStorage.getItem("cusdb_id")
        , acard_no: sessionStorage.getItem("acard_no")
        , qtype: sessionStorage.getItem("qtype")
        , mtype: sessionStorage.getItem("mtype")
        , actionAtype: sessionStorage.getItem("actionAtype")
        , userid: sessionStorage.getItem("userid")


      }

      let url = BaseApi + "/api/mp/save_mp_cusanswer";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              itempage_qtype2: response.data,


            });
          }, 100);




          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  getMpAnswer = async (callBack) => {

    try {


      let xdata = {

        item_no: sessionStorage.getItem("ref_mp_question")

      }



      let url = BaseApi + "/api/mp/getdata_mp_answer";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          // alert("context getDataMP ->" + JSON.stringify(response.data.data[0]));

          // alert("context getDataMP ->" + JSON.stringify(response.data.question[0].ref_answer));

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              // data: response.data.data,
              itempage_answer: response.data,
              // itempage_answer: response.data.answer,



            });
          }, 100);




          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }


  getRptMp = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,
        filter_mpitem: sessionStorage.getItem("filter_mpitem"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),
        userid: sessionStorage.getItem("userid")
      };

      // alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/mp/rpt_mp";

      await axios
        .post(url, { data: page })

        .then((response) => {

          //  alert("context  getPage_matching    ->" + JSON.stringify(response.data[1].totalrecord));

          let xpagecount = parseInt(response.data[0].totalrecord / 20);
          let ypage = response.data[0].totalrecord % 20;
          if (ypage > 0) { xpagecount = xpagecount + 1 }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,


              itempage_mp: response.data,
              totalrecord: response.data[0].totalrecord,
              pagecount: xpagecount


            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };


  genExcelRptMP = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize,
        filter_mpitem: sessionStorage.getItem("filter_mpitem"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),
        userid: sessionStorage.getItem("userid")
      };

      // alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/mp/genexcelrptmp";

      await axios
        .post(url, { data: page })

        .then((response) => {
          // console.log(response)

          // alert("context genExcelRptMP from server->" + JSON.stringify(response.data));

          let obj = this

          // setTimeout(function () {
          //   obj.setState({
          //     ...obj.state,
          //     itempage_mp: response.data
          //   });
          // }, 100)


          setTimeout(function () {
            callBack(response.data);
          }, 100);




        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };


  

  

  getPageMPDashboard = async (callBack) => {

    try {


      let xdata = {

        cusdb_id: sessionStorage.getItem("cusdb_id"),
        userid: sessionStorage.getItem("userid")

      }



      let url = BaseApi + "/api/mp/getdata_mp_dashboard";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          // alert("context getDataMP ->" + JSON.stringify(response.data.data[0]));

          // alert("context getDataMP ->" + JSON.stringify(response.data.question[0].ref_answer));

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data.data,
              itempage_question: response.data.question,
              // itempage_answer: response.data.answer,



            });
          }, 100);




          setTimeout(function () {
            callBack(response.data.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }
 

  DeleteQuestion = async (callBack) => {

    try {


      let xdata = {

          ref_mp_item: sessionStorage.getItem("ref_mp_item")
        , ref_mp_question: sessionStorage.getItem("ref_mp_question") 
        , userid: sessionStorage.getItem("userid")


      }

      let url = BaseApi + "/api/mp/delete_mp_question";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }
 

  saveMPMessage = async (callBack) => {

    try {


      let xdata = {



         item_name: sessionStorage.getItem("item_name")
         , item_no: sessionStorage.getItem("item_no")
        , mtype: sessionStorage.getItem("mtype")
        , mplan: sessionStorage.getItem("mplan")
        , mto: sessionStorage.getItem("mto")
        , teamid: sessionStorage.getItem("teamid")
        , datefrom: sessionStorage.getItem("datefrom")
        , dateto: sessionStorage.getItem("dateto")
        , mstatus: sessionStorage.getItem("mstatus")
        , userid: sessionStorage.getItem("userid")
        , remark: sessionStorage.getItem("remark")


      }



      let url = BaseApi + "/api/mp/insert_mp_message";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  

  

  getPageMessage = async (callBack) => {
    try {



      let page = {
        pageindex: this.state.pageindex || 1,
        pagesize: this.state.pagesize, 
        filter_type: sessionStorage.getItem("filter_type"),
        filter_sortby: sessionStorage.getItem("filter_sortby"),
        filter_sorttype: sessionStorage.getItem("filter_sorttype"),
        strsearch: sessionStorage.getItem("strsearch"),
        userid: sessionStorage.getItem("userid")
      };

      // alert("context getpage ->" + JSON.stringify(page));

      let url = BaseApi + "/api/mp/getpage_message";

      await axios
        .post(url, { data: page })

        .then((response) => {

          //  alert("context  getPage_matching    ->" + JSON.stringify(response.data[1].totalrecord));

          let xpagecount = parseInt(response.data[0].totalrecord / 20);
          let ypage = response.data[0].totalrecord % 20;
          if (ypage > 0) { xpagecount = xpagecount + 1 }

          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,


              itempage: response.data,
              totalrecord: response.data[0].totalrecord,
              pagecount: xpagecount


            });
          }, 100);

          setTimeout(function () {
            callBack(response.data);
          }, 100);


        });
    } catch (error) {

      callBack([{ error: error }]);
    }
  };


  

  

  getDataMP_Message = async (callBack) => {

    try {


      let xdata = {

        item_no: sessionStorage.getItem("item_no")

      }



      let url = BaseApi + "/api/mp/getdata_mp_message";

      //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

      await axios.post(url, { data: xdata })

        .then((response) => {


          // alert("context getDataMP ->" + JSON.stringify(response.data.data[0]));
 
          let obj = this;

          setTimeout(function () {
            obj.setState({
              ...obj.state,
              data: response.data.data,
              // itempage_question: response.data.question,
        



            });
          }, 100);




          setTimeout(function () {
            callBack(response.data)
          }, 100)

        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  

  Myresize = async ( callBack) => {

    try {

        //  alert("context Myresize->" + JSON.stringify(xdata));

        let url = BaseApi + "/api/filekeep/filekeep_resizemp";

        let page = {

            acard_no: sessionStorage.getItem("acard_no"),
            fk_group_id: sessionStorage.getItem("fk_group_id"),
            fk_type_id: sessionStorage.getItem("fk_type_id"),
            filename: sessionStorage.getItem("filename"),
            companycode: sessionStorage.getItem("companycode")

        }



        await axios.post(url, {
            data: page
        })

            .then((res) => {


                setTimeout(function () {
                    callBack(res.data)
                }, 200)
            })

    } catch (error) {
        AlertError(error.message);
        callBack(false)
    }
}
 

SaveMP_Message_Answer = async (callBack) => {

  try {


    let xdata = {



       item_name: sessionStorage.getItem("item_name")
      , mp_item_no: sessionStorage.getItem("mp_item_no")
      , mtype: sessionStorage.getItem("mtype") 
      , teamid: sessionStorage.getItem("teamid") 
      , mstatus: sessionStorage.getItem("mstatus")
      , userid: sessionStorage.getItem("userid")
    
 

    }



    let url = BaseApi + "/api/mp/insert_mp_message_answer";

    //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

    await axios.post(url, { data: xdata })

      .then((response) => {


        setTimeout(function () {
          callBack(response.data)
        }, 100)

      })


  } catch (error) {
    AlertError(error.message);
    callBack(false)
  }
}
 
checkMpMessage = async (callBack) => {

  try {


    let xdata = {

      userid: sessionStorage.getItem("userid")

    }



    let url = BaseApi + "/api/mp/get_mp_checkmessage";

    //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

    await axios.post(url, { data: xdata })

      .then((response) => {


        // alert("context getDataMP ->" + JSON.stringify(response.data.data[0]));

        let obj = this;

        // setTimeout(function () {
        //   obj.setState({
        //     ...obj.state,
        //     data: response.data.data,
        //     // itempage_question: response.data.question,
      



        //   });
        // }, 100);
 

        setTimeout(function () {
          callBack(response.data)
        }, 100)

      })


  } catch (error) {
    AlertError(error.message);
    callBack(false)
  }
}
 

UpdateQuestionShow = async (callBack) => {

  try {


    let xdata = {
  
       ref_mp_question: sessionStorage.getItem("ref_mp_question") 
      , ref_no1: sessionStorage.getItem("ref_no1")
      , userid: sessionStorage.getItem("userid")
    
 

    }



    let url = BaseApi + "/api/mp/update_mp_questionshow";

    //   alert("context getpage report  summary by sales->" + JSON.stringify(page));

    await axios.post(url, { data: xdata })

      .then((response) => {


        setTimeout(function () {
          callBack(response.data)
        }, 100)

      })


  } catch (error) {
    AlertError(error.message);
    callBack(false)
  }
}

  render() {
    return (
      <MP_Context.Provider
        value={{
          ...this.state,
          handleChange: this.handleChange,
          setData: this.setData,
          saveData: this.saveData,
          getData: this.getData,
          getRunning: this.getRunning,
          deleteData: this.deleteData,
          getPage: this.getPage,
          firstPage: this.firstPage,
          previousPage: this.previousPage,
          nextPage: this.nextPage,
          lastPage: this.lastPage,
          UpdatePersonInfo: this.UpdatePersonInfo,
          GetMPInfo: this.GetMPInfo,
          saveMPItem: this.saveMPItem,
          getDataMP: this.getDataMP,
          UpdateQuestion: this.UpdateQuestion,
          FinalQuestion: this.FinalQuestion,
          SaveAnswer: this.SaveAnswer,
          getMpQuestion: this.getMpQuestion,
          SaveCusAnswer: this.SaveCusAnswer,
          getMpAnswer: this.getMpAnswer,
          getRptMp: this.getRptMp,
          genExcelRptMP: this.genExcelRptMP,
          getPageMPDashboard: this.getPageMPDashboard,
          DeleteQuestion: this.DeleteQuestion,
          saveMPMessage: this.saveMPMessage,
          getPageMessage: this.getPageMessage,
          getDataMP_Message:  this.getDataMP_Message,
          Myresize: this.Myresize,
          SaveMP_Message_Answer: this.SaveMP_Message_Answer,
          checkMpMessage: this.checkMpMessage,
          UpdateQuestionShow: this.UpdateQuestionShow,






        }}
      >
        {this.props.children}
      </MP_Context.Provider>

    );
  }
}
const MP_Consumer = MP_Context.Consumer;

export { MP_Provider, MP_Consumer, MP_Context };

export function withMP_Consumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <MP_Consumer>
        {value => <Component {...props} context={value} />}
      </MP_Consumer>
    );
  };
}

