import React, { Component } from "react";
import axios from 'axios';
import BaseApi from "../../Api/BaseApi";

import {

  AlertError,
} from '../../components/Utility/MyString';

const ProductContext = React.createContext();
const ServiceApi = BaseApi + "/api/admin/admin_product";

export default class ProductProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userid: '',
      data: {
        code: '',
        name1: '',
        unitcode: 'EA',
        saleprice: 0,
        activestatus: '1',
        salestatus: '1',
        linkurl: '',
        content: ''
      },
      productcode: '',
      itempage: [
        {
          register:'',
          dn_no:'',
          imageurl: '',
          locate: '',
          buyin:'',
          imagename:'',
          price: '', 
          totalrecord: 1
        }
      ],
      pageindex: 1,
      pagesize: 20,
      strsearch: '', 
      totalrecord: 0,
      pagecount: 0
    } // end set state

  }// end sonstructore


  setData = async (values) => {
    this.setState({
      ...this.state,
      data: values
    });

    //console.log("state->" + JSON.stringify(this.state))

  };

  setProductUserid = async (xuserid) => {
    this.setState({
      //...this.state,  
      userid: xuserid
    });

    //console.log("state->" + JSON.stringify(this.state))

  };

  firstPage = async (callBack) => {
    this.setState({
      ...this.state,
      pageindex: 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  previousPage = async (callBack) => {
    if (this.state.pageindex === 1) {
      callBack("")
      return;
    }
    this.setState({
      ...this.state,
      pageindex: this.state.pageindex - 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  nextPage = async (callBack) => {

    if (this.state.pageindex === this.state.pagecount) {
      callBack("")
      return;
    }

    this.setState({
      ...this.state,
      pageindex: this.state.pageindex + 1
    })

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }

  lastPage = async (callBack) => {
    this.setState({
      pageindex: this.state.pagecount
    });

    let obj = this

    setTimeout(function () {
      //alert("Last Page ...." + obj.state.pageindex)
      obj.getPage(callBack);
    }, 200)

  }

  getPage = async (callBack) => {
    try {

      //alert("product context getpage -> " + this.state.userid);

      let page = {
        pageindex: this.state.pageindex,
        pagesize: this.state.pagesize,
        strsearch: this.state.strsearch,
        userid: sessionStorage.getItem("userid"),
        companycode: sessionStorage.getItem("companycode"),
        
      }

      //alert("inbox context get page")

      let url = BaseApi + "/api/stock/productcurrentstock_page";

      //alert("context data->" + JSON.stringify(page));

      await axios.post(url, { data: page, xx: "xxx" })
        .then((response) => {

          let xpagecount = 0
          let ypage = 0
          let xtotalrecord = 0

          //AlertError("getpage ->" + JSON.stringify(response.data));

          let objdata = response.data;

          //AlertError("getpage ->" + JSON.stringify(objdata[0].totalrecord));



          let obj = this

          setTimeout(function () {

            if (objdata[0] !== undefined) {
              xpagecount = parseInt(response.data[0].totalrecord / 20);
              ypage = response.data[0].totalrecord % 20;
              if (ypage > 0) { xpagecount = xpagecount + 1 }
              xtotalrecord = response.data[0].totalrecord
            }

            obj.setState({
              ...obj.state,
              itempage: response.data,
              totalrecord: xtotalrecord,
              pagecount: xpagecount
            });
          }, 100)


          // setTimeout(function () {
          //   callBack(response.data)
          // }, 100)

        })


    } catch (error) {
      //AlertError("error->" + error.message);
      callBack("")
    }
  };


  getData = async (id, callBack) => {
    try {

      //message.loading('Please wait ...',2);  
      //alert('get data id=>' + id);
      this.setState({
        ...this.state,
        productcode: id
      });

      //alert("product context -> " + ServiceApi);

      await axios.get(ServiceApi, { params: { id: id } })
        .then((res) => {

          AlertError("context from server->" + JSON.stringify(res.data));

          this.setState({
            ...this.state,
            data: res.data,
          });

          callBack(res.data)
        })


    } catch (error) {
      AlertError(error.message);
      callBack("")
    }
  };

  saveData = async (xdata, callBack) => {

    try {

      //message.loading('Please wait ...',2);      

      await axios.post(ServiceApi, {
        data: xdata
      })
        .then((res) => {
          if (res.data === "true") {
            //AlertCompleSave();

            setTimeout(function () {
              //message.success('Save Data Complete..', 5);
              callBack(true)
            }, 200)

          }
        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  updateData = async (xdata, callBack) => {

    try {

      //message.loading('Please wait ...',2);      

      await axios.put(ServiceApi, {
        data: xdata
      })
        .then((res) => {
          if (res.data === "true") {
            //AlertCompleSave();

            setTimeout(function () {
              //message.success('Save Data Complete..', 5);

              callBack(true)
            }, 200)

          }
        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  deleteData = async (xdata, callBack) => {

    try {

      //alert("code->" + xdata.code + ' xcode->' + this.state.xtype)

      //message.loading('Action in progress..',1);      

      await axios.delete(ServiceApi, {
        xcode: xdata.xcode,
        code: xdata.code,
        name: xdata.name,
        data: xdata
      })
        .then((res) => {
          if (res.data === "true") {
            setTimeout(function () {
              //message.success('Delete Data Complete..', 5);
              callBack(true)
            }, 200)
          }
        })


    } catch (error) {
      AlertError(error.message);
      callBack(false)
    }
  }

  //function
  componentDidMount() {
    //this.getData();    
  }

  //select changed
  handleChange = (str, callBack) => {
    this.setState({
      //...this.state,
      strsearch: str
    });

    let obj = this
    setTimeout(function () {
      //alert("from context->" + obj.state.strsearch);
      obj.getPage(callBack)
    }, 200)

  }
 

  PagecheckImage = async ( callBack) => {
    try {


        let companycode = sessionStorage.getItem("companycode");
        let userid = sessionStorage.getItem("userid");
        let strsearch = sessionStorage.getItem("strsearch");
        let filter = sessionStorage.getItem("filter");

        let page = {
            pageindex: this.state.pageindex,
            pagesize: this.state.pagesize,
            strsearch: strsearch,
            filter: filter,
            companycode: companycode,
            userid: userid
        }

        // alert("context getPageImage  ->" + JSON.stringify(page));

        let url = BaseApi + "/api/stock/pagecheckimage"

        await axios.post(url, { data: page })
            .then((response) => {


              let xpagecount = parseInt(response.data[0].totalrecord / 20);
              let ypage = response.data[0].totalrecord % 20;
              if (ypage > 0) { xpagecount = xpagecount + 1 }

                let obj = this

                setTimeout(function () {
                    obj.setState({
                        ...obj.state,

                        itempage: response.data,
                        totalrecord: response.data[0].totalrecord,
                        pagecount: xpagecount
                        
                    });
                }, 100)

                setTimeout(function () {
                    callBack(response.data)
                }, 100)

            })


    } catch (error) {
        AlertError(error.message);
        callBack()
    }
};


previousPageimage = async (callBack) => {
  if (this.state.pageindex === 1) {
    callBack("")
    return;
  }
  this.setState({
    ...this.state,
    pageindex: this.state.pageindex - 1
  })

  let obj = this
  setTimeout(function () {
    //alert("from context->" + obj.state.strsearch);
    obj.PagecheckImage(callBack)
  }, 200)

}

nextPageimage = async (callBack) => {

  if (this.state.pageindex === this.state.pagecount) {
    callBack("")
    return;
  }

  this.setState({
    ...this.state,
    pageindex: this.state.pageindex + 1
  })

  let obj = this
  setTimeout(function () {
    //alert("from context->" + obj.state.strsearch);
    obj.PagecheckImage(callBack)
  }, 200)

}


getDataProduct = async (callBack) => {
  try {

    let url = BaseApi + "/api/stock/Productdetail_getdata";

    let dn_no = sessionStorage.getItem("dn_no"); 
    let userid = sessionStorage.getItem("userid");
    let companycode = sessionStorage.getItem("companycode");


    let page = {

      dn_no: dn_no ,
      userid: userid,
      companycode: companycode
      
    };

    //   alert("context   ->" + JSON.stringify(page));



    await axios
      .post(url, { data: page })

      .then((response) => {
        // console.log(response)
 
 
 
        // AlertError("context getDataProduct from server->" + JSON.stringify(response.data.data)) ;


      let obj = this;

      // setTimeout(function () {
      //   obj.setState({
      //     ...obj.state,
        
      //     data: response.data.data,
          

      //   });
      // }, 100);

        setTimeout(function () {
          callBack(response.data);
        }, 100);


      });
  } catch (error) {

    callBack([{ error: error }]);
  }
};


  render() {
    return (
      <ProductContext.Provider
        value={{
          ...this.state,
          handleChange: this.handleChange,
          setData: this.setData,
          setProductUserid: this.setProductUserid,
          saveData: this.saveData,
          updateData: this.updateData,
          getData: this.getData,
          deleteData: this.deleteData,
          getPage: this.getPage,
          firstPage: this.firstPage,
          previousPage: this.previousPage,
          nextPage: this.nextPage,
          lastPage: this.lastPage,
        
          PagecheckImage: this.PagecheckImage,
          previousPageimage: this.previousPageimage,
          nextPageimage: this.nextPageimage,
          getDataProduct: this.getDataProduct,
        }}
      >
        {this.props.children}
      </ProductContext.Provider>
    );
  }
}
const ProductConsumer = ProductContext.Consumer;

export { ProductProvider, ProductConsumer, ProductContext };

export function withProductConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <ProductConsumer>
        {value => <Component {...props} context={value} />}
      </ProductConsumer>
    );
  };
}
